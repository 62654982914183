import React from 'react';
import { Link } from "react-router-dom";
import { debounce } from 'lodash.debounce';

import FullProfile from "../../components/modals/ModamilyAppFullProfile";
import ModamilyAppLikeLoadingComponent from "../../components/common/ModamilyAppLikeLoadingComponent";
import BrowseCard from "../../components/browse/ModamilyAppBrowseCard";
import reloadImage from "../../resources/images/appAssets/reload.png";

import styles from './browseCarousel.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

class BrowseCarousel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			index: -1,
			users: [],
		};
	}

	createBrowseCards = () => {
		let { users } = this.props;

		let cards = [];

		for (let index = 0; index < users.length; index++) {
			const user = users[index];
			if (!user) {
				continue;
			} else if (user.user) {
				delete user.user;
			}
			let obj = { ...user };
			cards.push(
				<div key={index} className={cx('flexReset',"browseCardScrollContainer")}>
					<BrowseCard
						key={index}
						handleCardAction={liked => {
							if (liked) {
								this.props.likeUser(liked, user);
							} else {
								this.props.dislikeUser(liked, user);
							}
						}}
						viewFullProfile={user => {
							this.props.viewFullProfile(user);
						}}
						user={obj}
					/>

					
				</div>
			);
		}
		return cards;
	};

	render = () => {
		let { users } = this.props;

		if (!users || users.length === 0) {
			return (
				<div className={cx('flexReset', 'browseCarousel')}>
					<div className={cx('flexReset',"carouselAlert")}>
						<div className={cx('flexReset',"carouselMessage")}>
							<div className={cx('flexReset',"cardMessage")}>
								<p className={cx('flexReset',"whiteText regularWeight uppercaseText")}>
									No users found
								</p>
							</div>
							<Link to="/settings/searchPreferences">``
								<div className={cx('flexReset',"cardAction")}>
									<p className={cx('flexReset',"whiteText regularWeight")}>
										Edit your search preferences
									</p>
								</div>
							</Link>
						</div>
						<div className={cx('flexReset',"carouselControlContainer")}>
							<div
								className={cx('flexReset',"carouselRefresh")}
								onClick={this.props.refreshClick}>
								<img className="reloadImage" alt="reload" src={reloadImage} />
							</div>
						</div>
					</div>
				</div>
			);
		}

		let components = this.createBrowseCards();

		return (
			<div className={cx('flexReset',"browseCarousel")}>
				{components}
				{
						this.props.fullProfile ? (
						<FullProfile
							likeUser={this.props.likeUser}
							dislikeUser={this.props.dislikeUser}
							exitFullProfile={this.props.onExitFullProfile}
							user={this.props.fullProfileUser}
						/>
						) : null
				}
				{
					this.props.likeLoading || this.props.dislikeLoading ? <ModamilyAppLikeLoadingComponent type={this.props.likeLoading ? 'like' : 'dislike'} /> : null
				}
			</div>
		);
	};
}

export default BrowseCarousel;
