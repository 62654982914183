import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ModamilyAppStatusBar from "../components/ModamilyAppStatusBar";
import { withApollo } from "react-apollo";
import mutations from "../apollo/mutations/mutations";
import decode from "jwt-decode";
import { useHistory } from "react-router-dom";

const ModamilyAppPhoneNumber = ({ client }) => {
  const [value, setValue] = useState();
  const [error, setError] = useState();
  const router = useHistory();

  const sendVerificationCode = async () => {
    const result = await client.mutate({
      mutation: mutations.phoneVerification,
      variables: { phone: value },
    });
    const response = decode(result.data.phoneVerification);
    if (response.action === "success") {
      window.localStorage.setItem("phoneid", response.data.id);
      router.push("/verify-number", { phone: value });
    } else {
      setError("Phone invalid or already in use.");
    }
  };

  return (
    <div className="ModamilyAppPhoneNumber__body">
      <ModamilyAppStatusBar />

      <div className="ModamilyAppPhoneNumber__info">
        <h2>What’s your phone number?</h2>

        <p>
          We protect everyone of you by making sure all of Modamily’s users are
          real.
        </p>
      </div>

      <div className="PhoneInputContainer">
        <PhoneInput
          placeholder="(011) 0123-0123"
          value={value}
          onChange={setValue}
          defaultCountry="US"
          initialValueFormat="national"
        />
      </div>
      {error && <div className="error">{error}</div>}

      <div className="ModamilyAppPhoneNumber__footer">
        <p>
          <b>This is absolutely private</b> and it will never be shown on your
          profile.
        </p>

        <button onClick={sendVerificationCode}>Next</button>
      </div>
    </div>
  );
};

export default withApollo(ModamilyAppPhoneNumber);
