import React from "react";

/**
 * @property {String} text - text displayed for the header
 */
export default class ModamilyAppTabMenuHeader extends React.Component {
	render = () => {
		return (
			<div className="tabMenuHeaderContainer">
				<div className="tabHeaderContainer">
					<p className="tabHeaderText" >{this.props.text}</p>
				</div>
			</div>
		);
	};
}
