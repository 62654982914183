export const Types = {
  GET_USER_REQUEST: 'users/get_user_request',
  GET_USER_SUCCESS: 'users/get_user_success'
}

export const getUserRequest = (userData) => ({
  type: Types.GET_USER_REQUEST,
  payload: {
    userData
  }
});

export const getUserSuccess = (userData) => ({
  type: Types.GET_USER_SUCCESS,
  payload: {
    userData
  }
});