import React from 'react';

// redux
import { connect } from 'react-redux';
import { updateSearchPreferencesRequest } from '../../../../redux/actions/searchPreferences';

// common
import SwitchButton from "../../../common/ModamilyAppSwitchButton";

//form
import FormHeader from "../../../form/ModamilyAppFormHeader";
import MultiSliderInput from "../../../form/ModamilyAppMultiSliderInput";

const IncomeRange = ({ searchPreferences, handleUpdate }) => (
  <div className="searchPrefForm">
		<div className="searchPrefIncomeRangeFormContainer">
			<FormHeader text="Income Range" />
			<div className="IncomeRangeSliderContainer">
				<div className="IncomeUnitSwitch">
					<div className="IncomeUnitLabel" />
					<SwitchButton />
				</div>
				{/* TODO support centimeters */}
				<MultiSliderInput
					type={"income"}
					units={"k"}
					min={25000}
					max={1000000}
					data={[
						searchPreferences.minIncome || 25000,
						searchPreferences.maxIncome || 1000000
					]}
					step={5000}
					onChange={values => {
						const _searchPreferences = {...searchPreferences};
						_searchPreferences.minIncome = values[0];
						_searchPreferences.maxIncome = values[1];
						handleUpdate(_searchPreferences);
					}}
				/>
			</div>
		</div>
	</div>
)


export default connect(
	({ searchPreferences }) => ({
		searchPreferences: searchPreferences.items
	}),
	{ updateSearchPreferencesRequest })(IncomeRange);