import React, { useState } from "react";

//forms
import FormHeader from "../../../form/ModamilyAppFormHeader";
import ButtonGroup from "../../../form/ModamilyAppButtonGroup";

// cosntants
import Options from "../../../../constants/formOptions";

const buttonOptions = [
  "Less than 6 months",
  "6 to 12 months",
  "Over a year from now",
  "Depends on the person",
  "No Preference",
];
const buttonOptionsIndex = ["1-6", "6-12", "+12", "?", ""];

const HaveABaby = ({ searchPreferences, selected, handleUpdate }) => {
  const [value, setValue] = useState(selected);

  const handleButtonClick = (value, index) => {
    console.info(value, index);
    const v = buttonOptionsIndex[index];
    setValue(v);
    searchPreferences.haveABaby = v;
    // if (index > -1) {
    //   if (value.toLowerCase() === "prefer not to say") {
    //     searchPreferences.orientations = [value.toLowerCase()];
    //   } else {
    //     const indexOfPNTS =
    //       searchPreferences.orientations.indexOf("prefer not to say");
    //     if (indexOfPNTS > -1) {
    //       searchPreferences.orientations.splice(indexOfPNTS, 1);
    //     }
    //     searchPreferences.orientations.push(value.toLowerCase());
    //   }
    // } else {
    //   searchPreferences.orientations.splice(
    //     searchPreferences.orientations.indexOf(value.toLowerCase()),
    //     1
    //   );
    // }
    handleUpdate(searchPreferences);
  };

  const haveAbabyIndex = buttonOptionsIndex.indexOf(value);

  return (
    <div className="searchPrefForm">
      <div className="searchPrefEducationFormContainer">
        <FormHeader text="Have a Baby" />
        <ButtonGroup
          type="radio"
          selected={haveAbabyIndex}
          data={buttonOptions}
          onButtonClick={handleButtonClick}
        />
      </div>
    </div>
  );
};

export default HaveABaby;
