import { Types } from '../actions/users';

const INITIAL_STATE = {
  loading: false,
  error: false,
  items: {
    user: {}
  }
}

export default function users(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_USER_SUCCESS:
      const { userData } = action.payload;
      return {
        ...state,
        items: { 
          ...state.items,
          user: userData.user 
        }
      }
    default:
      return state;
  }
}