import { parse as qsParse } from "qs";

const parse = (str) => {
  if (!str.startsWith("?")) {
    return {};
  }

  const parsed = qsParse(str.slice(1));

  return parsed;
};

export default parse;
