import React from "react";

import decode from "jwt-decode";
import { 
  withApollo
} from "react-apollo";
import Reorder, {
  reorder,
} from "react-reorder";

import Mutations from "../../../../apollo/mutations/mutations";

import Badge from "../../../common/ModamilyAppBadge";
import ModamilyAppRoundImage from "../../../common/ModamilyAppRoundImage";
import ModamilyAppLoadingComponent from "../../../common/ModamilyAppLoadingComponent";


function buildFileSelector() {
  const fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  fileSelector.setAttribute("accept","image/png, image/jpeg");
  return fileSelector;
}

// var imageCompressOptions = {
//   maxSizeMB: 0.3,
//   maxWidthOrHeight: 200,
//   useWebWorker: true
// };

/**
 * @property {Function} setView  - function used to reset the view/ refetch query
 */
class ModamilyEditProfilePhotos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      loading: false
    };

    this.state.data && this.state.data.sort((a, b) => {
      return a.imgOrder - b.imgOrder;
    });

    this.fileSelector = buildFileSelector();
    this.fileSelector.onchange = this.fileSelectorClick;
  }

  storeRef = () => {};

  onImageClick = (hasImage) => {
    if (!hasImage) {
      this.fileSelector.click();
    }
  };

  fileSelectorClick = async evt => {
    let files = evt.target.files;
    var reader = new FileReader();
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      reader.onload = e => {
        file.base64 = e.target.result;
        this.handleAddImage({base64: file.base64,name: file.name});
      };
      reader.readAsDataURL(file);
    }
  };

  handleDeleteImage = async (image) => {
    const { client } = this.props;
    
    this.setState({ loading: true });
    try {
      const response = await client.mutate({ 
        mutation: Mutations.deleteImage, 
        variables: {
        imageID: image.id,
        uri: image.path,
        web: true
        }
      });
      let { data } = this.state;
      data = data.filter(img => img.id !== image.id);
      this.setState({ data, loading: false }, this.props.onUpdateUser(data));
    } catch (err) {
      console.log("Error: ", err)
      this.setState({ loading: false });
    }
  }

  handleUpdateImageOrder = (event, previousIndex, nextIndex, fromId, toId) => {
    const { client } = this.props;
    try {
      if (!(nextIndex > this.state.data.length - 1)) {
        this.setState(
          {
            data: reorder(this.state.data, previousIndex, nextIndex)
          },
          () => {
            let returnObjs = [];
  
            for (let index = 0; index < this.state.data.length; index++) {
              const image = this.state.data[index];
              returnObjs.push({
                id: image.id,
                imgOrder: index,
                uri: image.path,
                base64: "",
                facebookURL: ""
              });
            }
  
            setTimeout(async () => {
              await client.mutate({ mutation: Mutations.updateImageOrder,variables: { images: returnObjs, web: true } })
            }, 150);
          }
        );
      }
    } catch (err) {
      console.log('error', err)
    }
  }

  handleAddImage = async (image) => {
    const { client } = this.props;
    this.setState({ loading: true });

    try {
      const response = await client.mutate({ 
        mutation: Mutations.addImage,
        variables: { 
          image: {
            id: null,
            base64: image.base64,
            facebookURL: "",
            uri: image.name,
            imgOrder: null
          },
          web: true
        } 
      })
      let result = decode(response.data.addImage);
      if(result.action === "success") {
        let { data } = this.state;
        data.push(result.data);
        this.setState({ data, loading: false }, this.props.onUpdateUser(data));
      } else {
        throw Error(result)
      }
      
    } catch (err) {
      console.log('error', JSON.stringify(err))
      this.setState({ loading: false });
    }

  }

  render() {
    
    let components = [];
    const _dataImageArr = this.state.data || [];
    for (
      let index = 0;
      index < _dataImageArr.length;
      index++
    ) {
      const image = _dataImageArr[index];
      let comp = (
        <div
          key={image.path + Math.floor(Math.random() * 100)}
          className="draggableImageContainer"
        >
          { 
            _dataImageArr && _dataImageArr.length > 1 
            ? <Badge
                onClick={e => {
                  if(_dataImageArr && _dataImageArr.length > 1) {
                    this.handleDeleteImage(image)
                    e.stopPropagation();
                  } else {
                    console.log('Error: you should have at least one photo')
                  }
                }}
                type={"delete"}
              > 
                <ModamilyAppRoundImage
                  size="Regular"
                  isUrl={true}
                  image={image.path}
                />
              </Badge>: 
              <ModamilyAppRoundImage
                size="Regular"
                isUrl={true}
                image={image.path}
              />
          }
        </div>
      );
      components.push(comp);
    }
    while (components.length < 6) {
      components.push(
        <div
          key={components.length - 1}
          className="draggableImageContainer"
          onClick={e => {
            this.onImageClick(false);
            e.stopPropagation();
          }}
        >
          <ModamilyAppRoundImage
            size="Regular"
            isUrl={false}
            image={null}
          />
        </div>
      );
    }
    if(this.state.loading) {
      return (<ModamilyAppLoadingComponent/>)
    }
    return (
      <Reorder
        className="photoGrid"
        itemKey="id"
        reorderId="profilePhotos" // Unique ID that is used internally to track this list (required)
        reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
        component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
        placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
        draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
        // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
        holdTime={500} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
        touchHoldTime={500} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
        mouseHoldTime={200} // Hold time before dragging begins with mouse (optional), defaults to holdTime
        onReorder={this.handleUpdateImageOrder} // Callback when an item is dropped (you will need this to update your state)
        autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
        disabled={false} // Disable reordering (optional), defaults to false
        disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
      >
        {
          components
          /*
		  Note this example is an ImmutableJS List so we must convert it to an array.
		  I've left this up to you to covert to an array, as react-reorder updates a lot,
		  and if I called this internally it could get rather slow,
		  whereas you have greater control over your component updates.
		  */
        }
      </Reorder>
    );
  };
}

export default withApollo(ModamilyEditProfilePhotos);
