import React from 'react';
import ModamilyAppRoundImage from '../common/ModamilyAppRoundImage';
import Mutations from "../../apollo/mutations/mutations";
import closeImg from '../../resources/images/appAssets/closePurple.png';
import decode from 'jwt-decode';
import { withApollo } from "react-apollo";
// import FormHeader from '../form/ModamilyAppFormHeader';

// import { Link, Redirect } from 'react-router-dom';

// import { Query } from 'react-apollo';
// import Queries from '../../apollo/queries/queries';
// import decode from 'jwt-decode';
// import Mutations from '../../apollo/mutations/mutations';

/**
 * @property {Boolean} active - if the menu is displayed or not
 * @property {Function} onChange - when filter values change this function is called and updates the fields
 */
class ModamilyBlockedUser extends React.Component {
  state = {
    active: this.props.active || false,
    blockedUsers: this.props.blockedUsers
  };

  unBlockUser = async (userId) => {
    try {
			const { client } = this.props;
			const response = await client.mutate({ mutation: Mutations.unBlockUser, variables: { userId: userId }})
			const result = decode(response.data.unBlockUser);
			if(result.action === 'success') {
        this.props.refetch();
        this.setState({
          blockedUsers: this.state.blockedUsers.filter(u => u.profile.id !== userId)
        })
			} else {
				throw new Error('Error unblocking the account.');
			}
		} catch(err) {
			console.error('Error unblocking the account.')
		}
  }

  closeModal = () => {
    this.props.closeModal();
  }

  render = () => {
    let { active } = this.props;

    const blockedUsers = this.state.blockedUsers.map(u => {
      const unblock = () => {
        this.unBlockUser(u.blockedUserId);
      }

      if (!u.profile) {
        return null;
      }

      return (
        <div
          key={u.profile.id}
          className="blockedUsersItem"
        >
          <div className="image-container">
            <ModamilyAppRoundImage
              isUrl={true}
              image={u.profile.userProfileImages.length ? u.profile.userProfileImages[0].path : null}
              name={u.profile.firstName}
            />
            <div className="likeNameContainer">
              <p>{u.profile.firstName || 'Unknown'}</p>
            </div>
          </div>
          <div className="actions">
            <button className="button redButton" type="button" onClick={unblock}>Un-Block</button>
          </div>
        </div>
      );
    })

    return (
      <div className={'modalCurtain blockedUsers' + (active ? ' curtainDrag' : ' modalHidden')}>
        <div className="modalCard blockedUsers">
          <div
            className="blockedUsersModalCloseButton"
            onClick={this.closeModal}
          >
            <img className="closeButton" src={closeImg} />
          </div>
          {this.state.blockedUsers.length > 0
            ? blockedUsers
            : <h2 style={{ padding: "2rem" }}>There are no blocked users.</h2>}
        </div>
      </div>
    );
  };
}

export default withApollo(ModamilyBlockedUser);