import { Types } from '../actions/searchPreferences';

const INITIAL_STATE = {
  loading: false,
  error: false,
  items: {
    shouldFetchProfiles: true
  }
}

export default function searchPreferences(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.UPDATE_SEARCH_PREFERENCES_SUCCESS:
      const { searchPreferences } = action.payload;
      return {
        ...state,
        items: { 
          ...state.items,
          ...searchPreferences 
        }
      }
    case Types.UPDATE_FETCH_PROFILES_SUCCESS:
      const { shouldFetchProfiles } = action.payload;
      return {
        ...state,
        items: { 
          ...state.items,
          shouldFetchProfiles 
        }
      }
    default:
      return state;
  }
}