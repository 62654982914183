import React from "react";
import GridDraggable, { Section } from "grid-draggable";
import FormHeader from "../form/ModamilyAppFormHeader";

/**
 * @property {Object} data - {coparentRanks: [
				"Physical attractiveness",
				"Intelligence",
				"Creativity",
				"Financial stability / income",
				"Shared value system",
				"Same religion",
				"Health"
			]} - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppCoparentRanks extends React.Component {
  state = {
    data: this.props.data || {
      coparentRanks: [
        "Physical attractiveness",
        "Intelligence",
        "Creativity",
        "Financial stability / income",
        "Shared value system",
        "Same religion",
        "Health",
      ],
    },
  };

  rankOptions = [
    "Physical attractiveness",
    "Intelligence",
    "Creativity",
    "Financial stability / income",
    "Shared value system",
    "Same religion",
    "Health",
  ];

  componentDidMount = () => {
    this.validate();
  };

  swapRanks = (indexA, indexB) => {
    if (indexA === indexB) return;

    let state = this.state;
    let tempA = state.data.coparentRanks[indexA];
    let tempB = state.data.coparentRanks[indexB];

    if (!tempA || !tempB) {
      return;
    }

    if (tempA && tempB) {
      state.data.coparentRanks[indexA] = tempB;
      state.data.coparentRanks[indexB] = tempA;
      state.profileImage = state.data.coparentRanks[0];
    }

    this.setState(state, () => this.validate());
  };

  validate = () => {
    let data = this.state.data;
    this.props.onValidChange(data);
  };

  render = () => {
    let components = [];

    for (let index = 0; index < this.state.data.coparentRanks.length; index++) {
      const rank = this.state.data.coparentRanks[index];
      components.push(
        <Section key={index + rank}>
          <div className="rankContainer">
            <div className="rankPositionContainer">
              <p className="rankPositionText">{index + 1}</p>
            </div>
            <div className="rankQualityContainer">
              <p className="rankQualityText">{rank}</p>
            </div>
          </div>
        </Section>
      );
    }

    console.log(components);

    return (
      <div className="coparentRanksForm">
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            Co-Parent Qualities
          </h2>

          <p>
            <b>Hold & drag to reorder</b> by level of importance.
          </p>
        </div>
        <div className="rankListContainer">
          <GridDraggable
            onSwap={this.swapRanks}
            lg={1}
            md={1}
            xs={1}
            rowClassName="rankRow"
            colClassName="rankCol"
          >
            {components}
          </GridDraggable>
        </div>
      </div>
    );
  };
}
