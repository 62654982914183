import React from "react";
import ButtonGroup from "../form/ModamilyAppButtonGroup";
import FormHeader from "../form/ModamilyAppFormHeader";
import Subtitle from "../common/ModmilyAppSubtitle";

/**
 * @property {Object} data  - { hairColor: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppHaveABaby extends React.Component {
  state = {
    data: this.props.data || { haveAbaby: null },
  };

  buttonOptions = [
    "Less than 6 months",
    "6 to 12 months",
    "Over a year from now",
    "Depends on the person",
  ];
  buttonOptionsIndex = ["1-6", "6-12", "+12", "?"];

  componentWillMount = () => {
    this.validate();
  };

  validate = () => {
    let data = this.state.data;
    if (!data.haveAbaby) {
      this.props.invalidate();
    } else if (data.haveAbaby) {
      console.log(data);
      this.props.onValidChange(data);
    }
  };

  handleButtonClick = (value, index, isActive) => {
    let data = this.state.data;
    data.haveAbaby = isActive ? this.buttonOptionsIndex[index] : null;
    this.props.scrollToBottom && this.props.scrollToBottom();
    this.validate();
  };

  render = () => {
    let data = this.state.data;

    let haveAbabyIndex = this.buttonOptionsIndex.indexOf(data.haveAbaby);

    return (
      <div className="hairColorForm">
        {/* <FormHeader text="Natural Hair"></FormHeader> */}
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            When do you want to have{" "}
            <span role="img" aria-label="baby face emoji">
              a baby? 👶
            </span>
          </h2>
        </div>
        <ButtonGroup
          selected={haveAbabyIndex}
          type="radio"
          data={this.buttonOptions}
          onButtonClick={this.handleButtonClick}
        ></ButtonGroup>
      </div>
    );
  };
}
