import React from "react";

import DotMenu from "../common/ModamilyAppDotMenu";
import { AWS_URL } from "../../config/config";
import dislikeButton from "../../resources/images/appAssets/dislikeColor.png";
import likeButton from "../../resources/images/appAssets/likeColor.png";

import disliked from "../../resources/images/appAssets/dislikeLarge.png";
import liked from "../../resources/images/appAssets/likeLarge.png";
import ModamilyAppLoadingComponent from "../common/ModamilyAppLoadingComponent";

import styles from './modamilyAppLikedUserCard.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

//TODO Add dot menu to card

/**
 * @property {Object} user - User to be displayed on card
 * @property {Function (Boolean)}  handleCardAction(liked) - a function ran when a user clicks on one of the two buttons, the parameter is true if its liked and false if disliked
 * @property {Function(Object)} viewFullProfile(user) - Function called to display the full profile component of a user
 * @property {Boolean} isPremium - a boolean determining if the user viewing the card is premium
 */
export default class ModamilyAppLikedUserCard extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			slideIndex: 0
		};
		
	}

	componentDidMount = () => {
		// this.playAnimation(true);
	};

	nextImage = () => {
		let index = this.state.slideIndex;
		index++;
		if (index > this.props.user.images.length) {
			index = 0;
		}
		this.setState({ slideIndex: index });
	};

	pickImage = index => {
		this.setState({ slideIndex: index });
	};

	prevImage = () => {
		let index = this.state.slideIndex;
		index--;
		if (index < 0) {
			index = this.props.user.images.length - 1;
		}
		this.setState({ slideIndex: index });
	};

	playAnimation = liked => {
		let el = liked ? this.refs.likeAnimation : this.refs.dislikedAnimation;

		if (el) {
			let name = el.className;
			el.className = name.replace(" inactiveAnimation", " activeAnimation");
			setTimeout(() => {
				let name = el.className;
				el.className = name.replace(" activeAnimation", " inactiveAnimation");
			}, 350);
		}
	};

	render = () => {
		let { user } = this.props;

		if (!user) {
			return <ModamilyAppLoadingComponent />;
		}

		return (
			<div className="likeUserCard">
				<div
					onClick={() => {
						if(this.props.isPremium)
						this.props.viewFullProfile(user);
					}}
					className="profileImageSlideshow">
					{user.images.map((e, index) => (
							<img
								alt={""}
								key={index}
								className={
									"profileSlideshowImage" +
									(index === this.state.slideIndex
										? " slideActive"
										: " slideInactive") +
									" likedUserImageSlide"
								}
								src={AWS_URL + e.path}
							/>
					))}
					<div className="slideDots">
						{user.images.slice(0, 6).map((e, index) => {
							return (
								<div
									key={e.path}
									className={
										"slideDot" +
										(index === this.state.slideIndex ? " activeDot" : "")
									}
									onClick={e => {
										this.pickImage(index);
										e.stopPropagation();
									}}
								/>
							);
						})}
					</div>
				</div>
				<div className="profileInfoCard">
					<div className="browseCardPopUpMenuContainer">
						<DotMenu
							options={["Block user", "View Full Profile"]}
							functions={[
								() => {
									console.log("BLOCK");
								},
								() => {
									if(this.props.isPremium)
									this.props.viewFullProfile(user);
								}
							]}
						/>
					</div>
					<div className="profileInfoContainer">
						<div className="profileInfoNameContainer">
							<p className="purpleText regularWeight profileInfoName">
								{user.firstName}
							</p>
						</div>
						<div className="lookingForContainer">
							<div className="lookingForTitleContainer">
								<p className="greyText lookingForTitle">LOOKING FOR</p>
							</div>
							<div className="lookingForItemContainer">
								<p className="purpleText regularWeight lookingForItems">
									{user.lookingFor.join(", ")}
								</p>
							</div>
						</div>
						<div className="ageContainer">
							<div className="ageTitleContainer">
								<p className="greyText ageTitle">AGE</p>
							</div>
							<div className="ageNumberContainer">
								<p className="purpleText regularWeight ageNumber">{user.age}</p>
							</div>
						</div>
						<div className={cx("cardActions")}>
							<div
								className={cx("dislikeButtonContainer")}
								onClick={() => {
									if (this.props.isPremium) {
										this.props.handleCardAction(false);
										this.playAnimation(false);
									}
								}}>
								<img alt={""} className={cx("dislikeButtonImage")} src={dislikeButton} />
							</div>
							<div
								className={cx("likeButtonContainer")}
								onClick={() => {
									if (this.props.isPremium) {
										this.props.handleCardAction(true);
										this.playAnimation(true);
									}
								}}>
								<img alt={""} className={cx("likeButtonImage")} src={likeButton} />
							</div>
						</div>
					</div>
				</div>
				<div
					ref="likeAnimation"
					className="profileAnimationLiked inactiveAnimation">
					<img alt={""} className="profileAnimationLikedImage" src={liked} />
				</div>
				<div
					ref="dislikedAnimation"
					className="profileAnimationDisliked inactiveAnimation">
					<img alt={""} className="profileAnimationDislikedImage" src={disliked} />
				</div>
			</div>
		);
	};
}
