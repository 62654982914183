import React, { Component } from "react";
import ModamilyAppNavBar from "../components/common/ModamilyAppNavBar";
import AccountDetails from "../components/settings/ModamilyAppAccountDetails";
import EditProfile from "../components/settings/ModamilyAppEditProfile";
import SearchPreferences from "../components/settings/SearchPreferences/ModamilyAppSearchPreferences";

export default class ModamilyAppSettings extends Component {
	state = { navKey: 0 };

	updateNav = () => {
		this.setState({ navKey: Math.random() * 1000 });
	};

	render = () => {
		let route = this.props.menu;

		let component = null;

		switch (route) {
			case "accountDetails":
				component = <AccountDetails updateNav={this.updateNav} />;
				break;
			case "searchPreferences":
				component = <SearchPreferences />;
				break;
			case "editProfile":
				component = <EditProfile />;
				break;
		}

		return (
			<div className="ModamilyAppSettings">
				<ModamilyAppNavBar key={this.state.navKey} type="inApp" />
				{component}
			</div>
		);
	};
}
