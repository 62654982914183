import React from 'react';

//form
import FormHeader from "../../../form/ModamilyAppFormHeader";
import SliderInput from "../../../form/ModamilyAppSliderInput";
import CurrentLocationForm from "../../../onboarding/ModamilyAppCurrentLocation";

const Distance = ({ searchPreferences, handleUpdate }) => (
  <div className="searchPrefForm">
		<div className="searchPrefDistanceFormContainer">
			<FormHeader text="Distance , Country & Zip" />
			<div className="distanceSlider">
				<SliderInput
					units="distance"
					min={1}
					step={1}
					max={150}
					onChange={value => {
						searchPreferences.distance = value;
						handleUpdate(searchPreferences);
					}}
					value={
						searchPreferences ? searchPreferences.distance : 150
					}
				/>
			</div>
			<CurrentLocationForm
				key={"CurrentLocationForm-UpdateSearchPreferences"}
				data={
					searchPreferences
						? {
								country: searchPreferences.country,
								zipcode: searchPreferences.zipcode
							}
						: null
				}
				invalidate={() => {}}
				validation="custom"
				type={"UPDATE_SEARCH_PREFERENCES"}
				isSearchForm={true}
				onValidChange={data => {
					searchPreferences.country = data.country;
					searchPreferences.zipcode = data.zipcode;
					handleUpdate(searchPreferences);
				}}
			/>
		</div>
	</div>
)


export default Distance;