import React, { useState } from "react";
import GoogleLogin from "react-google-login";
import { useHistory } from "react-router-dom";
import ModamilyAppNavHeaders from "../components/common/ModamilyAppNavHeaders";
import ModamilyAppTextInput from "../components/form/ModamilyAppTextInput";
import appleLogo from "../resources/images/appAssets/apple.png";

const ModamilyAppVerifyPhoneNumber = () => {
  const [emailAdress, setEmailAdress] = useState("");
  const router = useHistory();

  return (
    <div className="ModamilyApp--email-verification container center">
      <ModamilyAppNavHeaders component="previousOnboardingHeader" step="3" />

      <div className="my-32">
        <h2 className="purple heading-lg font-purple font-work-sans weight-600 font-letter-spacing-md line-height-lg">
          What's your email?
        </h2>
      </div>

      <div className=" mb-48">
        <p className="purple text-md font-purple font-work-sans weight-400 font-letter-spacing-sm line-height-md">
          Don’t ever lose access to your account
        </p>
      </div>

      <div className="w-full mt-32">
        <ModamilyAppTextInput
          //   onEnterPress={this.onEnterPress}
          onContentChange={(text) => {
            setEmailAdress(text);
          }}
          reference="emailVerification"
          //   ref="emailVerification"
          placeholder="Email Adress"
          //   value={this.state.data.firstName}
        />
      </div>

      <div className="mt-48 ">
        <p className="purple text-md font-purple font-work-sans weight-400 font-letter-spacing-sm line-height-md">
          Or verify instantly:
        </p>
      </div>

      <div className="background-white border-gray radius-8 shadow-md w-full mt-32 row align-center py-16  pointer">
        <GoogleLogin
          // clientId={config.googleClientID}
          buttonText=""
          onClick={() => {
            //   this.toggleLoading();
          }}
          onSuccess={(response) => {
            //   this.onSocialSignIn("google", response);
            return;
          }}
          onFailure={(response) => {}}
          autoLoad={false}
          cookiePolicy={"single_host_origin"}
        />

        <div>
          <p className="purple text-md font-purple font-work-sans weight-400 font-letter-spacing-sm line-height-md">
            Sign in with Google
          </p>
        </div>
      </div>

      <div className="background-black  border-gray radius-8 shadow-md w-full mt-32 row align-center py-16  pointer mb-32">
        <button
          // onClick={() => window.AppleID.auth.signIn()}
          className="apple-login"
        >
          <img src={appleLogo} alt="Login with Apple." width="18" height="18" />
        </button>

        <div>
          <p className="text-white text-md font-purple font-work-sans weight-400 font-letter-spacing-sm line-height-md">
            Sign in with Apple
          </p>
        </div>
      </div>

      <div className="ModamilyAppPhoneNumber__footer justify-end mt-48">
        <button onClick={() => router.push("/welldone")}>Next</button>
      </div>
    </div>
  );
};

export default ModamilyAppVerifyPhoneNumber;
