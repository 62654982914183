import React from "react";

/**
 * @property {String} text  the text to be displayed
 */
export default class ModamilyAppSubtitle extends React.Component {
    render = () => {
        return <div className="subtitle">
            <p className="subtitleText">{this.props.text}</p>
        </div>
    }
}