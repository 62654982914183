import { apiClient } from './apiClient';

export const startSession = async (priceType, email) => {
  const response = await apiClient.post('/checkout-create-session', {
    priceType,
    email
  });

  return response.data;
}

export const startBillingSession = async () => {
  const response = await apiClient.post('/checkout-create-portal-session');

  return response.data;
}
