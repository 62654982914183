import React from "react";
import ReactSlider from "react-slider";

/**
 * @property {Array[Integer]} data - values for the slider handles
 * @property {Integer} min - min value for the slider
 * @property {Integer} max - max value for the slider
 * @property {Integer} step - step amount for the slider
 * @property {String} units - type of units for display on the label
 * @property {String} type - slider type - "height", "income", "age"
 * @property {Integer} minDistance - (optional) - default:1- minimum distance between the slider handles
 * @property {Function([Integer])} onChange(values) - function taking in the new  values when they change the first index in the array is the lower bound
 */
export default class ModamilyAppMultiSlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.props.data || [this.props.min, this.props.max],
			min: this.props.min,
			max: this.props.max,
			type: this.props.type,
			step: this.props.step,
			units: this.props.units,
			minDistance: this.props.minDistance
		};
	}

	validate = () => {
		let data = this.state.data;
		if (data[0] >= this.state.min && data[1] <= this.props.max) {
			if (data[0] != data[1]) this.props.onChange(data);
		}
	};

	getValue = (data) => {
		return data / 1000 >= 1000 ? data / 1000000 + "MM"  : data / 1000 + "K";
	}

	render = () => {
		let { min, max, type, data, step, units } = this.state;

		let label = null;

		switch (type) {
			case "income":
				label = (
					<div className="fd-row">
						<p>{this.getValue(data[0])}</p>
						<p>{" - "}</p>
						<p>{this.getValue(data[1])}</p>
					</div>
				);
				break;
			default:
				label = (
					<div className="fd-row">
						<p>{data[0] + " " + units}</p>
						<p>{" - "}</p>
						<p>{data[1] + " " + units}</p>
					</div>
				);
				break;
		}

		return (
			<div className="multiSliderInputContainer">
				<div className="sliderLabel">
					<div className="sliderLabelContainer">{label}</div>
				</div>
				<div className="multiSliderContainer">
					<ReactSlider
						key={this.props.units}
						value={data}
						min={min}
						max={max}
						step={step}
						className="multiSliderInput"
						handleClassName="multiSliderHandle"
						barClassName="multiSliderBar"
						pearling={true}
						onChange={values => {
							this.setState({ data: values });
						}}
						onAfterChange={values => {
							this.setState({ data: values }, this.validate);
						}}
						minDistance={this.state.minDistance || 1}
						withBars
					/>
				</div>
			</div>
		);
	};
}
