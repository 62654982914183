import React from "react";

/**
 * @property {String} text - text for the header
 * @property {String} className - custom class for the header
 */
export default class ModamilyAppFormHeader extends React.Component {
  render() {
    return (
      <div className="formHeader">
        <p
          className={
            "formHeaderText" +
            (this.props.className ? " " + this.props.className : "")
          }
        >
          {this.props.text}
        </p>
      </div>
    );
  }
}
