import gql from "graphql-tag";

const signUp = gql`
  mutation signUpMutation(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $addressLine1: String!
    $addressLine2: String!
    $zipcode: String!
    $city: String!
    $state: String!
    $country: String!
    $phone: String!
    $fcmToken: String!
    $phoneVerification: String
    $phoneVerificationHash: String
  ) {
    signUp(
      firstName: $firstName
      lastName: $lastName
      email: $email
      zipcode: $zipcode
      city: $city
      phone: $phone
      password: $password
      state: $state
      country: $country
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      fcmToken: $fcmToken
      isOnline: true
      phoneVerification: $phoneVerification
      phoneVerificationHash: $phoneVerificationHash
    )
  }
`;
const updateProfile = gql`
  mutation updateProfile(
    $gender: String
    $dob: String
    $lookingFor: [String]
    $orientation: String
    $hairColor: String
    $eyeColor: String
    $bloodType: String
    $ethnicity: [String]
    $race: String
    $diet: String
    $occupationTitle: String
    $occupationWorkplace: String
    $income: Int
    $bodyType: String
    $height: Int
    $heightIsMetric: Boolean
    $drink: String
    $smoke: String
    $marijuana: String
    $religion: String
    $religionActivity: String
    $politics: String
    $children: String
    $fertilization: [String]
    $hometownCountry: String
    $hometownZip: String
    $relationshipStatus: String
    $school: String
    $education: String
  ) {
    updateProfile(
      gender: $gender
      dob: $dob
      lookingFor: $lookingFor
      orientation: $orientation
      hairColor: $hairColor
      eyeColor: $eyeColor
      bloodType: $bloodType
      ethnicity: $ethnicity
      race: $race
      diet: $diet
      occupationTitle: $occupationTitle
      occupationWorkplace: $occupationWorkplace
      income: $income
      bodyType: $bodyType
      height: $height
      heightIsMetric: $heightIsMetric
      drink: $drink
      smoke: $smoke
      marijuana: $marijuana
      religion: $religion
      religionActivity: $religionActivity
      politics: $politics
      children: $children
      fertilization: $fertilization
      hometownCountry: $hometownCountry
      hometownZip: $hometownZip
      relationshipStatus: $relationshipStatus
      school: $school
      education: $education
    )
  }
`;

const updateProfileSettings = gql`
  mutation updateProfileSettings(
    $gender: String
    $dob: String
    $lookingFor: [String]
    $orientation: String
    $hairColor: String
    $eyeColor: String
    $bloodType: String
    $ethnicity: [String]
    $race: String
    $diet: String
    $occupationTitle: String
    $occupationWorkplace: String
    $income: Int
    $bodyType: String
    $height: Int
    $heightIsMetric: Boolean
    $drink: String
    $smoke: String
    $marijuana: String
    $religion: String
    $religionActivity: String
    $politics: String
    $children: String
    $fertilization: [String]
    $hometownCountry: String
    $hometownZip: String
    $relationshipStatus: String
    $school: String
    $education: String
    $meetGender: String
    $haveAbaby: String
    $hometown: String
  ) {
    updateProfileSettings(
      gender: $gender
      dob: $dob
      lookingFor: $lookingFor
      orientation: $orientation
      hairColor: $hairColor
      eyeColor: $eyeColor
      bloodType: $bloodType
      ethnicity: $ethnicity
      race: $race
      diet: $diet
      occupationTitle: $occupationTitle
      occupationWorkplace: $occupationWorkplace
      income: $income
      bodyType: $bodyType
      height: $height
      heightIsMetric: $heightIsMetric
      drink: $drink
      smoke: $smoke
      marijuana: $marijuana
      religion: $religion
      religionActivity: $religionActivity
      politics: $politics
      children: $children
      fertilization: $fertilization
      hometownCountry: $hometownCountry
      hometownZip: $hometownZip
      relationshipStatus: $relationshipStatus
      school: $school
      education: $education
      meetGender: $meetGender
      haveAbaby: $haveAbaby
      hometown: $hometown
    )
  }
`;

const googleSignIn = gql`
  mutation googleSignUp($token: String) {
    googleSignUp(token: $token)
  }
`;

const appleSignIn = gql`
  mutation appleSignUp(
    $user: String
    $email: String
    $firstName: String
    $lastName: String
    $identityToken: String
    $authorizationCode: String
    $nonce: String
    $phoneVerification: String
    $phoneVerificationHash: String
  ) {
    appleSignUp(
      user: $user
      email: $email
      firstName: $firstName
      lastName: $lastName
      identityToken: $identityToken
      authorizationCode: $authorizationCode
      nonce: $nonce
      phoneVerification: $phoneVerification
      phoneVerificationHash: $phoneVerificationHash
    )
  }
`;

const updateImageOrder = gql`
  mutation updateImageOrder($images: [Image]!, $web: Boolean) {
    updateImageOrder(images: $images, web: $web)
  }
`;
const signIn = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password)
  }
`;

const updateUser = gql`
  mutation updateUser(
    $email: String
    $firstName: String
    $lastName: String
    $addressLine1: String
    $addressLine2: String
    $zipcode: String
    $city: String
    $state: String
    $country: String
    $phone: String
    $latLong: String
    $fcmToken: String
    $notifications: [String]
    $onboardingStep: Int
    $onboardingStatus: Int
    $skipFullProfile: Boolean
    $phoneVerification: String
    $phoneVerificationHash: String
  ) {
    updateUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      zipcode: $zipcode
      city: $city
      phone: $phone
      state: $state
      country: $country
      latLong: $latLong
      fcmToken: $fcmToken
      notifications: $notifications
      onboardingStep: $onboardingStep
      onboardingStatus: $onboardingStatus
      skipFullProfile: $skipFullProfile
      phoneVerification: $phoneVerification
      phoneVerificationHash: $phoneVerificationHash
    )
  }
`;
const updateCoParentRanks = gql`
  mutation updateCoParentRanks($qualities: String) {
    updateCoParentRanks(qualities: $qualities)
  }
`;
const updateSearchPrefs = gql`
  mutation updateSearchPrefs(
    $gender: [String]
    $race: [String]
    $lookingFor: [String]
    $ethnicity: [String]
    $hairColor: [String]
    $eyeColor: [String]
    $religion: [String]
    $education: [String]
    $orientations: [String]
    $politics: [String]
    $distance: Int
    $minIncome: Int
    $maxIncome: Int
    $minAge: Int
    $maxAge: Int
    $minHeight: Int
    $maxHeight: Int
    $country: String
    $zipcode: String
    $haveABaby: String
  ) {
    updateSearchPrefs(
      gender: $gender
      race: $race
      lookingFor: $lookingFor
      ethnicity: $ethnicity
      hair: $hairColor
      eyeColor: $eyeColor
      religion: $religion
      education: $education
      orientations: $orientations
      politics: $politics
      distance: $distance
      maxIncome: $maxIncome
      maxHeight: $maxHeight
      maxAge: $maxAge
      minIncome: $minIncome
      minHeight: $minHeight
      minAge: $minAge
      country: $country
      zipcode: $zipcode
      haveABaby: $haveABaby
    )
  }
`;

const deleteImage = gql`
  mutation deleteImage($imageID: ID, $uri: String, $web: Boolean) {
    deleteImage(imageID: $imageID, uri: $uri, web: $web)
  }
`;

// const addNewImage = gql`
// 	mutation addImage($image: Image) {
// 		addImage(image: $image)
// 	}
// `;

const addRelationship = gql`
  mutation addRelationship($relationship: Boolean, $userb: ID) {
    addRelationship(relationship: $relationship, userb: $userb)
  }
`;

const blockUser = gql`
  mutation blockUser($userId: ID) {
    blockUser(blocking: $userId)
  }
`;

const unBlockUser = gql`
  mutation blockUser($userId: ID) {
    unBlockUser(userId: $userId)
  }
`;

const reportUser = gql`
  mutation reportUser($userId: ID) {
    reportUser(reporting: $userId)
  }
`;

const upgrade = gql`
  mutation upgradeAccount(
    $price: String
    $subType: String
    $storeSubscription: String
    $storeReceipt: String
  ) {
    upgradeAccount(
      price: $price
      subType: $subType
      storeReceipt: $storeReceipt
      storeSubscription: $storeSubscription
    )
  }
`;

const facebookSignIn = gql`
  mutation facebookSignUp(
    $email: String
    $expoPushToken: String
    $token: String
    $name: String
    $id: String
  ) {
    facebookSignUp(
      email: $email
      expoPushToken: $expoPushToken
      token: $token
      name: $name
      id: $id
    )
  }
`;

const createConversationStarters = gql`
  mutation createConversationStarters($list: String) {
    createConversationStarters(list: $list)
  }
`;

const updateConversationStarters = gql`
  mutation updateConversationStaters($list: String) {
    updateConversationStarters(list: $list)
  }
`;

const uploadImages = gql`
  mutation uploadFiles($images: [Image]) {
    uploadImages(images: $images)
  }
`;

const setMessagesAsRead = gql`
  mutation setMessagesAsRead($messageIds: [ID]) {
    setMessagesAsRead(messageIds: $messageIds)
  }
`;

const createCoparentRanks = gql`
  mutation createCoParentRanks($qualities: String!) {
    createCoParentRanks(qualities: $qualities)
  }
`;

const createProfile = gql`
  mutation createProfile(
    $dob: String
    $gender: String
    $lookingFor: [String]
    $orientation: String
    $eyeColor: String
    $bloodType: String
    $ethnicity: [String]
    $race: String
    $diet: String
    $occupationTitle: String
    $occupationWorkplace: String
    $income: Int
    $bodyType: String
    $height: Int
    $heightIsMetric: Boolean
    $drink: String
    $smoke: String
    $marijuana: String
    $religion: String
    $religionActivity: String
    $politics: String
    $children: String
    $hairColor: String
    $fertilization: [String]
    $hometownCountry: String
    $hometownZip: String
    $relationshipStatus: String
    $school: String
    $education: String
  ) {
    createProfile(
      dob: $dob
      gender: $gender
      lookingFor: $lookingFor
      orientation: $orientation
      eyeColor: $eyeColor
      bloodType: $bloodType
      ethnicity: $ethnicity
      race: $race
      diet: $diet
      occupationTitle: $occupationTitle
      occupationWorkplace: $occupationWorkplace
      income: $income
      bodyType: $bodyType
      height: $height
      heightIsMetric: $heightIsMetric
      drink: $drink
      smoke: $smoke
      marijuana: $marijuana
      religion: $religion
      religionActivity: $religionActivity
      politics: $politics
      children: $children
      hairColor: $hairColor
      fertilization: $fertilization
      hometownCountry: $hometownCountry
      hometownZip: $hometownZip
      relationshipStatus: $relationshipStatus
      school: $school
      education: $education
    )
  }
`;
const addImage = gql`
  mutation addImage($image: Image!, $web: Boolean) {
    addImage(image: $image, web: $web)
  }
`;

const resetPassword = gql`
  mutation forgotPW($hash: String, $newPW: String) {
    forgotPW(hash: $hash, newPW: $newPW)
  }
`;

const deleteAccount = gql`
  mutation {
    deleteUser
  }
`;

const requestPasswordChange = gql`
  mutation requestPasswordChange($email: String!) {
    requestPasswordChange(email: $email)
  }
`;

const adminSignIn = gql`
  mutation adminSignIn($email: String!, $password: String!) {
    adminSignIn(email: $email, password: $password)
  }
`;

const adminUpdateUser = gql`
  mutation adminUpdateUser(
    $firstName: String
    $lastName: String
    $addressLine1: String
    $addressLine2: String
    $zipcode: String
    $city: String
    $state: String
    $country: String
    $phone: String
    $email: String
    $id: ID
  ) {
    adminUpdateUser(
      firstName: $firstName
      lastName: $lastName
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      zipcode: $zipcode
      city: $city
      phone: $phone
      state: $state
      country: $country
      email: $email
      id: $id
    )
  }
`;

const adminUpdateProfile = gql`
  mutation adminUpdateProfile(
    $gender: String
    $dob: String
    $lookingFor: [String]
    $orientation: String
    $hairColor: String
    $eyeColor: String
    $bloodType: String
    $ethnicity: [String]
    $race: String
    $diet: String
    $occupationTitle: String
    $occupationWorkplace: String
    $income: Int
    $bodyType: String
    $height: Int
    $heightIsMetric: Boolean
    $drink: String
    $smoke: String
    $marijuana: String
    $religion: String
    $religionActivity: String
    $politics: String
    $children: String
    $fertilization: [String]
    $hometownCountry: String
    $hometownZip: String
    $relationshipStatus: String
    $school: String
    $education: String
    $userId: ID
  ) {
    adminUpdateProfile(
      gender: $gender
      dob: $dob
      lookingFor: $lookingFor
      orientation: $orientation
      hairColor: $hairColor
      eyeColor: $eyeColor
      bloodType: $bloodType
      ethnicity: $ethnicity
      race: $race
      diet: $diet
      occupationTitle: $occupationTitle
      occupationWorkplace: $occupationWorkplace
      income: $income
      bodyType: $bodyType
      height: $height
      heightIsMetric: $heightIsMetric
      drink: $drink
      smoke: $smoke
      marijuana: $marijuana
      religion: $religion
      religionActivity: $religionActivity
      politics: $politics
      children: $children
      fertilization: $fertilization
      hometownCountry: $hometownCountry
      hometownZip: $hometownZip
      relationshipStatus: $relationshipStatus
      school: $school
      education: $education
      userId: $userId
    )
  }
`;

const adminDeleteUser = gql`
  mutation adminDeleteUser($id: ID) {
    adminDeleteUser(id: $id)
  }
`;

const adminUpgradeAccount = gql`
  mutation adminUpgradeAccount($userId: ID, $subType: String) {
    adminUpgradeAccount(userId: $userId, subType: $subType)
  }
`;

const adminUpdateAboutUsContent = gql`
  mutation adminUpdateAboutUsContent(
    $contentId: ID
    $content: String
    $header: String
  ) {
    adminUpdateAboutUsContent(
      contentId: $contentId
      content: $content
      header: $header
    )
  }
`;

const adminDeleteAboutUsContent = gql`
  mutation adminDeleteAboutUsContent($contentId: ID) {
    adminDeleteAboutUsContent(contentId: $contentId)
  }
`;

const adminAddAboutUsContent = gql`
  mutation adminAddAboutUsContent($content: String, $header: String) {
    adminAddAboutUsContent(content: $content, header: $header)
  }
`;

const addApi = gql`
  mutation addAPI($token: String!, $api: String!) {
    addApi(token: $token, api: $api)
  }
`;

const updateUserActive = gql`
  mutation updateUserActive($userID: ID, $active: Boolean!) {
    updateUserActive(userID: $userID, active: $active)
  }
`;

const reactivateAccount = gql`
  mutation enableUser {
    enableUser
  }
`;

const archiveConversation = gql`
  mutation archiveConversation($conversationId: ID) {
    archiveConversation(conversationId: $conversationId)
  }
`;

const unArchiveConversation = gql`
  mutation unArchiveConversation($conversationId: ID) {
    unArchiveConversation(conversationId: $conversationId)
  }
`;

const phoneVerification = gql`
  mutation phoneVerification($phone: String!) {
    phoneVerification(phone: $phone)
  }
`;

const phoneVerificationCode = gql`
  mutation phoneVerificationCode($id: String!, $code: String!) {
    phoneVerificationCode(id: $id, code: $code)
  }
`;

export default {
  signUp,
  addApi,
  adminSignIn,
  setMessagesAsRead,
  uploadImages,
  updateConversationStarters,
  createConversationStarters,
  reportUser,
  blockUser,
  unBlockUser,
  addRelationship,
  deleteImage,
  updateSearchPrefs,
  updateCoParentRanks,
  updateProfile,
  updateUser,
  reactivateAccount,
  createCoparentRanks,
  createProfile,
  updateProfileSettings, // Partially save
  googleSignIn,
  appleSignIn,
  deleteAccount,
  facebookSignIn,
  resetPassword,
  upgradeAccount: upgrade,
  updateImageOrder,
  requestPasswordChange,
  addImage,
  adminUpdateProfile,
  adminUpdateUser,
  adminDeleteUser,
  adminUpgradeAccount,
  adminAddAboutUsContent,
  adminDeleteAboutUsContent,
  adminUpdateAboutUsContent,
  signIn,
  updateUserActive,
  archiveConversation,
  unArchiveConversation,
  phoneVerification,
  phoneVerificationCode,
};
