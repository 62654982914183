import React from "react";
/**
 * @description This is used to contain components to the screen
 *
 * @property {Boolean} fullVH - sets the page container to be the size of the viewpdrt
 */
export default class ModamilyAppPageContainer extends React.Component {
	render() {
		let className =
			"pageContainer" + (this.props.fullVH ? " fullViewportHeight" : "");

		return <div className={className}>{this.props.children}</div>;
	}
}
