import React from "react";
import history from "./history";

import ModamilyAppHome from "../pages/ModamilyAppHome";
import ModamilyAppLikes from "../pages/ModamilyAppLikes";
import ModamilyAppBrowse from "../pages/Browse/ModamilyAppBrowse";
import ModamilyAppMessages from "../pages/ModamilyAppMessages";
import ModamilyAppSettings from "../pages/ModamilyAppSettings";
import ModamilyAppOnboarding from "../pages/ModamilyAppOnboarding";
import ModamilyAppAdminSignIn from "../pages/ModamilyAppAdminSignIn";
import ModamilyAppAdminControl from "../pages/ModamilyAppAdminControl";
import ModamilyAppPageNotFound from "../pages/ModamilyAppPageNotFound";
import ModamilyAppConfirmEmail from "../pages/ModamilyAppConfirmEmail";
import ModamilyAppRegisterLogin from "../pages/ModamilyAppRegisterLogin";
import ModamilyAppResetPassword from "../pages/ModamilyAppResetPassword";
import ModamilyAppPrivacyPolicy from "../pages/ModamilyAppPrivacyPolicy";
import ModamilyAppTermsOfService from "../pages/ModamilyAppTermsOfService";
import ModamilyAppSettingsHome from "../pages/ModamilyAppSettingsMobileHome";
import ModamilyAppAdminAboutUsContent from "../pages/ModamilyAppAdminAboutUsContent";
import ModamilyAppAboutUs from "../pages/ModamilyAppAboutUs";

import ModamilyAppWelcome from "../pages/ModamilyAppWelcome";
import ModamilyAppPhoneNumber from "../pages/ModamilyAppPhoneNumber";
import ModamilyAppVerifyPhoneNumber from "../pages/ModamilyAppVerifyPhoneNumber";
import ModamilyAppVerifyEmail from "../pages/ModamilyAppVerifyEmail";
import ModamilyAppWellDone from "../pages/ModamilyAppWellDone";

import queries from "../apollo/queries/queries";
import { withApollo } from "react-apollo";
import decode from "jwt-decode";

import { Router, Route, Redirect } from "react-router-dom";
import ModamilyAppDeactivatedAccount from "../pages/ModamilyAppDeactivatedAccount";
// import ModamilyAppMaintenance from '../pages/ModamilyAppMaintenance';

const index = () => {
  return <ModamilyAppHome />;
};

const pageNotFound = () => {
  return <ModamilyAppPageNotFound />;
};

const aboutUs = (props) => {
  return <ModamilyAppAboutUs {...props} />;
};

const terms = (props) => {
  return <ModamilyAppTermsOfService {...props} />;
};

const privacy = (props) => {
  return <ModamilyAppPrivacyPolicy {...props} />;
};

const settings = (props) => {
  return <ModamilyAppSettings {...props} />;
};

const browse = ({ history }) => {
  return <ModamilyAppBrowse history={history} />;
};

const messages = () => {
  return <ModamilyAppMessages />;
};

const likes = ({ location }) => {
  return <ModamilyAppLikes location={location} />;
};

const onboarding = () => {
  return <ModamilyAppOnboarding />;
};

const welcome = () => {
  return <ModamilyAppWelcome />;
};

const phoneNumber = () => {
  return <ModamilyAppPhoneNumber />;
};

const verifyPhoneNumber = () => {
  return <ModamilyAppVerifyPhoneNumber />;
};

const verifyEmail = () => {
  return <ModamilyAppVerifyEmail />;
};

const wellDone = () => {
  return <ModamilyAppWellDone />;
};

const login = () => {
  return <ModamilyAppRegisterLogin isLogin={true} />;
};

const register = () => {
  return <ModamilyAppRegisterLogin />;
};

const resetPassword = () => {
  return <ModamilyAppResetPassword />;
};

const resetPasswordConfirmEmail = (props) => {
  return <ModamilyAppConfirmEmail {...props} confirmFor="resetPassword" />;
};

const facebookConfirmEmail = (props) => {
  return <ModamilyAppConfirmEmail {...props} confirmFor="facebook" />;
};

const settingsHome = (props) => {
  return <ModamilyAppSettingsHome {...props} />;
};

const adminSignIn = (props) => {
  return <ModamilyAppAdminSignIn {...props} />;
};

const adminControl = (props) => {
  return <ModamilyAppAdminControl {...props} />;
};

const adminEditAbout = (props) => {
  return <ModamilyAppAdminAboutUsContent {...props} />;
};

const isAValidUrl = (path) => {
  if (
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === "development" ||
    "staging.modamily.com" === process.env.REACT_APP_API_REDIRECT_LANDING_URL
  ) {
    return true;
  }

  if (
    window.location.hostname === process.env.REACT_APP_API_REDIRECT_APP_URL &&
    (path === "" || path === "/" || path === "/aboutUs")
  ) {
    global.window &&
      (global.window.location.href =
        "https://" + process.env.REACT_APP_API_REDIRECT_LANDING_URL + path);
  } else if (
    window.location.hostname ===
      process.env.REACT_APP_API_REDIRECT_LANDING_URL &&
    path !== "" &&
    path !== "/" &&
    path !== "/aboutUs"
  ) {
    global.window &&
      (global.window.location.href =
        "https://" + process.env.REACT_APP_API_REDIRECT_APP_URL + path);
  } else {
    return true;
  }
};

class HandleRedirect extends React.Component {
  state = {
    redirect: "",
  };

  componentDidMount = async () => {
    const data = await this.props.client.query({
      query: queries.getOnboardingStatus,
    });
    let redirectPath = localStorage.getItem("redirect");
    let continueToBrowse = true;
    try {
      let response = decode(data.getOnboardingStatus);
      let keys = Object.keys(response.data);
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        if (!response.data[key]) {
          continueToBrowse = false;
        }
      }
    } catch (error) {
      continueToBrowse = false;
    }

    localStorage.removeItem("redirect");
    if (!continueToBrowse) {
      redirectPath = "/onboarding";
    }
    this.setState({ redirect: redirectPath });
  };

  render = () => {
    return this.state.redirect ? <Redirect to={this.state.redirect} /> : null;
  };
}

const ConnectedPrivateRoute = ({
  path,
  exact,
  strict,
  component: Component,
  location,
  client,
  ...rest
}) => (
  <Route
    path={path}
    exact={exact}
    strict={strict}
    location={location}
    render={(props) => {
      if (isAValidUrl(path)) {
        // Check for deactivated account
        if (localStorage.getItem("user")) {
          const user = JSON.parse(localStorage.getItem("user"));
          if (!user.userActive) {
            return (
              <>
                <ModamilyAppDeactivatedAccount />
              </>
            );
          }
        }

        if (
          localStorage.getItem("auth.token") &&
          localStorage.getItem("redirect")
        ) {
          return <HandleRedirect client={client} />;
        } else if (
          !localStorage.getItem("auth.token") &&
          !path.includes("login")
        ) {
          localStorage.setItem("redirect", `${path}${props.location.search}`);
        }

        return localStorage.getItem("auth.token") &&
          localStorage.getItem("user") ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to="/login" />
        );
      }
    }}
  />
);

const PrivateRoute = withApollo(ConnectedPrivateRoute);

const AdminRoute = ({
  path,
  exact,
  strict,
  component: Component,
  location,
  ...rest
}) => (
  <Route
    path={path}
    exact={exact}
    strict={strict}
    location={location}
    render={(props) => {
      if (isAValidUrl(path)) {
        return localStorage.getItem("auth.admin.token") ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to="/login" />
        );
      }
    }}
  />
);

const FacebookPrivateRoute = ({ component: Component, path, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAValidUrl(path)) {
        return localStorage.getItem("social.facebook.token") ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to="/login" />
        );
      }
    }}
  />
);

const CustomDomainRoute = ({
  path,
  exact,
  strict,
  component: Component,
  location,
  ...rest
}) => {
  return (
    <Route
      path={path}
      exact={exact}
      strict={strict}
      location={location}
      render={(props) => {
        if (isAValidUrl(path)) {
          return <Component {...props} />;
        }
      }}
    />
  );
};

function AppRouter() {
  return (
    <Router history={history}>
      <CustomDomainRoute path="/" exact component={index} />
      <PrivateRoute path="/messages" exact component={messages} />
      <PrivateRoute path="/browse" exact component={browse} />
      <PrivateRoute path="/onboarding" exact component={onboarding} />
      <PrivateRoute path="/likes" exact component={likes} />
      <PrivateRoute
        path="/settings/accountDetails"
        menu={"accountDetails"}
        exact
        component={settings}
      />
      <PrivateRoute
        path="/settings/searchPreferences"
        menu={"searchPreferences"}
        exact
        component={settings}
      />
      <PrivateRoute
        path="/settings/editProfile"
        menu={"editProfile"}
        exact
        component={settings}
      />
      <PrivateRoute path="/settings/home" component={settingsHome} />

      <CustomDomainRoute path="/welcome" exact component={welcome} />
      <CustomDomainRoute path="/phone-number" exact component={phoneNumber} />
      <CustomDomainRoute
        path="/verify-number"
        exact
        component={verifyPhoneNumber}
      />
      <CustomDomainRoute path="/verify-email" exact component={verifyEmail} />
      <CustomDomainRoute path="/welldone" exact component={wellDone} />

      <CustomDomainRoute path="/login" exact component={login} />
      <CustomDomainRoute path="/register" exact component={register} />
      <CustomDomainRoute path="/terms" exact component={terms} />
      <CustomDomainRoute path="/termsOfService" exact component={terms} />
      <CustomDomainRoute path="/terms-of-service" exact component={terms} />
      <CustomDomainRoute path="/privacy" exact component={privacy} />
      <CustomDomainRoute path="/privacyPolicy" exact component={privacy} />
      <CustomDomainRoute path="/privacy-policy" exact component={privacy} />
      {/* <Route path="/maintenance" exact component={maintenance}></Route> */}
      <CustomDomainRoute
        path="/auth/resetPassword/:hash"
        exact
        component={ModamilyAppResetPassword}
      />
      <CustomDomainRoute
        path="/confirmEmail/resetPassword"
        exact
        component={resetPasswordConfirmEmail}
      />
      {/* <FacebookPrivateRoute
        path="/facebook/confirmEmail"
        exact
        component={facebookConfirmEmail}
      /> */}
      <AdminRoute path="/admin/control" exact component={adminControl} />
      <AdminRoute path="/admin/edit/aboutUs" exact component={adminEditAbout} />
      <CustomDomainRoute path="/admin/login" exact component={adminSignIn} />
      <CustomDomainRoute path="/aboutUs" exact component={aboutUs} />
      <CustomDomainRoute path="/about-us" exact component={aboutUs} />
      <CustomDomainRoute path="/about" exact component={aboutUs} />
    </Router>
  );
}

export default AppRouter;
