import React from "react";

import NavBar from "../components/common/ModamilyAppNavBar";
import Button from "../components/common/ModamilyAppButton";
import Badge from "../components/common/ModamilyAppBadge";

import TextInput from "../components/form/ModamilyAppTextInput";
import TextArea from "../components/form/ModamilyAppTextArea";

import { Query, Mutation } from "react-apollo";
import Queries from "../apollo/queries/queries";
import Mutations from "../apollo/mutations/mutations";
import decode from "jwt-decode";
import ModamilyAppLoadingComponent from "../components/common/ModamilyAppLoadingComponent";
import ModamilyAppPageContainer from "../components/common/ModamilyPageContainer";
import ModamilyAppFormHeader from "../components/form/ModamilyAppFormHeader";

/**
 * @property {Object} content - content obj
 */
class AdminAboutUsContentEdit extends React.Component {
	state = {
		content: this.props.content.content,
		header: this.props.content.header,
		id: this.props.content.id,
		valid: false,
		updateCreate: !!this.props.content.id
	};

	validate = () => {
		let data = this.state;

		if (data.content && data.header) {
			this.setState({ valid: true });
		} else {
			this.setState({ valid: false });
		}
	};

	render = () => {
		let mutation = this.state.updateCreate
			? Mutations.adminUpdateAboutUsContent
			: Mutations.adminAddAboutUsContent;
		return (
			<Mutation
				mutation={Mutations.adminDeleteAboutUsContent}
				variables={{ contentId: this.state.id }}>
				{(deleteMutation, { loading, error, data }) => {
					if (loading) {
						return <ModamilyAppLoadingComponent />;
					} else if (error) {
						console.log(error);
					} else if (data) {
						// console.log(data);
						if (data.adminDeleteAboutUsContent === "No token detected!") {
							localStorage.clear();
							window.location.reload();
						} else {
							let response = decode(data.adminDeleteAboutUsContent);
							console.log(response);
							this.props.refetch();
						}
					}

					return (
						<Mutation mutation={mutation}>
							{(mutation, { loading, error, data }) => {
								if (loading) {
									return <ModamilyAppLoadingComponent type="dot" />;
								} else if (error) {
									console.log(error);
								} else if (data) {
									if (data != "No token detected!") {
										let response = decode(
											this.state.updateCreate
												? data.adminUpdateAboutUsContent
												: data.adminAddAboutUsContent
										);
										this.props.refetch();
										console.log(response);
									} else {
										localStorage.clear();
										window.location.reload();
									}
								}

								return (
									<div className="adminContentEditCard">
										<div className="contentHeaderEditContainer">
											<TextInput
												label="Header"
												type="text"
												value={this.state.header}
												onContentChange={data => {
													this.setState(
														{ header: data ? data : "" },
														this.validate
													);
												}}
											/>
										</div>
										<div className="contentEditContainer">
											<TextArea
												charLimit={0}
												text={this.state.content}
												onContentChange={data => {
													this.setState(
														{ content: data ? data : "" },
														this.validate
													);
												}}
											/>
										</div>
										<div className="adminContentButtonsContainer fd-row">
											<div className="adminEditContentButtonContainer">
												<Button
													disabled={!this.state.valid}
													text={this.state.updateCreate ? "Update" : "Create"}
													style="orangeGradient"
													onClick={() => {
														mutation({
															variables: {
																header: this.state.header,
																content: this.state.content,
																contentId: this.state.id
															}
														});
													}}
												/>
											</div>
											{this.state.updateCreate ? (
												<div className="adminEditContentButtonContainer">
													<Button
														text={"Delete"}
														style="white"
														onClick={() => {
															deleteMutation();
														}}
													/>
												</div>
											) : null}
										</div>
									</div>
								);
							}}
						</Mutation>
					);
				}}
			</Mutation>
		);
	};
}

/**
 * @property {Array(Object)} data - array of content objects
 */
class AdminAboutUsContentMenu extends React.Component {
	state = { data: this.props.data };

	render = () => {
		let { data } = this.state;

		let components = [];

		for (let index = 0; index < data.length; index++) {
			const content = data[index];

			components.push(
				<AdminAboutUsContentEdit
					content={content}
					refetch={this.props.refetch}
				/>
			);
		}

		components.push(
			<AdminAboutUsContentEdit content={{ header: "", content: "" }} refetch={this.props.refetch} />
		);

		return (
			<div className="AdminEditContent">
				<NavBar type="adminInApp" currentLink="contentEdit" />
				<ModamilyAppPageContainer>
					<ModamilyAppFormHeader text="About Us Content" /> {components}
				</ModamilyAppPageContainer>
			</div>
		);
	};
}

export default class AdminAboutUsContent extends React.Component {
	state = {};

	render = () => {
		return (
			<Query query={Queries.adminGetAboutUsContent}>
				{({ loading, data, error, refetch }) => {
					if (loading) {
						return <ModamilyAppLoadingComponent />;
					} else if (error) {
						console.log(error);
						refetch();
					} else if (data) {
						// console.log(data);
						let response = decode(data.getAboutUsContent);
						return (
							<AdminAboutUsContentMenu
								data={response.data}
								refetch={() => {
									refetch();
								}}
							/>
						);
					}
				}}
			</Query>
		);
	};
}
