import React from 'react';

//form
import FormHeader from "../../../form/ModamilyAppFormHeader";
import MultiSliderInput from "../../../form/ModamilyAppMultiSliderInput";

const AgeRange = ({ searchPreferences, handleUpdate }) => (
  <div className="searchPrefForm">
		<div className="searchPrefAgeRangeFormContainer">
			<FormHeader text="Age Range" />
			<div className="ageRangeSliderContainer">
				<MultiSliderInput
					min={18}
					units={""}
					max={99}
					data={[
						searchPreferences.minAge,
						searchPreferences.maxAge
					]}
					type="age"
					step={1}
					onChange={values => {
						searchPreferences.minAge = values[0];
						searchPreferences.maxAge = values[1];
						handleUpdate(searchPreferences);
					}}
				/>
			</div>
		</div>
	</div>
)

export default AgeRange;

