let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
let numberRegex = /(^\d*\.?\d*[0-9]+\d*$)|(^[0-9]+\d*\.\d*$)/;
let zipRegex = /^(\d{5}((|-)-\d{4})?)|([A-Za-z]\d[A-Za-z][\s\.\-]?(|-)\d[A-Za-z]\d)|[A-Za-z]{1,2}\d{1,2}[A-Za-z]? \d[A-Za-z]{2}$/;
let noWhiteSpaceRegex = /\S/;

module.exports = {
  passwordRegex: {
    //Length check comes first, as it is the fastest check
    minPassLength: /.{8,}/,
    specialCheck: /\W/,
    upperCaseCheck: /[A-Z]/,
    numberCheck: /[0-9]/,
    lowerCaseCheck: /[a-z]/
  },
  emailRegex,
  numberRegex,
  zipRegex,
  noWhiteSpaceRegex
};
