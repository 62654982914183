import React from "react";
import ModamilyAppFormHeader from "../form/ModamilyAppFormHeader";
import moment from "moment";
/**
 * @property {Object} data  - { dob: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppBirthForm extends React.Component {
  state = {
    data: this.props.data || { dob: null },
    valid: false,
  };

  componentDidMount = () => {
    let data = this.state.data;
    if (!data.dob) {
      this.props.invalidate();
    } else {
      this.props.onValidChange(data);
    }

    // let supportsDateInput =
    //   navigator.userAgent.includes('chrome') ||
    //   navigator.userAgent.includes('Chrome');

    // console.log(supportsDateInput);
    this.setState({ supportsDateInput: true });
  };

  onChange = (evt) => {
    let data = evt.target.value;

    console.log(data);

    if (data.length < 1) {
      this.props.invalidate();
    }

    let date = new Date(data);

    if (date != "Invalid Date") {
      if (
        !(this.meetsMinimumAge(date, 18) && !this.meetsMinimumAge(date, 100))
      ) {
        data = null;
        this.props.invalidate();
      } else {
        this.props.onValidChange({ dob: data });
        this.setState({ data: { dob: data } });
      }
    }
  };

  customInputChange = (e, type) => {
    let temp = this.state.data.dob;
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    let fieldIntIndex = parseInt(fieldIndex, 10);
    // console.log(temp);

    if (value.length >= maxLength) {
      // It should not be last input field
      if (fieldIntIndex < 3) {
        // Get the next input field using it's name
        const nextfield = document.querySelector(
          `input[name=field-${fieldIntIndex + 1}]`
        );

        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }

    let data = value.trim();
    console.info("data", data, "dob", this.state.data.dob);
    // if (data.length == 0) {
    //   return null;
    // }

    let date = temp ? temp.split("-") : ["", "", ""];

    switch (type) {
      case "day":
        if (value.length <= 2) {
          date[2] = value;
        }

        break;
      case "month":
        if (value.length <= 2) {
          date[1] = value;
        }
        break;
      case "year":
        if (value.length <= 4) {
          date[0] = value;
        }
        break;
    }

    let bdate = date.join("-");

    this.setState({ data: { dob: bdate } });

    const dateObj = moment(bdate, "YYYY-MM-DD");

    const diff = moment().diff(dateObj, "Y");
    if (isNaN(diff) || diff < 18 || diff > 100) {
      data = null;
      this.props.invalidate();
    } else {
      this.props.onValidChange({ dob: dateObj });
    }
  };

  render() {
    let { supportsDateInput, data } = this.state;
    console.log(supportsDateInput);
    console.log(navigator.userAgent);
    let inputComponent = null;
    let date = data?.dob ? data?.dob.split("-") : ["", "", ""];

    if (!supportsDateInput) {
      inputComponent = (
        <input
          type="date"
          className="birthdayInput"
          value={this.state.data.dob}
          onChange={this.onChange}
        />
      );
    } else {
      inputComponent = (
        <div className="customDateInput">
          <div>
            <label>Month</label>
            <input
              type="number"
              className="monthInput"
              placeholder="12"
              value={date[1]}
              maxLength="2"
              name="field-1"
              onChange={(evt) => {
                this.customInputChange(evt, "month");
              }}
            />
          </div>

          <div>
            <label>Day</label>
            <input
              type="number"
              placeholder="03"
              className="dayInput"
              value={date[2]}
              maxLength="2"
              name="field-2"
              onChange={(evt) => {
                this.customInputChange(evt, "day");
              }}
            />
          </div>
          <div>
            <label>Year</label>
            <input
              type="number"
              placeholder="1995"
              className="yearInput"
              value={date[0]}
              maxLength="4"
              name="field-3"
              onChange={(evt) => {
                this.customInputChange(evt, "year");
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="birthdayForm">
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            When’s your birthday?
          </h2>

          <p>We swear you don’t look your age</p>
        </div>
        <div className="birthdayInputContainer">{inputComponent}</div>
      </div>
    );
  }
}
