export default async coordinates => {
// console.log(coordinates);

  let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
    coordinates.latitude
  },${coordinates.longitude}&key=AIzaSyDWykAZZYDSy_gYID2_q-1PZ_vLXVGtZR0`;

  return await request({ url, method: "GET" });
};

let request = obj => {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open(obj.method || "GET", obj.url);
    if (obj.headers) {
      Object.keys(obj.headers).forEach(key => {
        xhr.setRequestHeader(key, obj.headers[key]);
      });
    }
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(xhr.statusText);
      }
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send(obj.body);
  });
};


