import React from 'react';

//forms
import FormHeader from "../../../form/ModamilyAppFormHeader";
import ButtonGroup from "../../../form/ModamilyAppButtonGroup";

// cosntants
import Options from "../../../../constants/formOptions";

const EducationLevel = ({ searchPreferences, selectedEducationIndexes, handleUpdate }) => (
  <div className="searchPrefForm">
			<div className="searchPrefEducationFormContainer">
				<FormHeader text="Education Level" />
				<ButtonGroup
					manualSelection={true}
					customSelected={selectedEducationIndexes}
					data={Options.educationLevels}
					onButtonClick={(value, index) => {
						if (index > -1) {
							if(value.toLowerCase() === 'no preference') {
								searchPreferences.education = [value.toLowerCase()]
							} else {
								const indexOfPNTS = searchPreferences.education.indexOf('no preference');
								if ( indexOfPNTS > -1) { 
									searchPreferences.education.splice(
										indexOfPNTS,
										1
									);
								}
								searchPreferences.education.push(
									value.toLowerCase()
								);
							}
						} else {
							searchPreferences.education.splice(
								searchPreferences.education.indexOf(
									value.toLowerCase()
								),
								1
							);
						}
						handleUpdate(searchPreferences);
					}}
				/>
			</div>
		</div>
);


export default EducationLevel;