import gql from "graphql-tag";

const test = gql`
  {
    test
  }
`;

const getAccountDetails = gql`
  {
    getAccountDetails
  }
`;

const getMessages = gql`
  query getMessages($conversationId: ID!) {
    getMessages(conversationId: $conversationId)
  }
`;

const getConversations = gql`
  {
    getConversations
  }
`;

const getPotentialMatches = gql`
  {
    getPotentialMatches
  }
`;

const getZipcodeCountryAndSubscription = gql`
  {
    getZipcodeCountryAndSubscription
  }
`;
const getFullProfile = gql`
  query getFullProfile($id: ID!) {
    getFullProfile(id: $id)
  }
`;

const getUsersBrowse = gql`
  query getUsersBrowse(
    $zipcode: String
    $country: String
    $page: Int
    $limit: Int
    $is_free: Int
  ) {
    getUsersBrowse(
      zipcode: $zipcode
      country: $country
      page: $page
      limit: $limit
      is_free: $is_free
    )
  }
`;

const getUsersBrowseV2 = gql`
  query getUsersBrowseV2(
    $zipcode: String
    $country: String
    $page: Int
    $limit: Int
    $is_free: Int
  ) {
    getUsersBrowseV2(
      zipcode: $zipcode
      country: $country
      page: $page
      limit: $limit
      is_free: $is_free
    )
  }
`;

const getBrowse = gql`
  {
    getBrowse
  }
`;

const getUser = gql`
  query getUser($id: ID!) {
    user(id: $id)
  }
`;

const getOnboardingStatus = gql`
  {
    getOnboardingStatus
  }
`;
const getSearchPrefs = gql`
  {
    getSearchPrefs
  }
`;

const getLikes = gql`
  {
    getLikes
  }
`;

const getSubscription = gql`
  {
    getSubscription
  }
`;

const adminGetUsers = gql`
  query adminGetUsers(
    $minHeight: Int
    $maxHeight: Int
    $minAge: Int
    $maxAge: Int
    $minIncome: Int
    $maxIncome: Int
    $distance: Int
    $gender: [String]
    $zipcode: String
    $country: String
    $religion: [String]
    $ethnicity: [String]
    $education: [String]
    $relationshipStatus: [String]
    $eyeColor: [String]
    $hairColor: [String]
    $race: [String]
    $politics: [String]
    $lookingFor: [String]
    $page: Int
    $heightIsMetric: Boolean
    $orientation: String
    $haveAbaby: String
    $lastName: String
    $firstName: String
    $email: String
    $lastLogin: String
    $subscription: [String]
    $all: Boolean
  ) {
    adminGetUsers(
      heightIsMetric: $heightIsMetric
      religion: $religion
      eyeColor: $eyeColor
      hairColor: $hairColor
      education: $education
      relationshipStatus: $relationshipStatus
      ethnicity: $ethnicity
      race: $race
      politics: $politics
      lookingFor: $lookingFor
      minHeight: $minHeight
      maxHeight: $maxHeight
      minAge: $minAge
      maxAge: $maxAge
      minIncome: $minIncome
      maxIncome: $maxIncome
      distance: $distance
      gender: $gender
      zipcode: $zipcode
      country: $country
      orientation: $orientation
      haveAbaby: $haveAbaby
      email: $email
      lastLogin: $lastLogin
      subscription: $subscription
      firstName: $firstName
      lastName: $lastName
      page: $page
      all: $all
    )
  }
`;

const adminSearchUsers = gql`
  query adminSearchUsers($firstName: String, $lastName: String) {
    adminSearchUsers(firstName: $firstName, lastName: $lastName)
  }
`;

const adminGetUserProfile = gql`
  query adminGetUserProfile($userId: ID) {
    adminGetUserProfile(userId: $userId)
  }
`;

const adminGetUserAccountDetails = gql`
  query adminGetUserAccountDetails($userId: ID) {
    adminGetUserAccountDetails(userId: $userId)
  }
`;

const adminGetUserSearchPreferences = gql`
  query adminGetUserSearchPreferences($userId: ID) {
    adminGetUserSearchPreferences(userId: $userId)
  }
`;

const adminGetAboutUsContent = gql`
  {
    getAboutUsContent
  }
`;

export default {
  test,
  getOnboardingStatus,
  getUser,
  getUsersBrowse,
  getUsersBrowseV2,
  getBrowse,
  getFullProfile,
  getLikes,
  getSearchPrefs,
  getConversations,
  getMessages,
  getAccountDetails,
  getSubscription,
  getPotentialMatches,
  adminGetUsers,
  adminSearchUsers,
  adminGetUserProfile,
  adminGetUserAccountDetails,
  adminGetUserSearchPreferences,
  adminGetAboutUsContent,
  getZipcodeCountryAndSubscription,
};
