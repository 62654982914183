import React, { Component } from "react";
import ModamilyAppButton from "./ModamilyAppButton";
import ModamilyAppLinkText from "./ModamilyAppLinkText";
import ModamilyProfileButton from "./ModamilyAppProfileButton";
import ModamilyAppNavHeaders from "./ModamilyAppNavHeaders";

import menuButtonIcon from "../../resources/images/appAssets/menu.png";

import smallLogo from "../../resources/images/appAssets/modamilyText.png";
import mediumLogo from "../../resources/images/appAssets/modamilyText@2x.png";
import largeLogo from "../../resources/images/appAssets/modamilyText@3x.png";

import adminLogo from "../../resources/images/appAssets/modamilyBabyLarge.png";
import ModamilyAppStatusBar from "../../components/ModamilyAppStatusBar";

import { Redirect, Link } from "react-router-dom";
import NavSideMenu from "../common/ModamilyAppNavSideMenu";

/**
 * @property {String} type - "signin" | "register" | "inApp" | "register" | "onboarding" - determines the state of the navbar
 * @property {String} currentLink - used to mark the current like as purple rather than grey
 */
class ModamilyAppNavBar extends Component {
  state = {
    type: this.props.type ? this.props.type : "default",
    redirect: false,
    size: window.innerWidth,
    route: "",
  };

  downloadApp = () => {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.open("https://www.apple.com/ios/app-store/", "_blank");
    } else if (/Android/i.test(navigator.userAgent)) {
      window.open(
        "https://play.google.com/store?utm_source=na_Med&utm_medium=hasem&utm_content=Mar0519&utm_campaign=Evergreen&pcampaignid=MKT-DR-na-us-1000189-Med-hasem-py-Evergreen-Mar0519-Text_Search_BKWS-id_100566_%7cEXA%7cONSEM_kwid_43700023142506977&gclid=Cj0KCQjwtr_mBRDeARIsALfBZA560JMlKsrF5WlLvA0CDbIm2PFp60SQTLhZHZV0vN81wliNECY4yb8aAiYyEALw_wcB&gclsrc=aw.ds",
        "_blank"
      );
    } else {
      window.open(
        "https://play.google.com/store?utm_source=na_Med&utm_medium=hasem&utm_content=Mar0519&utm_campaign=Evergreen&pcampaignid=MKT-DR-na-us-1000189-Med-hasem-py-Evergreen-Mar0519-Text_Search_BKWS-id_100566_%7cEXA%7cONSEM_kwid_43700023142506977&gclid=Cj0KCQjwtr_mBRDeARIsALfBZA560JMlKsrF5WlLvA0CDbIm2PFp60SQTLhZHZV0vN81wliNECY4yb8aAiYyEALw_wcB&gclsrc=aw.ds",
        "_blank"
      );
    }
  };

  redirectToLogin = () => {
    this.setState({ redirect: true, route: "/login" });
  };

  redirectToRegister = () => {
    this.setState({ redirect: true, route: "/welcome" });
  };

  redirectToHome = () => {
    let auth = localStorage.getItem("auth.token");
    let route = auth ? "/browse" : "/";

    this.setState({ redirect: true, route });
  };

  showSideMenu = () => {
    this.setState({ showSideMenu: true });
  };

  hideSideMenu = () => {
    this.setState({ showSideMenu: false });
  };

  render() {
    const user = localStorage.getItem("user");

    if (this.state.redirect) {
      return <Redirect to={this.state.route} />;
    }

    let size = this.state.size;

    let links = null;
    let sideButtons = null;
    let navClass = "nav";
    //Determines how the links and side buttons render
    switch (this.state.type) {
      case "signin":
        sideButtons = (
          <div className="navEle navBtns">
            <div className="navBtnContainer">
              <ModamilyAppButton text="Sign in" style="red" />
            </div>
            <div className="navBtnContainer">
              <ModamilyAppButton
                text="Sign up"
                style="white"
                onClick={this.redirectToRegister}
              />
            </div>
          </div>
        );
        navClass += " navSignRegister";
        links = " ";
        break;
      case "register":
        return (
          <div className="align-center mt-32">
            <ModamilyAppNavHeaders component="shieldHeader" />
          </div>
        );
      case "inApp":
        try {
          sideButtons = (
            <ModamilyProfileButton
              username={
                (user.firstName || "") +
                " " +
                (user.lastName ? user.lastName.slice(0, 1) : "") +
                "."
              }
            />
          );
          let currentLink = this.props.currentLink;

          links = [
            <ModamilyAppLinkText
              key={0}
              color={currentLink === "browse" ? "purple" : "grey"}
              text="BROWSE"
              link="/browse"
            />,
            <ModamilyAppLinkText
              key={1}
              color={currentLink === "likes" ? "purple" : "grey"}
              text="LIKES"
              link="/likes"
            />,
            <ModamilyAppLinkText
              key={2}
              color={currentLink === "messages" ? "purple" : "grey"}
              text="MESSAGES"
              link="/messages"
            />,
          ];
          navClass += " inAppNav";
        } catch (exception) {
          console.log(exception);
          return <Redirect to="/login" />;
        }
        break;
      case "onboarding":
        navClass += " onboardingNav";

        break;
      case "admin":
        navClass += " adminNav";

        break;
      case "adminInApp":
        navClass += " adminNav";

        let currentLink = this.props.currentLink;

        links = [
          <ModamilyAppLinkText
            key={0}
            color={currentLink === "control" ? "white" : "grey"}
            text="Control"
            link="/admin/control"
          />,
          <ModamilyAppLinkText
            key={1}
            color={currentLink === "contentEdit" ? "white" : "grey"}
            text="Edit Content"
            link="/admin/edit/aboutUs"
          />,
        ];
        break;
      default:
        sideButtons = (
          <div className="navEle navBtns">
            <div className="navBtnContainer">
              <Link to="/welcome">
                <ModamilyAppButton text="Join" style="white" />
              </Link>
            </div>
            <div className="navBtnContainer">
              <Link to="/login">
                <ModamilyAppButton text="Sign in" style="orangeGradient" />
              </Link>
            </div>
          </div>
        );
        links = [
          <ModamilyAppLinkText
            key={"aboutLink"}
            color="grey"
            text="ABOUT"
            link="/aboutUs"
          />,
          <ModamilyAppLinkText
            key={"servicesLink"}
            color="grey"
            text="SERVICES"
            newTab={true}
            link="https://www.modamilyconcierge.com/"
          />,
          <ModamilyAppLinkText
            key={"supportLink"}
            color="grey"
            text="SUPPORT"
            newTab={true}
            link="mailto:support@modamily.com"
          />,
        ];
        break;
    }

    let linksContainer = links ? (
      <div className="navEle navLinks">{links}</div>
    ) : null;

    let logoContainerClassName = links
      ? "navEle navLogoContainer"
      : "navEle navOnboardingLogoContainer";

    let logoImg = smallLogo;
    if (this.state.type != "onboarding") {
      window.addEventListener("resize", (e) => {
        this.setState({ size: window.innerWidth });
      });

      if (size > 650) {
        logoImg = mediumLogo;
      }
      if (size > 1920) {
        logoImg = largeLogo;
      }

      if (
        size < 850 &&
        (this.state.type != "admin") & (this.state.type != "adminInApp")
      ) {
        sideButtons = (
          <div onClick={this.showSideMenu} className="sideMenuButtonContainer">
            <img className="sideMenuButtonImage" src={menuButtonIcon} />
          </div>
        );
      }
    }
    let logoLink = "/";

    if (this.state.type === "admin" || this.state.type === "adminInApp") {
      logoImg = adminLogo;
      logoLink += "login";
    }

    if (this.props.returnToHome) {
      logoLink = "/";
    }

    const showNavBarType = () => {
      if (this.state.type !== "onboarding") {
        return (
          <Link to={logoLink}>
            <img className="navLogo" src={logoImg} alt="" />
          </Link>
        );
      }

      if (this.state.type === "register") {
        return <div></div>;
      }

      if (this.props.step > 6 && this.props.step < 8) {
        return (
          <ModamilyAppNavHeaders
            component="steperBackChevronHeader"
            step={this.props.step}
            previousStep={this.props.previousStep}
          />
        );
      }

      if (this.props.step >= 8) {
        return (
          <ModamilyAppNavHeaders
            component="skipHeader"
            previousStep={this.props.previousStep}
            nextStep={this.props.nextStep}
          />
        );
      }

      return (
        <ModamilyAppNavHeaders
          component="steperHeader"
          step={this.props.step}
          previousStep={this.props.previousStep}
        />
      );
    };

    return (
      <div key={size + "windowSize"} className={navClass}>
        <div
          onClick={() => {
            if (this.props.returnToHome) {
              localStorage.clear();
            }
          }}
          className={logoContainerClassName}
        >
          {/* {this.state.type !== "onboarding" ? (
            <Link to={logoLink}>
              <img className="navLogo" src={logoImg} alt="" />
            </Link>
          ) : (
            <ModamilyAppStatusBar />
          )} */}

          {showNavBarType()}
        </div>
        {linksContainer}
        {sideButtons}
        {size < 850 && this.state.type !== "onboarding" ? (
          <NavSideMenu
            active={this.state.showSideMenu}
            close={this.hideSideMenu}
            loggedIn={this.state.type === "inApp"}
          />
        ) : null}
      </div>
    );
  }
}

export default ModamilyAppNavBar;
