import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import decode from "jwt-decode";
import clsx from "clsx";
import ModamilyAppFormHeader from "../form/ModamilyAppFormHeader";
import ModamilyAppButton from "../common/ModamilyAppButton";
import Badge from "../common/ModamilyAppBadge";

import Queries from "../../apollo/queries/queries";
import Mutations from "../../apollo/mutations/mutations";
import SubscriptionOptions from "../../constants/subscriptionTypes";
import config from "../../config/config";

import closeImg from "../../resources/images/appAssets/closePurple.png";

/**
 * @property {Function} closeModal - function used to close the modal
 * @property {Boolean} active - boolean determining if the modal is shown or hidden
 */
export default class ModamilyProfileNotFound extends React.Component {
  render = () => {
    let active = this.props.active;

    return (
      <div
        className={"modalCurtain" + (active ? " curtainDrag" : " modalHidden")}
      >
        <div className="profileNotFoundModalCard">
          <div className="upgradeModalCardHeader">
            <div
              className="upgradeModalCloseButton"
              onClick={this.props.closeModal}
            >
              <img className="closeButton" src={closeImg} />
            </div>
            <div className="upgradeModalHeaderContent">
              <ModamilyAppFormHeader text="Profile Not Found" />

              <p className="greyText semiBoldWeight">
                This user may have deactivated their account.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
