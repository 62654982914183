import { takeEvery, fork } from 'redux-saga/effects';
import * as actions from '../actions/users';

function* getUser({ id }) {
  try {
  } catch(e) {
    console.log('error fetching user', e);
  }
}

function* watchGetUserRequest() {
  yield takeEvery(actions.Types.GET_USER_REQUEST, getUser);
}

const userSagas = [
  fork(watchGetUserRequest)
];

export default userSagas;