import React from "react";

import { Link } from "react-router-dom";

/** ModamilyAppLinkText
 * @description Link text used for linking users to other pages
 *
 * @property {String} color - 'red', 'grey', 'white', 'purple'. Determines the color of the text. default: red
 * @property {String} link - the url that will be loaded
 * @property {String} text - the display text of the clickable link
 * @property {Boolean} newTab - display the link in a new tab
 */
export default class ModamilyAppLinkText extends React.Component {
	render = () => {
		return this.props.newTab ? (
			<a
				className={(this.props.color ? this.props.color : "red") + "Link"}
				target={this.props.newTab ? "_blank" : "_self"}
				href={this.props.link}
				rel="noopener noreferrer">
				<p className={(this.props.color ? this.props.color : "red") + "Text"}>
					{this.props.text}
				</p>
			</a>
		) : (
			<Link to={this.props.link}>
				<p className={(this.props.color ? this.props.color : "red") + "Text"}>
					{this.props.text}
				</p>
			</Link>
		);
	};
}
