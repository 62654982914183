import React from "react";
import ButtonGroup from "../form/ModamilyAppButtonGroup";
import FormHeader from "../form/ModamilyAppFormHeader";
import Subtitle from "../common/ModmilyAppSubtitle";
import ModamilyAppScrollBarOptions from "../common/ModamilyAppScrollBarOptions";

/**
 * @property {Object} data  - { eyeColor: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppEyeColor extends React.Component {
  state = {
    data: this.props.data || { eyeColor: null },
  };

  buttonOptions = ["black", "blue", "brown", "green", "hazel", "other"];

  componentWillMount = () => {
    this.validate();
  };

  validate = () => {
    let data = this.state.data;
    if (!data.eyeColor) {
      this.props.invalidate();
    } else if (data.eyeColor) {
      console.log(data);
      this.props.onValidChange(data);
    }
  };

  handleButtonClick = (value, index, isActive) => {
    let data = this.state.data;
    data.eyeColor = isActive ? value : null;
    this.props.scrollToBottom && this.props.scrollToBottom();
    this.validate();
  };

  render = () => {
    let data = this.state.data;

    let eyeColorIndex = this.buttonOptions.indexOf(data.eyeColor);

    return (
      <div className="hairColorForm">
        {/* <FormHeader text="Eye Color" /> */}
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            What’s your eye color?
          </h2>
        </div>
        <ModamilyAppScrollBarOptions>
          <ButtonGroup
            selected={eyeColorIndex}
            type="radio"
            data={this.buttonOptions}
            onButtonClick={this.handleButtonClick}
          />
        </ModamilyAppScrollBarOptions>
      </div>
    );
  };
}
