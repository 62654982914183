import React from "react";
import TextInput from "../form/ModamilyAppTextInput";
import ButtonGroup from "../form/ModamilyAppButtonGroup";
import FormHeader from "../form/ModamilyAppFormHeader";
import ModamilyOnboardingFooterText from "../common/ModamilyOnboardingFooterText";
import closePurpleImg from "../../resources/images/appAssets/closePurple.png";

/**
 * @property data [Object] - { educationLevel: null, school: null } - default data for the form
 * @property invalidate [Function] - Function that gets called when the form is invalid
 * @property onValidChange[Function]- Function that gets called when the form is valid
 */
export default class ModamilyAppEducation extends React.Component {
  state = {
    data: this.props.data || { educationLevel: null, school: null },
  };

  buttonOptions = [
    "postgrad",
    "undergrad",
    "some college",
    "highschool or equivalent",
    "prefer not to say",
  ];

  componentDidMount = () => {
    this.validate();
  };

  validate = () => {
    let data = this.state.data;
    console.log(data);
    if (!data.educationLevel) {
      this.props.invalidate();
    } else {
      this.props.onValidChange(data);
    }
  };

  /**
   * @argument key [String] - key of the value that is to be changed
   */
  updateData = (key, value) => {
    let data = this.state.data;

    data[key] = value;

    let state = this.state;

    state.data = data;

    this.setState(state);
    if (!value) {
      this.props.invalidate();
    } else {
      this.validate();
    }
  };

  handleButtonClick = (value, index, isActive) => {
    this.updateData("educationLevel", isActive ? value : null);
  };

  render = () => {
    let { data } = this.state;
    let selected = -1;
    if (data.educationLevel) {
      selected = this.buttonOptions.indexOf(data.educationLevel.toLowerCase());
    }

    const disabledNext = selected === -1;
    return (
      <div className="hairColorForm">
        {/* <FormHeader text="Education"></FormHeader> */}
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            What’s your highest education level?
          </h2>
        </div>
        {!this.props.hasEducationLevel ? (
          <>
            <ButtonGroup
              data={this.buttonOptions}
              selected={selected}
              type="radio"
              onButtonClick={this.handleButtonClick}
            ></ButtonGroup>
            <div className="row mt-48 align-center OnboardingButtonContainer">
              <ModamilyOnboardingFooterText text="We will use this to find the perfect match for you. You can change it later." />

              <div className="lookingForButton">
                <button
                  disabled={disabledNext}
                  className={`gradientOrangeButton whiteText button ${
                    disabledNext ? "disabledButton" : ""
                  }`}
                  onClick={() => this.props.handleEducationLevel(true)}
                >
                  Next
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row align-center px-20 border-selected py-20">
              <div>
                <p>{data.educationLevel}</p>
              </div>
              <div
                onClick={() => this.props.handleEducationLevel(false)}
                className="closeButtonImg"
              >
                <img
                  className="closeImg"
                  src={closePurpleImg}
                  alt="Close button"
                />
              </div>
            </div>

            <div className="schoolContainer ">
              <label>School</label>
              <TextInput
                placeholder="School"
                value={data.school || ""}
                onContentChange={(data) => {
                  if (typeof data === "boolean") {
                    this.updateData("school", null);
                  } else {
                    this.updateData("school", data);
                  }
                }}
              />
            </div>
          </>
        )}
      </div>
    );
  };
}
