import React from "react";
import ProfileMenu from "../components/common/ModamilyAppProfileMenu";
import NavBar from "../components/common/ModamilyAppNavBar";
import UpgradeModal from "../components/modals/ModamilyAppUpgrade";

export default class ModamilyAppSettingsHome extends React.Component {
	state = { displayUpgrade: false };

	openModal = () => {
		this.setState({ displayUpgrade: true });
	};

	closeModal = () => {
		this.setState({ displayUpgrade: false });
	};

	render = () => {
		return (
			<div className="ModamilyAppSettingsHome">
				<NavBar type="inApp" />
				<ProfileMenu active={true} openModal={this.openModal} />
				<UpgradeModal
					closeModal={this.closeModal}
					active={this.state.displayUpgrade}
				/>
			</div>
		);
	};
}
