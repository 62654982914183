import React from "react";

/**
 * @property {String} text - default text for the text area
 * @property {Boolean} disabled - whether the area is disabled or not
 * @property {Integer} charLimit - the limit of characters in the area
 * @property {Function} onContentChange - a function ran when the content changes
 * @property {String}  placeholderText - text that is there for a placeholder
 */
export default class ModamilyAppTextArea extends React.Component {
	state = {
		content: this.props.text || "",
		charLimit: this.props.charLimit >= 0 ? this.props.charLimit : 150,
		disabled: this.props.disabled || false
	};

	onContentChange = evt => {
		let val = evt.target.value;
		this.setState({ content: val }, () => {
			if (this.props.onContentChange)
				this.props.onContentChange(this.state.content);
		});
	};

	render = () => {
		let charLimit= this.state.charLimit;
		let charAmount = this.state.charLimit - this.state.content.length;

		if(this.state.charLimit == 0){
			charLimit= null
			charAmount = this.state.content.length;
		}

		return (
			<div className="textArea">
				<textarea
					onChange={this.onContentChange}
					disabled={this.state.disabled}
					maxLength={charLimit}
					placeholder={this.state.placeholderText}
					value={this.state.content}
				/>
				<div className="counterContainer">
					<p className="counterText">
						{charAmount}
					</p>
				</div>
			</div>
		);
	};
}
