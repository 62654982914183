import React, { Component } from "react";
import ModamilyAppNavBar from "../components/common/ModamilyAppNavBar";

import history from "../router/history";

class ModamilyAppPageNotFound extends Component {
	render() {
		history.push("/error");
		return (
			<div className="ModamilyAppPageNotFound">
				<ModamilyAppNavBar type="inApp" />
				<div className="pageNotFoundMessage">
					<p className="redText pageNotFoundText">
						Sorry, it seems that we can't find that page
					</p>
				</div>
			</div>
		);
	}
}

export default ModamilyAppPageNotFound;
