import React, { Component } from "react";

import goalPointImage from "../../resources/images/appAssets/like@3x.png";

class ModamilyAppGoalsSection extends Component {
	render() {
		return (
			<div className="goalBox">
				<div className="goalBoxContentContainer">
					<img className="goalPointImg" src={goalPointImage} alt="" />
					<p className="goalPointText">{this.props.text}</p>
				</div>
			</div>
		);
	}
}
export default ModamilyAppGoalsSection;
