import React from "react";

//Import images for the badge
import plusImage from "../../resources/images/appAssets/plus.png";
import deleteImage from "../../resources/images/appAssets/deleteColor.png";
import editImage from "../../resources/images/appAssets/editColor.png";

/**
 * @property {String} type  the type of badge image - "text" || "add" || "delete" || "edit" || " custom"
 * @property {Function} onClick function fired when the component is clicked
 * @property {String} text - text for the text badge
 * @property {String} color - changes the color of the text badge
 * @property {image} image  custom image for the badge
 */
export default class ModamilyAppBadge extends React.Component {
  render = () => {
    let image = null;

    switch (this.props.type) {
      case "add":
        image = plusImage;
        break;
      case "delete":
        image = deleteImage;
        break;
      case "edit":
        image = editImage;
        break;
      case "custom":
        image = this.props.image;
        break;
      case "text":
        image = (
          <div
            className={
              "badge textBadge" +
              (this.props.color
                ? " " + this.props.color + "Badge"
                : " redBadge")
            }
          >
            <p>{this.props.text}</p>
          </div>
        );
        break;
      default:
        image = null;
        break;
    }

    return (
      <div className="badgeContainer">
        {this.props.type !== "text" ? (
          <img
            onClick={this.props.onClick}
            className="badge"
            src={image}
            style={{ cursor: "pointer" }}
          />
        ) : (
          image
        )}

        {this.props.children}
      </div>
    );
  };
}
