import React, { useState } from "react";

//form
import FormHeader from "../../../form/ModamilyAppFormHeader";
import ButtonGroup from "../../../form/ModamilyAppButtonGroup";

// cosntants
import Options from "../../../../constants/formOptions";
import ModamilyAppCheckButton from "../../../form/ModamilyAppCheckButton";
import { capitalize } from "lodash";
import ModamilyAppRadioButton from "../../../form/ModamilyAppRadioButton";
import { useEffect } from "react";

const LookingFor = ({
  gender,
  selectedLookingForIndexes,
  searchPreferences,
  handleUpdate,
}) => {
  const [selected, setSelected] = useState([...selectedLookingForIndexes]);
  const [coParentOptions, setCoParentOptions] = useState([
    {
      id: 0,
      text: "Dating (Romantic)",
      value: "co-parent-romantic",
      selected: selectedLookingForIndexes.includes("co-parent-romantic"),
    },
    {
      id: 1,
      text: "Platonic (Friends)",
      value: "co-parent-friends",
      selected: selectedLookingForIndexes.includes("co-parent-friends"),
    },
    {
      id: 2,
      text: "Open To Both",
      value: "co-parent",
      selected: selectedLookingForIndexes.includes("co-parent"),
    },
  ]);

  useEffect(() => {
    searchPreferences.lookingFor = getLookingForValues();
    handleUpdate(searchPreferences);
  }, [selected, coParentOptions]);

  const getLookingForValues = () => {
    const opts = selected.map((s) => {
      if (s.startsWith("co-parent")) {
        return coParentOptions.find((a) => a.selected === true).value;
      }
      return s;
    });

    return opts;
  };

  const checkOptions = (option) => {
    const sel = selected.map((s) =>
      s.indexOf("co-parent") > -1 ? "co-parent" : s
    );
    const newSelection = [];
    let coParentOpts = coParentOptions;
    const opt = option;
    if (sel.indexOf(opt) < 0) {
      newSelection.push(opt);
      newSelection.push(...sel);
      if (opt === "co-parent") {
        coParentOpts = coParentOpts.map((o) => {
          o.selected = o.value === "co-parent";
          return o;
        });
      }
    } else {
      newSelection.push(...sel.filter((e) => !e.startsWith(opt)));
      if (opt.indexOf("co-parent") > -1) {
        coParentOpts = coParentOpts.map((o) => {
          o.selected = false;
          return o;
        });
      }
    }
    setSelected([...newSelection]);
    setCoParentOptions([...coParentOpts]);
  };

  const checkCoparentOption = (opt) => {
    const coParentOpts = coParentOptions.map((c) => {
      c.selected = c.value === opt;
      return c;
    });
    setCoParentOptions(coParentOpts);
  };

  const getCoparentOptsView = () => {
    return coParentOptions.map((c, idx) => {
      return (
        <ModamilyAppRadioButton
          text={c.text}
          key={`coparent_key_${idx}`}
          active={c.selected}
          onClick={(isActive) => {
            checkCoparentOption(c.value);
          }}
        />
      );
    });
  };

  let hasCoParentOption = false;
  coParentOptions.forEach((s) => {
    if (s.selected) {
      hasCoParentOption = true;
    }
  });
  let opts = Options.lookingFors;
  if (gender !== "") {
    if (gender === "man") {
      opts = opts.filter((f) => !f.startsWith("looking"));
    } else if (gender === "woman") {
      opts = opts.filter((f) => !f.startsWith("open"));
    }
  }

  return (
    <div className="searchPrefForm">
      <div className="searchPrefLookingForFormContainer">
        <FormHeader text="What are you looking for?" />
        <p className="purpleText">
          Choose the ones that apply to you or to your couple.
        </p>
        {opts.map((o, idx) => {
          let coParentOptsView = null;
          let sel = selected.includes(o);
          if (o === "co-parent" && hasCoParentOption) {
            sel = true;
            coParentOptsView = getCoparentOptsView();
          }

          return (
            <>
              <ModamilyAppCheckButton
                text={capitalize(o)}
                key={idx}
                active={sel}
                onClick={(isActive) => {
                  console.info(o, idx, isActive);
                  checkOptions(o);
                }}
                // manualSelection={this.props.manualSelection}
              />
              {coParentOptsView}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default LookingFor;
