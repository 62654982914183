import React, { Component } from "react";
import ModamilyAppNewsletterBox from "./ModamilyAppNewsletterBox";
import ModamilyAppSocialMediaBox from "./ModamilyAppSocialMediaBox";
import ModamilyAppSiteLinks from "./ModamilyAppSiteLinks";
import { facebookAppID } from "../../config/config";

/**
 * @property {String} type - determines the content and style of footer
 */
class ModamilyAppFooter extends Component {
	render() {
		let footerContent;
		let footerStyle = "footer";

		switch (this.props.type) {
			case "signup":
				footerStyle += " signUpFooter";
				footerContent = this.props.children;
				break;
			case "onboarding":
				footerStyle += " onboardingFooter";
				footerContent = this.props.children;
				break;
			case "onboardingHidden":
				footerStyle += " onboardingFooterHidden";
				footerContent = this.props.children;
				break;
			case "home":
				footerContent = [
					<ModamilyAppSocialMediaBox
						key={0}
						twitterLink="https://twitter.com/modamily?lang=en"
						instagramLink="https://www.instagram.com/modamily/?hl=en"
						facebookLink="https://www.facebook.com/modamily/"
					/>,
					<ModamilyAppSiteLinks key={1}/>,
					<div id="google_translate_element" key={2}/>,
					<p className="copyright"key={3}>© 2019 Modamily | All Rights Reserved</p>
				];
				break;
			default:
				footerContent = this.props.children;
				footerStyle += " signUpFooter";
				break;
		}

		return <div className={footerStyle}>{footerContent}</div>;
	}
}
export default ModamilyAppFooter;
