import React from "react";

import NavBar from "../components/common/ModamilyAppNavBar";
import FormHeader from "../components/form/ModamilyAppFormHeader";
import PageContainer from "../components/common/ModamilyPageContainer";

import decode from "jwt-decode";
import { Query } from "react-apollo";
import Queries from "../apollo/queries/queries";
import ModamilyAppLoadingComponent from "../components/common/ModamilyAppLoadingComponent";

export default class ModamilyAppAboutUs extends React.Component {
	createElements = content => {
		let container = [];

		for (let index = 0; index < content.length; index++) {
			const element = content[index];
			container.push(
				<div key={index + "_" + element.id}>
					<FormHeader text={element.header} />
					<p>{element.content}</p>
				</div>
			);
		}

		return container;
	};

	render = () => {
		return (
			<Query query={Queries.adminGetAboutUsContent}>
				{({ loading, data, error, refetch }) => {
					let content = null;
					if (loading) {
						return <ModamilyAppLoadingComponent />;
					} else if (error) {
						console.log(error);
					} else if (data) {
						let response = decode(data.getAboutUsContent);
						content = this.createElements(response.data);
					}

					return (
						<div className="aboutUsPage">
							<NavBar type="onboarding" />
							<PageContainer>
								<FormHeader text="About Us" />
								<br />
								<p>
									Media or Investor Inquiries:
									<accent className="redText">contact@modamily.com</accent>
								</p>
								<br />
								<p>
									Customer Support:
									<accent className="redText">support@modamily.com</accent>
								</p>
								<br />
								<br />
								{content}
								<p>
									Contact us at{" "}
									<accent className="redText regularWeight">
										contact@modamily.com
									</accent>{" "}
									for further inquiries.
								</p>
								<FormHeader text="Who are Modamily's clients?" />
								<div className="termsSublist">
									<ul>
										<li>
											{" "}
											Single men and women who are ready to start a family or
											help you start your family
										</li>
										<li>
											Gay couples wishing to find an egg donor for a surrogacy
											arrangement, or a known donor who will also be involved in
											the child's life
										</li>
										<li>
											Lesbian couples looking for a sperm donor - Meet a free
											known sperm donor who could play a Great Uncle role
										</li>
										<li>Men wishing to be a known donor</li>
										<li>Women wishing to be an egg donor</li>
										<li>Women wishing to be surrogates</li>
										<li>
											Men and Women looking for medical and legal information on
											how to become co-parents
										</li>
									</ul>
								</div>
							</PageContainer>
						</div>
					);
				}}
			</Query>
		);
	};
}
