import React from "react";
import ModamilyAppScrollBarOptions from "../common/ModamilyAppScrollBarOptions";

/**
 * @property {Object} data - { conversationStarters: [] } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppConversationStarters extends React.Component {
  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getItem("user"));

    const defaultStarters = this.questions.map((q, idx) => {
      const savedAnswer =
        this.props.data.conversationStarters.find((c) => c.question === q)
          ?.answer || null;

      return {
        question: q,
        answer: savedAnswer,
      };
    });

    this.state = {
      data: { conversationStarters: defaultStarters },
      edit: false,
      selected: -1,
      user,
    };
  }

  componentWillMount() {
    this.validate();
  }

  questions = [
    "A few words about yourself",
    `What's your favorite movie?`,
    "Favorite quality in a person?",
    "What is your go to Karaoke song?",
    "Do you speak any other languages?",
    "Who is someone that inspires you?",
    `What's are your favorite TV shows?`,
    "What did you want to be growing up?",
    "What do you like to do on the weekend?",
    "What things do you like to do after work?",
    "If you won the lottery, what would you do?",
    `What is a favorite trip you've taken recently?`,
    "If you could interview anyone, who would it be?",
    "What recent accomplishment are you most proud of?",
    "Two truths and one lie, guess which one is false.",
    `What's the most impactful thing you've read recently?`,
    "Do you see your child going to private school or public school?",
    "Do you want to raise your child in the same religion that you grew up in?",
    "If you were to pack a bag and go on a trip tomorrow, where would you want to go?",
  ];

  addConvoStarter = (index) => {
    this.setState({ edit: true, selected: index });
  };

  validate = () => {
    let atLeast1 = false;
    let conversationStarters = this.state.data.conversationStarters;

    if (this.props.onEditProfile) {
      atLeast1 = true;
    } else {
      for (let index = 0; index < conversationStarters.length; index++) {
        const starter = conversationStarters[index];
        if (starter) {
          if (starter.question && starter.answer) {
            atLeast1 = true;
          }
        }
      }
    }

    if (atLeast1) {
      this.props.onValidChange(this.state.data);
    }
  };

  selectQuestionHandler = (question) => {
    let { data } = this.state;

    //get the index where the user clicks
    const idx = this.questions.indexOf(question);
    let selected = data.conversationStarters[idx] || {};

    //set the question state
    selected.question = question;
    selected.answer = "";
    selected.update = true;

    this.setState({ selected, data });
  };

  removeConversationStarter = (index) => {
    let data = this.state.data;

    let convo = data.conversationStarters[index];

    convo.question = "";
    convo.answer = "";

    data.conversationStarters[index] = convo;

    this.setState({ data }, this.validate);
  };

  updateSelectedAnswer = (question, answer) => {
    let { data } = this.state;

    //get the index where the user clicks
    const idx = this.questions.indexOf(question);
    let selected = data.conversationStarters[idx] || {};

    selected.answer = answer;
    console.log(selected);
    this.setState({ selected, data }, this.validate);
  };

  completeConvoStarter = () => {
    this.setState({ edit: false, selected: -1 }, this.validate);
  };
  cancelConvoStarter = () => {
    let data = this.state.data;
    let selected = data.conversationStarters[this.state.selected];

    selected.question = "";
    selected.answer = "";
    this.setState({ selected: -1, data, edit: false });
  };

  renderEditForm = () => {
    let { selected, data } = this.state;

    let question =
      data.conversationStarters.length > selected
        ? data.conversationStarters[selected].question
        : null;

    // console.log(question);

    let selectedQuestions = [];

    for (let index = 0; index < data.conversationStarters.length; index++) {
      const starter = data.conversationStarters[index];
      selectedQuestions.push(starter.question);
    }

    console.log(selectedQuestions);

    if (!question) {
      return (
        <div className="hairColorForm">
          <div className="ModamilyAppOnboarding__info">
            <h2 className="ModamilyAppOnboarding__info--title">
              What are you looking for?
            </h2>

            <p>Choose the ones that apply to you or to your couple.</p>
          </div>
          {/* <QuestionModal
            selectedQuestions={selectedQuestions}
            onSelect={(q) => {
              this.selectQuestionHandler(q);
            }}
            active={true}
            hideFooter={this.props.hideFooter}
          /> */}
        </div>
      );
    }

    let isIncomplete = !(data.conversationStarters[selected].answer.length > 0);

    return (
      <div className="hairColorForm">
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            Add your first <span>conversation starter</span>
          </h2>

          <p>
            Help others understand more about your personality and/or parenting
            expectations.
          </p>
        </div>
        {/* <div className="selectedQuestionContainer">
          <Badge
            type="delete"
            onClick={(e) => {
              e.stopPropagation();
              this.selectQuestionHandler(null);
            }}
          >
            <div className="cardContainer">
              <div>
                <p className="purpleText regularWeight">{question}</p>
              </div>
            </div>
          </Badge>
        </div>
        <TextArea
          onContentChange={this.updateSelectedAnswer}
          placeholderText="Answer here"
          text={data.conversationStarters[selected].answer}
        />
        <div className="convoFormButtonContainer">
          <Button
            text={isIncomplete ? "Cancel" : "Done"}
            style={isIncomplete ? "white" : "orangeGradient"}
            onClick={
              isIncomplete ? this.cancelConvoStarter : this.completeConvoStarter
            }
          />
        </div> */}
        <div />
      </div>
    );
  };

  render() {
    let { edit, data, selected } = this.state;

    return (
      <div className="hairColorForm">
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            Add your first <span>conversation starter</span>
          </h2>

          <p>
            Help others understand more about your personality and/or parenting
            expectations.
          </p>
        </div>
        <ModamilyAppScrollBarOptions>
          <div className="conversationCardContainer">
            {this.questions.map((question, index) => {
              const starter = data.conversationStarters[index];

              if (starter?.update || starter?.answer) {
                return (
                  <div className="convoTextArea" key={index}>
                    <div className="questionContainer">
                      <p className={"purpleText BoldWeight"}>{question}</p>
                    </div>
                    <textarea
                      value={starter?.answer}
                      onChange={(e) =>
                        this.updateSelectedAnswer(question, e.target.value)
                      }
                      rows={5}
                      cols={5}
                    />
                  </div>
                );
              }
              return (
                <div
                  key={question.length + index}
                  className="convoCardContainer"
                  onClick={() => {
                    this.selectQuestionHandler(question);
                  }}
                >
                  <ConversationCard
                    question={question}
                    edit={edit}
                    data={data}
                    selected={selected}
                  />
                </div>
              );
            })}
          </div>
        </ModamilyAppScrollBarOptions>
      </div>
    );
  }
}

class ConversationCard extends React.Component {
  render = () => {
    let { question, answer, edit, data, selected } = this.props;

    if (!question) {
      question = "Add a question to answer...";
      answer = null;
    }

    let questionTextClass = "question";
    if (answer) {
      questionTextClass = "purpleText BoldWeight";
    }

    return (
      <div className="conversationCard">
        <div className="questionContainer">
          <p className={questionTextClass}>{question}</p>
        </div>
      </div>
    );
  };
}
