import React, { Component } from "react";

class ModamilyAppSocialMediaBox extends Component {
	render() {
		return (
			<div className="socialMediaBox">
				<a
					href={this.props.twitterLink}
					target="_blank"
					rel="noopener noreferrer">
					<img
						className="twSocialMediaImg"
						src={require("../../resources/images/appAssets/tw@3x.png")}
						alt=""
					/>
				</a>
				<a
					href={this.props.instagramLink}
					target="_blank"
					rel="noopener noreferrer">
					<img
						className="igSocialMediaImg"
						src={require("../../resources/images/appAssets/ig@3x.png")}
						alt=""
					/>
				</a>
				<a
					href={this.props.facebookLink}
					target="_blank"
					rel="noopener noreferrer">
					<img
						className="fbSocialMediaImg"
						src={require("../../resources/images/appAssets/fb@3x.png")}
						alt=""
					/>
				</a>
			</div>
		);
	}
}
export default ModamilyAppSocialMediaBox;
