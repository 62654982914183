import React from "react";
import Button from "../common/ModamilyAppButton";

import ModamilyAppRoundImage from "../common/ModamilyAppRoundImage";

import { Link } from "react-router-dom";

/**
 * @property {Boolean} active - determines whether the modal is hidden or active
 * @property {Function} closeModal - a function to close the modal
 * @property {Image} userMatchedWithImage - user image object
 * @property {Image} currentUserImage - user image object
 */
export default class ModamilyAppMatchModal extends React.Component {
	render = () => {
		let { active, currentUserImage, userMatchedWithImage } = this.props;
		return ( 
			<div className={"modalCurtain" + (active ? " curtainDrag" : " modalHidden")} style={{ top: active ?  Math.ceil(document.documentElement.scrollTop) + 'px' : '-200vh'}}>
				<div className="matchModalCard">
					<div className="matchModalContentContainer">
						<div className="messageChatHeaderTextContainer">
							<p className="whiteText regularWeight">You two Matched!</p>
						</div>
						<div className="messageChatHeaderImageContainer">
							<div className="overlayImageContainer">
								<ModamilyAppRoundImage
									size="Regular"
									image={currentUserImage ? currentUserImage.path : null}
									isUrl={true}
								/>
							</div>
							<ModamilyAppRoundImage
								size="Regular"
								image={userMatchedWithImage ? userMatchedWithImage.path : null}
								isUrl={true}
							/>
						</div>
						<div className="matchModalText">
							<p className="whiteText regularWeight">
								You guys seem like you're interested in learning more. Send a
								message and say hi!
							</p>
						</div>
						<div className="matchModalActions">
							<div className="sendMessageButtonContainer">
								<Link to="/messages">
									<Button style="clear" text="Send a Message" />
								</Link>
							</div>
							<div className="backButtonContainer">
								<Button
									style="white"
									text="Back to Browsing"
									onClick={() => {
										window.location.reload();
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
}
