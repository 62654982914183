import { Types } from '../actions/browse';

const INITIAL_STATE = {
  loading: false,
  error: false,
  items: []
}

export default function browse(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_BROWSE_SUCCESS:
      let { browseData } = action.payload;
      browseData = JSON.parse(JSON.stringify(browseData));
      return {
        ...state,
        items: [
          ...browseData
        ]
      }
    case Types.SET_LIKE_SUCCESS:
    case Types.SET_DISLIKE_SUCCESS:
      let { userid } = action.payload;
      const data = state.items.filter(u => u.id !== userid);
      return {
        ...state,
        items: [
          ...data
        ]
      }
    case Types.CLEAR_BROWSE_RESULT:
      return {
        ...state,
        items: []
      }
    default:
      return state;
  }
}