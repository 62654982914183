import React from "react";
import downArrow from "../../resources/images/appAssets/dropdownArrow.png";

/**
 * @property {Array(String)} data - data for selection
 * @property {String} title- Title for default display default is "Select Item"
 * @property {String} type- display text for when an item is selected EX: "{type} : {selectedItem}" default is "Data"
 * @property {Integer} selected- selected values index from the array
 * @property {Function(String)} onItemClick - function fired when an item is clicked
 */
export default class ModamilyAppDropDownMenu extends React.Component {
  state = {
    data: this.props.data || [],
    selected: this.props.selected > -1 ? this.props.selected : -1,
    displayMenu: false,
    title: this.props.title || "Select Item",
    type: this.props.type || "Data",
    isTall: this.props.isTall,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selected !== this.props.selected) {
      this.setState({ selected: this.props.selected });
    }
  }

  onItemClick = (data, index) => {
    this.setState({ selected: index, displayMenu: false }, () => {
      if (this.props.onItemClick) {
        this.props.onItemClick(data);
      }
    });
  };

  showDropdownMenu = (evt) => {
    evt.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  };

  hideDropdownMenu = () => {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  };

  render() {
    let { title, data, selected, type, isTall } = this.state;

    let menuItems = [];

    for (let index = 0; index < data.length; index++) {
      const dataPoint = data[index];

      menuItems.push(
        <div
          key={index}
          onClick={() => {
            this.onItemClick(dataPoint, index);
          }}
          className={
            "dropdownMenuItem" + (index === selected ? " selectedMenuItem" : "")
          }
        >
          <div className="dropdownMenuItemTextContainer">
            <p className="dropdownMenuItemText">{dataPoint}</p>
          </div>
        </div>
      );
    }

    if (selected > -1) {
      if (this.props.hideType) {
        title = data[selected];
      } else {
        title = type + " : " + data[selected];
      }
    }

    return (
      <>
        {isTall ? (
          <div className="heightContainer">
            <div className="heightContainerMenu">{menuItems}</div>
          </div>
        ) : (
          <div className="dropdownMenu">
            <div className="dropdownMenuButton" onClick={this.showDropdownMenu}>
              <p>{title}</p>{" "}
              <img className="dropdownArrow" alt="down-arrow" src={downArrow} />
            </div>
            {this.state.displayMenu ? (
              <div className="dropdownMenuContainer">{menuItems}</div>
            ) : null}
          </div>
        )}
      </>
    );
  }
}
