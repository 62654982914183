import React from "react";

import statusBar from "../resources/images/appAssets/statusBar.png";
import shieldCheck from "../resources/images/appAssets/WellDoneIcon.png";

const ModamilyAppStatusBar = () => {
  return (
    <div className="ModamilyAppStatusBar">
      <img src={statusBar} alt="status bar" className="statusBar" />

      <img src={shieldCheck} alt="shield check" className="shieldCheck" />
    </div>
  );
};

export default ModamilyAppStatusBar;
