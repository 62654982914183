import React from "react";

import ModamilyAppNavBar from "../components/common/ModamilyAppNavBar";
import ModamiyAppPageContainer from "../components/common/ModamilyPageContainer";

import AdminHome from "../components/admin/adminHome";
import AdminEditUser from "../components/admin/adminEditUser";

export default class AdminControl extends React.Component {
	state = { view: 0, selectedUser: -1 };

	selectUser = user => {
		this.setState({ selectedUser: user, view: 1 });
	};

	exitEditUser = () => {
		this.setState({ selectedUser: -1, view: 0 });
	};

	render = () => {
		let component = null;

		switch (this.state.view) {
			case 0:
				component = <AdminHome selectUser={this.selectUser} />;
				break;
			case 1:
				component = (
					<AdminEditUser
						user={this.state.selectedUser}
						exitEditUser={this.exitEditUser}
					/>
				);
				break;
		}

		return (
			<div className="ModamilyAppAdminControl">
				<ModamilyAppNavBar
					currentLink={"control"}
					hasLinks={false}
					type={"adminInApp"}
				/>
				<ModamiyAppPageContainer>{component}</ModamiyAppPageContainer>
			</div>
		);
	};
}
