import React from "react";
import ButtonGroup from "../form/ModamilyAppButtonGroup";
import ModamilyAppScrollBarOptions from "../common/ModamilyAppScrollBarOptions";
import ModamilyOnboardingFooterText from "../common/ModamilyOnboardingFooterText";
import closePurpleImg from "../../resources/images/appAssets/closePurple.png";
import { capitalize } from "lodash";
import { religion, activityOptions } from "../../constants/filterOptions";

/**
 * @property {Object} data  - { religion: null, religionActivity: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppReligion extends React.Component {
  state = {
    data: this.props.data || { religion: "", religionActivity: "" },
  };

  beliefOptions = religion.slice(0, religion.length - 1);

  componentWillMount = () => {
    this.validate();
  };

  validate = () => {
    let data = this.state.data;
    if (!data.religion) {
      this.props.invalidate();
    } else if (data.religion) {
      console.log(data);
      this.props.onValidChange(data);
    }
  };

  handleBeliefSelection = (value, index, isActive) => {
    let data = this.state.data;
    data.religion = isActive ? value : null;
    this.props.scrollToBottom && this.props.scrollToBottom();
    this.validate();
  };

  handleActivitySelection = (value, index, isActive) => {
    let data = this.state.data;
    data.religionActivity = isActive ? value : null;
    this.validate();
  };

  validateReligionOption = (option) => {
    const data = this.state.data;
    const religionIndex = this.beliefOptions.indexOf(data.religion);
    if (religionIndex > -1) {
      this.props.handleHasReligionOption(option);
    } else {
      this.props.nextStep();
    }
  };

  render = () => {
    let data = this.state.data;

    const activityIndex = activityOptions.indexOf(data.religionActivity);
    const religionIndex = this.beliefOptions.indexOf(data.religion);
    const disabledNext = data.religion?.length === 0;

    return (
      <div className="hairColorForm">
        {/* <FormHeader text="Religion" /> */}
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title--religion">
            {!this.props.hasReligionOption
              ? `What’s your current religion, if any?`
              : `How serious are you about it?`}
          </h2>
        </div>
        {!this.props.hasReligionOption && (
          <>
            <ModamilyAppScrollBarOptions>
              <ButtonGroup
                selected={religionIndex}
                type="radio"
                data={this.beliefOptions}
                onButtonClick={this.handleBeliefSelection}
              />
            </ModamilyAppScrollBarOptions>
            <div className="row mt-48 align-center OnboardingButtonContainer">
              <ModamilyOnboardingFooterText text="We will use this to find the perfect match for you. You can change it later." />

              <div className="lookingForButton">
                <button
                  disabled={disabledNext}
                  className={`gradientOrangeButton whiteText button ${
                    disabledNext ? "disabledButton" : ""
                  }`}
                  onClick={() => this.validateReligionOption(true)}
                >
                  Next
                </button>
              </div>
            </div>
          </>
        )}
        {this.props.hasReligionOption && (
          <>
            <div
              className="row align-center px-20 border-selected"
              style={{ padding: "1rem" }}
            >
              <div>
                <p>{capitalize(data.religion)}</p>
              </div>
              <div
                onClick={() => this.validateReligionOption(false)}
                className="closeButtonImg"
              >
                <img
                  className="closeImg"
                  src={closePurpleImg}
                  alt="Close button"
                />
              </div>
            </div>
            <div className="hairColorForm">
              <ModamilyAppScrollBarOptions>
                <ButtonGroup
                  selected={activityIndex}
                  type="radio"
                  data={activityOptions}
                  onButtonClick={this.handleActivitySelection}
                />
              </ModamilyAppScrollBarOptions>
            </div>
          </>
        )}
      </div>
    );
  };
}
