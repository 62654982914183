import React from 'react';

import DotMenu from '../common/ModamilyAppDotMenu';
import { AWS_URL } from '../../config/config';
import dislikeButton from '../../resources/images/appAssets/dislikeColor.png';
import likeButton from '../../resources/images/appAssets/likeColor.png';

import disliked from '../../resources/images/appAssets/dislikeLarge.png';
import liked from '../../resources/images/appAssets/likeLarge.png';
import { withApollo } from 'react-apollo';
import Mutations from '../../apollo/mutations/mutations';
import decode from 'jwt-decode';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import styles from './modamilyAppBrowseCard.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles)


/**
 * @property {Object} user - User to be displayed on card
 * @property {Function (Boolean)}  handleCardAction(liked) - a function ran when a user clicks on one of the two buttons, the parameter is true if its liked and false if disliked
 * @property {Function(Object)} viewFullProfile(user) - Function called to display the full profile component of a user
 */
class ModamilyAppBrowseCard extends React.Component {
  state = {
    slideIndex: 0
  };

  componentDidMount = () => {
    // this.playAnimation(true);
  };

  nextImage = () => {
    let index = this.state.slideIndex;
    index++;
    if (index > this.props.user.images.length) {
      index = 0;
    }
    this.setState({ slideIndex: index });
  };

  pickImage = index => {
    this.setState({ slideIndex: index });
  };

  prevImage = () => {
    let index = this.state.slideIndex;
    index--;
    if (index < 0) {
      index = this.props.user.images.length - 1;
    }
    this.setState({ slideIndex: index });
  };

  playAnimation = liked => {
    let el = liked ? this.refs.likeAnimation : this.refs.dislikedAnimation;

    // console.log("playing animation", liked);

    if (el) {
      let name = el.className;
      el.className = name.replace(' inactiveAnimation', ' activeAnimation');
      setTimeout(() => {
        let name = el.className;
        el.className = name.replace(' activeAnimation', ' inactiveAnimation');
      }, 350);
    }
  };

  handleBlockUser = async (userId) => {
    try {
      const { client } = this.props;
  
      const response = await client.mutate({ mutation: Mutations.blockUser,variables: { userId }})
      let result = decode(response.blockUser);
      if (result.action === 'success') {
      }
    } catch (err) { 
      console.log('error blocking user', err);
    }
  }

  render() {
    const { slideIndex } = this.state;
    let { user } = this.props;

    const classes = {
      photoImage: id =>
      cx('profileSlideshowImage', id === slideIndex && 'slideActive')
    };
    if(user && user.images) {
      return (
        <div className="browseCarouselCard">
          <div onClick={() => { user && this.props.viewFullProfile(user); }} className="profileImageSlideshow">
            {
              user.images.map((image, id) => (
              <LazyLoadImage
                key={id}
                className={classes.photoImage(id)}
                alt={'photo'}
                src={AWS_URL + image.path}
                loading="lazy"
              />
            )) 
            }
            <div className="slideDots">
              {user.images.length > 1
                ? user.images.slice(0, 6).map((e, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          'slideDot' +
                          (index === this.state.slideIndex
                            ? ' activeDot'
                            : '')
                        }
                        onClick={e => {
                          this.pickImage(index);
                          e.stopPropagation();
                        }}
                      />
                    );
                  })
                : null}
            </div>
          </div>
          <div className="profileInfoCard">
            <div className="browseCardPopUpMenuContainer">
              <DotMenu
                options={['Block user', 'View Full Profile']}
                functions={[
                  () => this.handleBlockUser(user.id),
                  () => {
                    this.props.viewFullProfile(user);
                  }
                ]}
              />
            </div>
            <div className="profileInfoContainer">
              <div className="profileInfoNameContainer">
                <p className="purpleText regularWeight profileInfoName">
                  {user.firstName}
                </p>
              </div>
              <div className="lookingForContainer">
                <div className="lookingForTitleContainer">
                  <p className="greyText lookingForTitle">LOOKING FOR</p>
                </div>
                <div className="lookingForItemContainer">
                  <p className="purpleText regularWeight lookingForItems">
                    {user.lookingFor ? user.lookingFor.join(', ') : ''}
                  </p>
                </div>
              </div>
              <div className="ageContainer">
                <div className="ageTitleContainer">
                  <p className="greyText ageTitle">AGE</p>
                </div>
                <div className="ageNumberContainer">
                  <p className="purpleText regularWeight ageNumber">
                    {user.age}
                  </p>
                </div>
              </div>
              <div className="ageContainer">
                <div className="ageTitleContainer">
                  <p className="greyText ageTitle">COUNTRY</p>
                </div>
                <div className="ageNumberContainer">
                  <p className="purpleText regularWeight ageNumber">
                    {user.country}
                  </p>
                </div>
              </div>
              {user ? (
                <div className="lookingForContainer">
                  <div className="lookingForTitleContainer">
                    <p className="greyText lookingForTitle">CITY</p>
                  </div>
                  <div className="lookingForItemContainer">
                    <p className="purpleText regularWeight lookingForItems">
                      {user.city}
                    </p>
                  </div>
                </div>
              ) : null}
              <div className="cardActions">
                <div
                  className="dislikeButtonContainer"
                  onClick={() => {
                    this.props.handleCardAction(false);
                    // this.playAnimation(false);
                  }}
                >
                  <img
                    alt={''}
                    className="dislikeButtonImage"
                    src={dislikeButton}
                  />
                </div>
                <div
                  className="likeButtonContainer"
                  onClick={() => {
                    this.props.handleCardAction(true);
                    // this.playAnimation(true);
                  }}
                >
                  <img
                    alt={''}
                    className="likeButtonImage"
                    src={likeButton}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            ref="likeAnimation"
            className="profileAnimationLiked inactiveAnimation"
          >
            <img
              alt={''}
              className="profileAnimationLikedImage"
              src={liked}
            />
          </div>
          <div
            ref="dislikedAnimation"
            className="profileAnimationDisliked inactiveAnimation"
          >
            <img
              alt={''}
              className="profileAnimationDislikedImage"
              src={disliked}
            />
          </div>
        </div>
      )
    } else return null
  }
}

export default withApollo(ModamilyAppBrowseCard);
