export const Types = {
  UPDATE_SEARCH_PREFERENCES_REQUEST: 'search_preferences/update_search_preferences_request',
  UPDATE_SEARCH_PREFERENCES_SUCCESS: 'search_preferences/update_search_preferences_success',
  UPDATE_FETCH_PROFILES_REQUEST: 'search_preferences/update_fetch_profiles_request',
  UPDATE_FETCH_PROFILES_SUCCESS: 'search_preferences/update_fetch_profiles_success',
}


export const updateSearchPreferencesRequest = (searchPreferences) => ({
  type: Types.UPDATE_SEARCH_PREFERENCES_REQUEST,
  payload: {
    searchPreferences
  }
});

export const updateSearchPreferencesSuccess = (searchPreferences) => ({
  type: Types.UPDATE_SEARCH_PREFERENCES_SUCCESS,
  payload: {
    searchPreferences
  }
});

export const updateFetchProfilesRequest = (shouldFetchProfiles) => ({
  type: Types.UPDATE_FETCH_PROFILES_REQUEST,
  payload: {
    shouldFetchProfiles
  }
});

export const updateFetchProfilesSuccess = (shouldFetchProfiles) => ({
  type: Types.UPDATE_FETCH_PROFILES_SUCCESS,
  payload: {
    shouldFetchProfiles
  }
});
