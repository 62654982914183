import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/common/ModamilyAppNavBar";
import FormHeader from "../components/form/ModamilyAppFormHeader";
import PageContainer from "../components/common/ModamilyPageContainer";

export default class ModamilyAppTermsOfService extends React.Component {
	render = () => {
		return (
			<div className="termsOfServicePage">
				<NavBar type="onboarding" />
				<PageContainer>
					<FormHeader text="Terms of Use" />
					<br />
					<p>Updated: Sept. 2019</p>
					<br />
					<p>
						Welcome to Modamily Inc.’s websites hosted at modamily.com and
						modamilyconcierge.com (together, collectively the “Websites”)
						and mobile phone applications (the “App”) (together, collectively,
						the ”Service”). The modamily.com website is a social networking
						service that connects individuals who are looking for a romantic
						relationship, platonic co-parenting partnership, or a known donor
						arrangement. The modamilyconcierge.com website is a personal
						consulting service to help individuals find co-parents, sperm/egg
						donors, and surrogates. The Service is NOT a fertility, egg donor or
						sperm donor service and it does NOT offer legal advice relating to
						co-parenting or custody arrangements and related rights and claims.
					</p>
					<br></br>
					<p>
						Please review the following terms and conditions concerning your use
						of and access to the Service. By accessing, using, and/or
						downloading any materials or contents from the Service, you agree to
						follow and be bound by the following terms and conditions (the
						“Terms of Use”) and the Privacy Policy. If you do not agree with the
						Terms of Use and the <accent className="redText">
							<Link to="/privacy">Privacy policy</Link>
						</accent>{" "}, you
						may not use the Service. The Service is provided by Modamily, Inc.,
						whose principal office is located at 750 N. San Vicente Blvd., 8th
						Flr., West Hollywood, CA 90069 (“MODAMILY”).
					</p>
					<br></br>
					<p>
						NOTICE OF ARBITRATION AGREEMENT AND CLASS ACTION WAIVER: THIS
						AGREEMENT INCLUDES A BINDING ARBITRATION CLAUSE AND A CLASS ACTION
						WAIVER, SET FORTH BELOW, WHICH AFFECT YOUR RIGHTS ABOUT RESOLVING
						ANY DISPUTE WITH THE COMPANY. PLEASE READ IT CAREFULLY.
					</p>
					<br></br>
					<FormHeader text="Access and Compliance" />
					<br></br>
					<p>
						By accessing the Service, you agree to be bound by the Terms of Use,
						all applicable laws and regulations and that you warrant and
						represent that you are legally entitled to do so and are abiding by
						all applicable laws and regulations in the jurisdiction in which you
						reside. You agree that MODAMILY may modify this agreement and such
						modifications shall be effective immediately upon posting. You agree
						to review these Terms of Use periodically to be aware of
						modifications. Continued access or use of the site shall be deemed
						conclusive evidence of your acceptance of the modified agreement.
					</p>
					<br></br>
					<FormHeader text="Disclaimer"></FormHeader>
					<br></br>
					<p>
						MODAMILY DOES NOT CONDUCT BACKGROUND CHECKS OR OTHERWISE SCREEN
						USERS OF THE SERVICE IN ANY WAY. The services offered by MODAMILY
						through the Service are limited to facilitating introductions
						between individuals who have represented that they are looking for a
						romantic relationship, a platonic co-parenting partnership, or a
						known donor arrangement. MODAMILY strongly recommend that, if any
						user finds or is introduced to an individual who may be a suitable
						partner, parent, co-parent, or donor, the user should take all
						reasonable and sensible precautions and steps to assess such
						individuals. Any information gained from questionnaires, tests, or
						communications through the Service are based solely upon information
						submitted by other users of the Service and MODAMILY make no
						guarantees as to its correctness, completeness, or accuracy of such
						user-submitted information and cannot verify or otherwise ascertain
						the accuracy of such user-submitted information. Users are solely
						responsible for undertaking steps to check or otherwise ascertain
						the accuracy of any user-submitted information. Each user is solely
						responsible for the accuracy, legality, currency and compliance of
						its own Material and will be solely liable for false, misleading,
						inaccurate, infringing or other actionable material contained or
						referred to therein.
					</p>
					<br></br>
					<p>
						Although each member/subscriber/user must agree to these Terms of
						Use, MODAMILY cannot guarantee that each member is at least the
						required minimum age, nor does MODAMILY accept responsibility or
						liability for any content, communication or other use or access of
						the Service by persons under the age of 18 in violation of the Terms
						of Use. It is also possible that other
						members/subscribers/registrants or users (including unauthorized
						users, or "hackers") may post or transmit offensive or obscene
						materials through the Service and that you may be involuntarily
						exposed to such offensive or obscene materials. It is also possible
						for others to obtain personal information about you via your use of
						the Service. These individuals may use your information for purposes
						other than what you intended. MODAMILY are not responsible for the
						use of any personal information that you disclose on the Website.
						Please carefully select the type of information that you post on the
						Service or release to others. MODAMILY DISCLAIMS ALL LIABILITY,
						REGARDLESS OF THE FORM OF ACTION, FOR THE ACTS OR OMISSIONS OF OTHER
						MEMBERS/SUBSCRIBERS/REGISTRANTS OR USERS (INCLUDING UNAUTHORIZED
						USERS), WHETHER SUCH ACTS OR OMISSIONS OCCUR DURING THE USE OF THE
						SERVICE OR OTHERWISE
					</p>
					<br></br>
					<p>
						Although MODAMILY attempts to maintain the integrity and accuracy of
						the information on the Service, MODAMILY provides any services on an
						“as is” basis and make no guarantees as to its correctness,
						completeness, or accuracy. The Service may contain typographical
						errors, inaccuracies, or other errors or omissions. Also,
						unauthorized additions, deletions, or alterations could be made to
						the Service by third parties without our knowledge. If you believe
						that information found on the Service is inaccurate or unauthorized,
						please inform MODAMILY by contacting us via information provided at
						the “Contact Us” link. MODAMILY specifically disclaims any implied
						warranties of merchantability or fitness for a particular purpose.
						Further, MODAMILY does not warrant that your use of the service will
						be secure, uninterrupted, always available or error-free, or that
						the service will meet your requirements or that any defects in the
						service will be corrected. MODAMILY disclaims liability for, and no
						warranty is made with respect to, connectivity and availability.
					</p>
					<br></br>
					<FormHeader text="Use of the Service"></FormHeader>
					<br></br>
					<p>
						<i>
							By using or accessing the Website or App, you agree to the
							following:
						</i>
					</p>
					<br></br>
					<p>
						(1) The information posted in any profile you create, including your
						photograph, is posted by you and that you are the exclusive author
						of your profile and the exclusive owner of your photographs. You
						assign to MODAMILY, with full title guarantee, all copyright in your
						profile, any photographs posted by you, and any additional
						information sent to MODAMILY at any time in connection with your use
						of the Service. You waive absolutely any and all moral rights to be
						identified as author of your profile and owner of your photograph
						and any similar rights in any jurisdiction in the world. By posting
						information, photographs or content on any part of the Service, you
						automatically grant, and you represent and warrant that you have the
						right to grant, to MODAMILY, free of charge, an irrevocable,
						perpetual, non-exclusive, royalty-free, fully-paid, worldwide
						license to use, copy, perform, display, promote, publish and
						distribute such information, content and photographs and to prepare
						derivative works of, or incorporate into other works, such
						information and content, and to grant and authorize sub-licenses of
						the foregoing.
					</p>
					<br></br>
					<p>
						(2) You will use the Service in a manner consistent with any and all
						applicable laws and regulations. You will not engage in advertising
						to, or solicitation of, other members to buy or sell any products or
						services through the Service. You will not transmit any chain
						letters or junk email to other members. To protect our users against
						such conduct, MODAMILY may limit or otherwise control the number of
						messages that any user may send in a day. You are solely responsible
						for your interactions with other users of the Service. MODAMILY
						reserves the right, but has no obligation, to monitor and/or mediate
						disputes between you and other users of the Service.
					</p>
					<br></br>
					<p>
						(3) You are solely responsible for the content or information you
						post, publish or display on the Service, or transmit to other users.
						You shall not post on the Service, or transmit to other users or to
						MODAMILY or its employees, agents or representatives, any
						defamatory, inaccurate, abusive, obscene, profane, offensive,
						sexually oriented, threatening, harassing, offensive, or illegal
						material, or any material that infringes or violates another party's
						rights (including, but not limited to, intellectual property rights,
						and rights of privacy and publicity). You will not include in your
						profile any offensive language, including but not limited to
						offensive anatomical or sexual references, or offensive sexually
						suggestive or connotative language, and you will not post any photos
						containing nudity or personal information. MODAMILY reserve the
						right, but has no obligation, to reject any profile or photo that
						does not comply with the prohibitions set forth in this section.
						MODAMILY reserves the right to refuse service to anyone, at our sole
						discretion.
					</p>
					<br></br>
					<p>
						(4) By becoming a Member/Subscriber/Registrant, you agree to accept
						and consent to receiving email or other communications initiated
						from MODAMILY or through MODAMILY including, without limitation:
						push notifications, alerts, message notification emails and emails
						informing you about events, emails informing you of changes to the
						Service and emails informing you of promotions that either MODAMILY
						provide or that are being provided by third parties. If you choose
						to respond to promotions that are provided by third parties and in
						the course of doing so, disclose information to any external service
						providers, and/or grant them permission to collect information about
						you, then their use of your information is governed by their private
						policies. Should you not wish to receive any of MODAMILY’s email
						communications, please do not register/subscribe to the Service.
						However, you may opt-out of receiving email communications sent from
						MODAMILY or through MODAMILY. For push notifications and/or alerts,
						you will be asked to accept or refuse such notifications after
						downloading the App. If you deny, you will not receive any push
						notifications and/or alerts. If you accept, these notifications
						and/or alerts will be automatically sent to you. If you no longer
						wish to receive such notifications and/or alerts, you may change
						your notification settings on your mobile device.
					</p>
					<br></br>
					<p>
						(5) The App utilizes GPS, Bluetooth, or other location-based
						software on your mobile device to provide you with location-based
						content such as notifying you if a user is within your general
						location or has been at or near your current location. If you do not
						wish to utilize this feature, you should set your device to disable
						the App from accessing your location data.
					</p>
					<br></br>
					<p>
						(6) You agree that MODAMILY has no responsibility or liability for
						the deletion, corruption or failure to store any messages or other
						content maintained or transmitted by the Service. You acknowledge
						that features, parameters or other services MODAMILY provides may
						change at any time. You acknowledge that MODAMILY reserve the right
						to sign out, terminate, delete or purge your account from the
						Service if it is inactive. “Inactive” indicates you have not signed
						in to the Service for a particular period of time, as determined by
						MODAMILY in its sole discretion. MODAMILY is entitled to investigate
						and terminate your membership/subscription/registration if you have
						misused the Service, or behaved in a way which could be regarded as
						inappropriate, unlawful or illegal. The following is a partial, but
						not exhaustive, list of the types of actions that are illegal or
						prohibited under the Terms of Use:
						<div className="termsList">
							<ul>
								<li>
									Harassing, abusing or otherwise inappropriately contacting any
									other user of the Service;
								</li>
								<li>
									Collecting information about the Service or any users of the
									Service without our written consent;
								</li>
								<li>
									Modifying, framing, rendering (or re-rendering), mirroring,
									truncating, injecting, filtering or changing any content or
									information contained in the Service, without our written
									consent;
								</li>
								<li>
									Using any deep-link, page-scrape, robot, crawl, index, spider,
									click spam, macro programs, Internet agent, or other automatic
									device, program, algorithm or methodology which does the same
									things, to use, access, copy, acquire information, generate
									impressions or clicks, input information, store information,
									search, generate searches, or monitor the Service or any
									portion thereof;
								</li>
								<li>
									Accessing or using the site for commercial or competitive
									purposes;
								</li>
								<li>
									Disguising the origin of information transmitted to, from, or
									through the Service.
								</li>
								<li>Impersonating another person;</li>
								<li>Distributing viruses or other harmful computer code;</li>
								<li>
									Allowing any other person or entity to impersonate you to
									access or use the Service;
								</li>
								<li>
									Using the Service for any purpose in violation of local,
									state, national, international laws
								</li>
								<li>
									Using the Service in a way that is intended to harm, or a
									reasonable person would understand would likely result in
									harm, to the user or others;
								</li>
								<li>
									Posting, distributing or reproducing in any way, any
									copyrighted material, trademarks, or other proprietary
									information without obtaining the prior written consent of the
									owner of such proprietary rights;
								</li>
								<li>
									Removing any copyright, trademark or other proprietary rights
									notices contained in the Service or forging headers or
									otherwise manipulating identifiers in order to disguise the
									origin of any information transmitted through the Service;
								</li>
								<li>
									Circumventing any measures implemented by MODAMILY aimed at
									preventing violations of the Terms of Use.
								</li>
							</ul>
						</div>
						<br />
						MODAMILY expressly reserves the right, in its sole discretion, to
						terminate a user’s access to any interactive services and/or to any
						or all other areas of the Website due to any act that would
						constitute a violation of these Terms of Use.
					</p>
					<br></br>
					<p>
						(7) Any personal information you submit to the Service is governed
						by the Privacy Policy. This Privacy Policy encompasses any and all
						information you may provide to the site either for purposes of
						submitting an order or to receive further information, updates and
						promotions about or related to the Service.
					</p>
					<br></br>
					<p>
						(8) You are ineligible to use this Service if you are under the age
						of 18 or you are not able to form legally binding contracts, or if
						your membership/subscription/registration has been suspended by
						MODAMILY. MODAMILY does not accept any users who are under the age
						of 18. By becoming a Member/Subscriber/Registered User of the
						Service, you represent and warrant that you are at least 18 years
						old. By using the Service, You represent and warrant that you have
						the right, authority and capacity to use the Service and to abide by
						the Terms of Use. Your membership/subscription/registration with the
						Service is for your sole, personal use. You may not authorize others
						to use your membership/subscription/registration and you may not
						assign or otherwise transfer your account to any other person or
						entity.
					</p>
					<br></br>
					<p>
						(9) Via its “User-Submitted Validation” function, the Service allows
						users to voluntarily submit personal identification records and
						other personal documentation for purposes of declaring or otherwise
						assuring other users of his or her commitment or sincerity to the
						desired introduction or match. The submission of personal
						documentation and any reliance upon such is completely at the user’s
						option and discretion. As set forth in greater detail in the
						Disclaimer and Limitation of Liability sections of the Terms of Use,
						MODAMILY and the Service make no guarantees as to correctness,
						completeness, or accuracy of any user-submitted materials or
						information and cannot verify or otherwise ascertain the accuracy of
						such user-submitted materials or information.
					</p>
					<br></br>
					<p>
						<strong>
							Special Terms of Use for Purchases and Subscriptions:
						</strong>
					</p>
					<br></br>
					<p>
						All purchases of services from the Service are subject to the
						following terms and conditions:<br></br> The current subscriber and
						renewal rates offered by the Service are available on the
						subscription page of modamily.com and modamilyconcierge.com.
						Current subscribers can view their subscription plan including any
						applicable renewal rate and period on the Account Information page.
					</p>
					<div className="termsList">
						<ol>
							<li>All purchases are final.</li>
							<li>
								No refund will be given for unused portions of your subscription
								period.
							</li>
							<li>
								No refund will be given if your subscription privileges are
								terminated due to a violation of the Terms of Use. You
								acknowledge and agree that harassing, hostile or offensive
								behavior (including the use of foul language) in communications
								to other subscribers/members will constitute sufficient grounds
								for immediate termination of subscription privileges.
							</li>
							<li>
								Subscription privileges are non-refundable in the event that you
								elect to suspend or cancel your membership.
							</li>
							<li>
								While the Service will ensure that your electronic communication
								shall be transmitted to the appropriate recipient’s in-box or
								message will be transmitted to another user on the MODAMILY
								system, the Service cannot guarantee that you will receive a
								response from the recipient and makes not promises,
								representations and warranties of any kind in that regard
							</li>
							<li>AUTOMATIC SUBSCRIPTION RENEWALS:</li>
							<div className="termsSublist">
								<ol>
									<li>
										In making purchases of subscriptions from the Service, you
										acknowledge, agree and authorize MODAMILY to renew your
										subscription, automatically, at the end of each subscription
										interval (e.g. one month, two months or three months), at
										the guaranteed renewal rate applicable to the purchase
										options chosen by you.
									</li>
									<li>
										Such automatic renewal and related charges shall be applied
										at the end of the applicable subscription interval and for
										the same interval initially selected by you. For example, if
										you purchased a three month subscription to the Service, at
										the end of that initial three month interval, your
										subscription will automatically renew for another three
										month interval. If you wish to modify the subscription
										interval, you simply need to log into the website as a
										subscriber/member and click on the CONTACT US tab in the
										menu and email
										<accent className="redText">
											{" "}
											<a
												target="_blank"
												href="mailto:support@modamily.com?subject=Billing Question.">
												support@modamily.com
											</a>
										</accent>{" "}
										with the subject heading “Modify Subscription Interval” or
										"Billing Question."
									</li>
									<li>
										Such automatic renewals shall continue periodically based
										upon the interval you selected unless and until you formally
										submit instructions to MODAMILY to terminate the automatic
										subscription renewals. In order to terminate the automatic
										subscription renewal, you simply need to log into the
										website as a subscriber/member and clicking on the CONTACT
										US tab in the menu and email
										<accent className="redText">
											{" "}
											<a
												target="_blank"
												href="mailto:support@modamily.com?subject=Billing Question.">
												support@modamily.com
											</a>
										</accent>{" "}
										and selecting the option for “Cancel Subscription" or
										"Billing Question."
									</li>
								</ol>
							</div>
						</ol>
					</div>
					<br></br>
					<FormHeader text="Copyrights and Trademarks" />
					<br></br>
					<p>
						The contents of the Website are protected by international copyright
						and trademark laws. The owner of the copyrights and trademarks,
						names, logos and service marks are MODAMILY, its affiliates or other
						third party licensors. Except as stated herein, none of the contents
						of the Service may be modified, copied, printed, reproduced,
						distributed, republished, performed, downloaded, displayed, posted,
						transmitted and/or otherwise used in any form or by any means,
						including but not limited to electronic, mechanical, photocopying,
						recording, or other means, without the prior express written
						permission of MODAMILY.
					</p>
					<br></br>
					<p>
						<strong>DMCA Statement</strong>
					</p>
					<br></br>
					<p>
						Owners of copyrights or their agents that believe any content on
						this Service infringes upon said copyrights will submit a notice
						pursuant to the Digital Millennium Copyright Act ("DMCA") to
						MODAMILY immediately upon discovery. Any notices given pursuant to
						the DMCA shall be given to the designated agent of the Service at{" "}
					</p>
					<p className="redText">contact@modamily.com</p>
					<p>or via registered, United States mail sent return receipt to:</p>
					<br></br>
					<p>Modamily</p>
					<br></br>
					<p>Attn: DMCA Compliance Agent</p>
					<br></br>
					<p>750 N. San Vicente Blvd., 8th Flr.</p>
					<br></br>
					<p>West Hollywood, CA 90069</p>
					<br></br>
					<FormHeader text="Limitation of Liability"></FormHeader>
					<p>
						<u>
							{" "}
							MODAMILY DOES NOT CONDUCT BACKGROUND CHECKS OR OTHERWISE SCREEN
							USERS OF THE SERVICE IN ANY WAY.{" "}
						</u>
						IN NO EVENT WILL MODAMILY BE LIABLE TO YOU OR TO ANY OTHER PERSON
						FOR ANY INCIDENTAL, CONSEQUENTIAL, OR INDIRECT DAMAGES (INCLUDING,
						BUT NOT LIMITED TO, DAMAGES FOR LOSS OF DATA, LOSS OF PROGRAMS, COST
						OF PROCUREMENT OF SUBSTITUTE SERVICES OR SERVICE INTERRUPTIONS)
						ARISING OUT OF THE USE OF OR INABILITY TO USE THE SERVICE, EVEN IF
						MODAMILY OR OUR AGENTS OR REPRESENTATIVES KNOW OR HAVE BEEN ADVISED
						OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
						CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
						WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
						TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO MODAMILY FOR
						USE OF THE SERVICE DURING THE TERM OF YOUR MEMBERSHIP/SUBSCIPTION.
						MODAMILY WILL NOT BE LIABLE FOR ANY DAMAGES, DIRECT, INDIRECT,
						INCIDENTAL AND/OR CONSEQUENTIAL, INCLUDING BY NOT LIMITED TO
						PHYSICAL DAMAGES, BODILY INJURY OR EMOTIONAL DISTRESS, ARISING OUT
						OF THE USE OF THIS SERVICE, INCLUDING, WITHOUT LIMITATION, DAMAGES
						ARISING OUT OF YOUR COMMUNICATIONS WITH AND/OR INTERACTIONS WITH ANY
						OTHER USER OF THE SERVICE, OR ANY INDIVIDUAL YOU ARE INTRODUCED TO
						VIA THE SERVICE. THE LIMITATION OF LIABILITY SET FORTH IN THIS
						SECTION SHALL NOT APPLY IN THE EVENT OF USER’S BREACH, OR RELATED TO
						ITS INDEMNITY OBLIGATIONS. THIS PARAGRAPH SHALL NOT AFFECT THE
						RIGHTS LISTED BELOW IN THE SECTION TITLED “INDEMNIFICATION”.
					</p>
					<br></br>
					<FormHeader text="Indemnification"></FormHeader>
					<br></br>
					<p>
						You agree to indemnify, defend and hold harmless MODAMILY its
						officers, directors, employees, agents, licensors, suppliers and any
						third party information providers from and against all losses,
						expenses, damages and costs, including, but not limited to all
						attorneys’ fees charged to MODAMILY, arising out of or relating to
						your use of the Service or any violation of these Terms of Use by
						you or any other person accessing the Service on your behalf,
						including, but not limited to, any allegation that any materials,
						information or content that you submit to MODAMILY infringes or
						otherwise violates the copyright, trademark, trade secret or other
						intellectual property or privacy rights of any third party. This
						indemnity shall be applicable without regard to the negligence of
						any party, including any indemnified person.
					</p>
					<br></br>
					<FormHeader text="Release"></FormHeader>
					<br></br>
					<p>
						If you have a dispute with one or more of the other users of the
						Service, you release MODAMILY (and our officers, directors, agents,
						subsidiaries, joint ventures and employees) from any claims, demands
						and damages (actual and consequential) of every kind and nature,
						known and unknown, arising out of or in any way connected with such
						dispute.
					</p>
					<br></br>
					<FormHeader text="Conflicts"></FormHeader>
					<br></br>
					<p>
						Shall a conflict or contradiction exist between these Terms and any
						others which relate specifically to a particular section of the
						Service, the specific terms relevant that section shall prevail.
					</p>
					<br></br>
					<FormHeader text="Severability"></FormHeader>
					<br></br>
					<p>
						Any provision of this agreement found to be unenforceable will not
						void nor effect other provisions of the agreement.
					</p>
					<br></br>
					<p>
						RESOLUTION OF DISPUTES – MANDATORY ARBITRATION AND CLASS ACTION
						WAIVER
					</p>
					<br></br>
					<p>
						We offer the following process to help you resolve a complaint or
						Dispute (as defined below) that you may have with MODAMILY or the
						use of this Service. Please read this section carefully. Our
						Customer Service Department, which you can reach at{" "}
						<accent className="redText">
							{" "}
							<a
								target="_blank"
								href="mailto:support@modamily.com?subject=Billing Question.">
								support@modamily.com
							</a>
						</accent>{" "}
						help you resolve most of your concerns. If our Customer Service
						Department is unable to resolve your complaint or Dispute, you agree
						to take the following steps to resolve any Dispute you may have with
						us.
					</p>
					<br></br>
					<p>Step 1. Notice of Dispute</p>
					<br></br>
					<p>
						You must first try to resolve any complaint or Dispute with us
						through our Notice of Dispute process. You begin by submitting a
						“Notice of Dispute” with any supporting documents or other
						information by U.S. Mail to:
					</p>
					<br></br>
					<p>MODAMILY, INC.</p>
					<p>Attention: Notice of Dispute </p>
					<p>750 N. San Vicente Blvd., 8th Flr.</p>
					<p>West Hollywood, CA 90069</p>
					<br></br>
					<p>
						A “Notice of Dispute” is a written form in which you provide your
						name, address, contact information, email address, the facts
						regarding your Dispute, and the relief you are requesting from us.
						Once we receive your Notice of Dispute, you and we will attempt to
						resolve any Dispute through informal negotiation within forty-five
						(45) days from the date the Notice of Dispute is received by us. If
						an agreement cannot be reached within forty-five (45) days of
						receipt of the Notice of Dispute, you or we may initiate an
						arbitration proceeding or small claims action as described below.
					</p>
					<br></br>
					<p>
						You agree that the term “Dispute” in these Terms of Use will have
						the broadest meaning possible. It applies to any dispute, action, or
						other controversy between you and us relating to the Website, the
						Services, the Terms of Use (or any breach thereof) – whether in
						contract, warranty, tort, laws or regulation. The term also applies
						to any dispute over the validity, enforceability, or scope of this
						arbitration provision.
					</p>
					<br></br>
					<p>Step 2: Arbitration</p>
					<br></br>
					<p>
						If your Dispute is not resolved to your satisfaction within 45 days
						from when we received your Notice of Dispute, you and we agree to
						arbitrate all Disputes between us. As explained below, you and we
						also may also have the right to bring an individual action before a
						Small Claims Court for certain claims. You agree that arbitration or
						a small claims action will be your sole and exclusive remedy to
						resolve any dispute with us. The Federal Arbitration Act applies to
						this Agreement.
					</p>
					<br></br>
					<p>
						By agreeing to arbitrate, you are giving up the right to litigate
						(or participate in as a party or class member) any and all Disputes
						in a court before a judge or jury (except Small Claims Court).
						Instead, a neutral arbitrator will resolve all Disputes.
					</p>
					<br></br>
					<p>
						To read more about arbitration, visit www.adr.org. The American
						Arbitration Association (“AAA”) will conduct any arbitration under
						its Consumer Arbitration Rules in effect at that time. You can
						review the Consumer Arbitration Rules{" "}
						<accent className="redText">
							<a href="https://www.adr.org/aaa/ShowProperty?nodeId=/UCM/ADRSTAGE2021425&">
								here
							</a>
						</accent>
					</p>
					<br></br>
					<p>
						You may begin an arbitration with us by completing a Demand for
						Arbitration form, available by clicking{" "}
						<accent className="redText">
							<a href="https://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015820">
								here
							</a>
						</accent>{" "}
						The completed form(s) should be returned directly to the AAA. The
						AAA will assign someone to manage your case. You will be contacted
						with instructions for continuing the arbitration process. Be sure to
						keep a copy of everything you send to the administrator of your
						arbitration or us. If, for any reason, the AAA is unavailable, the
						parties shall mutually select another arbitration forum which will
						be governed by the AAA Consumer Arbitration Rules. The AAA rules
						will determine whether the arbitration will take place through
						written submissions by you and us, by telephone, or in person.
					</p>
					<br></br>
					<p>Class Action Waiver</p>
					<br></br>
					<p>
						Any proceedings to resolve or litigate any Dispute in any forum will
						be conducted solely on an individual basis. Class arbitrations,
						class actions, private attorney general actions, consolidation of
						your Dispute with other arbitrations, or any other proceeding in
						which either party acts or proposes to act in a representative
						capacity or as a private attorney general are not permitted and are
						waived by you, and an arbitrator will have no jurisdiction to hear
						such claims. If a court or arbitrator finds that the class action
						waiver in this section is unenforceable as to all or some parts of a
						Dispute, then the class action waiver will not apply to those parts.
						Instead, those parts will be severed and proceed in a court of law,
						with the remaining parts proceeding in arbitration. If any other
						provision of this Dispute resolution section is found to be illegal
						or unenforceable, that provision will be severed with the remainder
						of this section remaining in full force and effect.
					</p>
					<br></br>
					<p>Costs</p>
					<br></br>
					<p>
						If you initiate an arbitration with the AAA, we will promptly
						reimburse you for any standard filing fee you may have been required
						to pay by the AAA, once you have notified us in writing and provided
						a copy of the arbitration demand. However, if we are the prevailing
						party in the arbitration, we will be entitled to seek and recover
						our attorneys’ fees and costs, subject to the arbitrator’s
						determination and applicable law.
					</p>
					<br></br>
					<p>Small Claims Option</p>
					<br></br>
					<p>
						You may also litigate any Dispute in Small Claims Court in Los
						Angeles County, California if the Dispute meets all requirements to
						be heard in the small claims court. You can learn more about the
						Small Claims Court in Los Angeles County by clicking
						<a target="_blank" href="https://dcba.lacounty.gov">
							here
						</a>
						However, if you initiate a Small Claims case, you are responsible
						for all your court costs.
					</p>
					<FormHeader text="Governing Law & Exclusive Venue"></FormHeader>
					<br></br>
					<p>
						Use of the Service and any claim relating to MODAMILY shall be
						governed by the laws of the State of California and litigated in the
						State of California. Any Disputes, actions, claims or causes of
						action arising out of or in connection with these Terms of Use or
						the Website shall be subject to the exclusive jurisdiction of the
						state and federal courts located in California. The choice of law of
						and exclusive venue in California apply to you regardless of where
						you are accessing the Service from even if you are accessing the
						Service from outside of the United States of America.
					</p>
					<br></br>
					<p>
						The Terms of Use (together with the Privacy Policy) represent the
						parties' entire understanding relating to the use of the Service and
						supersedes any prior or contemporaneous, conflicting or additional,
						communications. MODAMILY reserve the right to change these Terms of
						Use or policies relating to the Sevice at any time and from time to
						time, and such changes will be effective upon being posted herein.
						You should visit this page from time to time to review the then
						current Terms of Use because they are binding on you. Certain
						provisions of these Terms of Use may be superseded by expressly
						designated legal notices or terms located on particular pages of the
						Service, which may be posted from time to time. Your continued use
						of the Service after any such changes and/or postings shall
						constitute your consent to such changes. If any provision of these
						Terms of Use is held by a court of competent jurisdiction to be
						invalid or unenforceable, then such provision(s) shall be construed
						to reflect the intentions of the invalid or unenforceable
						provision(s), with all other provisions remaining in full force and
						effect.
					</p>
					<br></br>
					<p>
						You may not assign these Terms of Use without the prior written
						approval of MODAMILY. Any purported assignment in violation of this
						section shall be void. MODAMILY reserves the right to use Third
						Party Providers in the provision of the Service and/or the goods,
						service and/or Materials associated therewith. In the event of any
						litigation of any controversy or dispute arising out of or in
						connection with these Terms of Use, its interpretation, its
						performance, or the like, the prevailing party shall be awarded
						reasonable attorneys' fees and expenses, court costs, and reasonable
						costs for expert and other witnesses attributable to the prosecution
						or defense of that controversy or dispute. Any and all rights not
						expressly granted herein are reserved by MODAMILY.
					</p>
					<br></br>
					<p>
						If you have any questions regarding these terms or wish to contact
						us for any matter:
						<br></br>
						Email:
						<accent className="redText">
							{" "}
							<a target="_blank" href="mailto:contact@modamily.com">
								contact@modamily.com
							</a>
						</accent>{" "}
					</p>
				</PageContainer>
			</div>
		);
	};
}
