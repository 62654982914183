import React from "react";
import SwitchButton from "../common/ModamilyAppSwitchButton";

import caretImg from "../../resources/images/appAssets/dropdownArrow.png";

/**
 * @property {String} actionType - determines action button type "switch" or "click"
 * @property {Boolean} active - determines whether the switch button is active
 * @property {String} header - tab header text
 * @property {String} data - user data to display
 * @property {Function} onClick - function fired when the menu item is clicked
 * @property {Boolean} hideOverflow - hide overflow of text displayed
 *
 */
export default class ModamilyAppTabMenuItem extends React.Component {
  render = () => {
    let actionType = this.props.actionType;

    let tabAction = this.props.cancelAction ? null : actionType === "switch" ? (
      <SwitchButton
        key={Math.floor(Math.random() * 1000)}
        onClick={this.props.onClick}
        active={this.props.active}
      />
    ) : (
      <div className="tabMenuArrowContainer">
        <img className="tabMenuArrowImg" src={caretImg} alt="tabMenuArrowImg" />
      </div>
    );

    let headerClass = !!this.props.data
      ? "tabHeaderContainer"
      : "tabHeaderContainerNoData";

    return (
      <div
        className="tabMenuItem"
        onClick={() => {
          if (this.props.onClick && this.props.actionType !== "switch") {
            this.props.onClick();
          }
        }}
      >
        <div
          className={
            "tabContentContainer" +
            (this.props.hideOverflow ? " hideOverflow" : "")
          }
        >
          <div className={headerClass}>
            <p className="tabHeaderText">{this.props.header}</p>
          </div>
          {!!this.props.data ? (
            <div className="tabDataContainer">
              <p className="tabDataText">{this.props.data}</p>
            </div>
          ) : null}
        </div>
        <div className="tabActionContainer">{tabAction}</div>
      </div>
    );
  };
}
