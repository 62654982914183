import React, { Component } from "react";
// import GoogleLogin from "react-google-login";
import { useGoogleLogin } from "@react-oauth/google";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import decode from "jwt-decode";

import ModamilyAppNavBar from "../components/common/ModamilyAppNavBar";
import ModamilyAppTextInput from "../components/form/ModamilyAppTextInput";
import ModamiyAppPageContainer from "../components/common/ModamilyPageContainer";
import emailIcon from "../resources/images/appAssets/email_purple.png";
import lockIcon from "../resources/images/appAssets/lock.png";
import ModamilyAppButton from "../components/common/ModamilyAppButton";
import ModamilyAppLinkText from "../components/common/ModamilyAppLinkText";
import ModamilyAppFooter from "../components/common/ModamilyAppFooter";
import ModamilyAppMutationRenderer from "../apollo/mutations/mutationRenderer";
import ModamilyAppLoadingComponent from "../components/common/ModamilyAppLoadingComponent";
import scriptjs from "scriptjs";
import qsParse from "../utils/queryString";
import appleLogo from "../resources/images/appAssets/apple.png";
import googleLogo from "../resources/images/appAssets/google.png";

import mutations from "../apollo/mutations/mutations";
import config from "../config/config";
import { clearPhoneValidation } from "../utils/storage";

const GoogleLoginWrapper = (onSuccess) => {
  const login = useGoogleLogin({
    onSuccess,
  });

  return (
    <>
      <div className="google-login-container">
        <img src={googleLogo} alt="Login with Google." width="18" height="18" />
      </div>
      <div onClick={login}>
        <p className="purple text-md font-purple font-work-sans weight-400 font-letter-spacing-sm line-height-md">
          Sign in with Google
        </p>
      </div>
    </>
  );
};

class ModamilyAppRegisterLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: !!this.props.isLogin,
      email: "",
      password: "",
      confPass: "",
      sendQuery: false,
      errorMessage: null,
    };
  }

  componentWillMount() {
    let token = localStorage.getItem("auth.token");
    if (token === "undefined") {
      localStorage.clear();
    }
    let user = localStorage.getItem("user");
    if (user) {
      this.setState({ alreadyLoggedIn: true });
    }

    scriptjs.get(
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js",
      () => {
        const params = {
          clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
          redirectURI: process.env.REACT_APP_APPLE_LOGIN_REDIRECT,
          scope: "name email",
        };
        window.AppleID.auth.init(params);
      }
    );

    const parsedQs = qsParse(window.location.search);
    if (parsedQs.appleLogin) {
      this.onSocialSignIn("apple", parsedQs.appleLogin);
    }
  }

  confirmPassword = () => {
    if (
      this.state.password.trim().length > 0 &&
      this.state.confPass.trim().length > 0
    ) {
      return this.state.password === this.state.confPass;
    } else {
      return false;
    }
  };

  queryErrorHandler = (error) => {
    this.setState({
      sendQuery: false,
      loading: false,
      email: "",
      password: "",
      errorMessage: "",
    });
    return <div></div>;
  };

  querySuccessHandler = (data) => {
    let response = decode(data.signIn);
    if (response.action === "success") {
      let token = response.data.token;
      let user = decode(token);
      localStorage.setItem("auth.token", token);
      localStorage.setItem("user", JSON.stringify(user));
      return <Redirect to="/browse" />;
    } else {
      this.setState(
        { sendQuery: false, password: "", loading: false },
        this.createErrorMessage(response.data.message)
      );
      return <div></div>;
    }
  };

  mutationSuccessHandler = (data) => {
    let response = decode(data.signUp);

    if (response.action === "success") {
      let token = response.data.token;
      let user = decode(token);
      localStorage.setItem("auth.token", token);
      localStorage.setItem("user", JSON.stringify(user));
      clearPhoneValidation();
      return <Redirect to="/welldone" />;
    } else {
      this.setState({
        sendQuery: false,
        password: "",
        errorMessage: response.data.message,
      });
      return <div></div>;
    }
  };

  mutationErrorHandler = (error) => {
    let message = "Error, please try again later";

    this.setState({
      sendQuery: false,
      email: "",
      password: "",
      confPass: "",
      errorMessage: message,
    });
    setTimeout(() => {
      this.setState({ errorMessage: null });
    }, 5000);
    return null;
  };

  createErrorMessage = (message) => {
    this.setState({
      errorMessage: message,
    });
    setTimeout(() => {
      this.setState({ errorMessage: null });
    }, 5000);
  };

  renderSocialSignIn = () => {
    let { social, socialResponse } = this.state;
    debugger;
    let token;

    let returnToken;

    const phoneVerification = window.localStorage.getItem("phoneid");
    const phoneVerificationHash = window.localStorage.getItem("token");

    if (social === "google") {
      token = {
        user: {
          ...socialResponse.profileObj,
          name:
            socialResponse.profileObj.given_name ||
            socialResponse.profileObj.name,
        },
        accesssToken: socialResponse.accessToken,
        accessTokenExpirationDate: socialResponse.expires_at,
        phoneVerification,
        phoneVerificationHash,
      };

      returnToken = JSON.stringify({
        ...token,
        expoPushToken: this.state.expoToken || "",
      });

      return (
        <Mutation
          mutation={mutations.googleSignIn}
          variables={{ token: returnToken }}
        >
          {(mutation, { loading, data, error }) => {
            if (error) {
              this.setState({ socialSignIn: false });
            } else if (data) {
              try {
                let response = decode(data.googleSignUp);

                if (response.action === "success") {
                  localStorage.setItem(
                    "user",
                    JSON.stringify(response.data.user)
                  );
                  localStorage.setItem(
                    "auth.token",
                    response.data.modamilyToken
                  );
                  clearPhoneValidation();

                  if (response.data.found) {
                    return <Redirect to="/browse" />;
                  } else {
                    return <Redirect to="/welldone" />;
                  }
                } else {
                  this.setState({ socialSignIn: false });
                }
              } catch (error) {
                this.setState({ socialSignIn: false });
              }
            } else {
              mutation();
            }

            return <ModamilyAppLoadingComponent />;
          }}
        </Mutation>
      );
    } else if (social === "apple") {
      const response = decode(socialResponse);

      let firstName = "";
      let lastName = "";
      if (response.userData.name) {
        firstName = response.userData.name.firstName;
        lastName = response.userData.name.lastName;
      }

      const payload = {
        user: response.user.sub,
        email: response.user.email,
        firstName,
        lastName,
        identityToken: response.id_token,
        authorizationCode: response.code,
        nonce: response.user.c_hash,
        phoneVerification,
        phoneVerificationHash,
      };

      return (
        <Mutation mutation={mutations.appleSignIn} variables={{ ...payload }}>
          {(mutation, { loading, data, error }) => {
            if (error) {
              return null;
            } else if (data) {
              try {
                let response = decode(data.appleSignUp);

                if (response.action === "success") {
                  localStorage.setItem(
                    "user",
                    JSON.stringify(response.data.user)
                  );
                  localStorage.setItem(
                    "auth.token",
                    response.data.modamilyToken
                  );
                  clearPhoneValidation();

                  if (response.data.found) {
                    return <Redirect to="/browse" />;
                  } else {
                    return <Redirect to="/welldone" />;
                  }
                } else {
                  return null;
                }
              } catch (error) {
                return null;
              }
            } else {
              mutation();
            }

            return <ModamilyAppLoadingComponent />;
          }}
        </Mutation>
      );
    } else {
      localStorage.setItem(
        "social.facebook.signInResponse",
        JSON.stringify(socialResponse)
      );
      localStorage.setItem("social.facebook.token", socialResponse.accessToken);
      return <Redirect to="/facebook/confirmEmail" />;
    }
  };

  renderQuery = () => {
    const phoneVerification = window.localStorage.getItem("phoneid");
    const phoneVerificationHash = window.localStorage.getItem("token");
    let vars = this.state.isLogin
      ? { email: this.state.email, password: this.state.password }
      : {
          email: this.state.email,
          password: this.state.password,
          firstName: "",
          lastName: "",
          addressLine1: "",
          addressLine2: "",
          zipcode: "",
          city: "",
          state: "",
          phone: "",
          country: "",
          fcmToken: "",
          phoneVerification,
          phoneVerificationHash,
        };
    return this.state.isLogin ? (
      <ModamilyAppMutationRenderer
        mutation={mutations.signIn}
        variables={vars}
        onError={this.queryErrorHandler}
        onSuccess={this.querySuccessHandler}
        loadingComponent={<ModamilyAppLoadingComponent type="loader" />}
      />
    ) : (
      <ModamilyAppMutationRenderer
        mutation={mutations.signUp}
        variables={vars}
        onError={this.mutationErrorHandler}
        onSuccess={this.mutationSuccessHandler}
        loadingComponent={<ModamilyAppLoadingComponent type="loader" />}
      />
    );
  };

  onEnterPress = (e) => {
    if (this.state.password.trim().length <= 0) {
      this.refs.passwordInput.refs.passwordSign.focus();
      this.createErrorMessage("Please enter a valid password");
      return;
    } else if (this.state.isLogin) {
      this.setState({ sendQuery: true });
    } else if (
      this.state.password.trim().length >= 8 &&
      this.state.email.trim().length > 1
    ) {
      if (this.state.confPass.trim().length <= 0) {
        this.refs.confirmPassInput.refs.confirmPassSign.focus();
        this.createErrorMessage("Confirm password");
      } else if (
        this.state.password.trim().length >= 8 &&
        this.state.email.trim().length > 1 &&
        this.state.confPass.trim().length > 1
      ) {
        if (this.confirmPassword()) {
          this.setState({ sendQuery: true });
        } else {
          this.createErrorMessage("Passwords must match");
        }
      }
    }
  };

  onSocialSignIn = (api, response) => {
    this.setState({
      socialSignIn: true,
      social: api,
      socialResponse: response,
      loading: true,
    });
  };

  toggleLoading = () => {
    let loading = this.state.loading;
    this.setState({ loading: !loading });
  };

  updateText = (text, key) => {
    this.setState({ [key]: text });
  };

  toggleForm = (isLogin) => {
    this.setState({ isLogin });
  };

  render() {
    let {
      isLogin,
      email,
      password,
      sendQuery,
      confPass,
      errorMessage,
      alreadyLoggedIn,
      socialSignIn,
    } = this.state;

    if (alreadyLoggedIn) {
      return <Redirect to="/browse" />;
    }

    if (sendQuery) {
      return this.renderQuery();
    }

    if (socialSignIn) {
      return this.renderSocialSignIn();
    }

    let isDisabled = !(email.trim().length > 0 && password.trim().length > 0);
    if (!isLogin) {
      isDisabled = !(confPass.trim().length > 0);
      isDisabled = !this.confirmPassword();
    }

    let suggestionText = isLogin
      ? "Forgot your password?"
      : "Already have an account?";
    let suggestionLinkText = isLogin ? "Reset" : "Login";
    let suggestionLinkUrl = isLogin ? "/confirmEmail/resetPassword" : "/login";

    let passwordRequirements = isLogin ? null : (
      <div className="passwordRequirements">
        <p className="purpleText lightWeight">
          Password must be at least 8 characters long, contain an uppercase
          letter, a number and a special character
        </p>
      </div>
    );

    let component = this.state.loading ? (
      <ModamilyAppLoadingComponent />
    ) : (
      <>
        <div className="ModamilyApp--email-verification container center">
          {!this.props.isLogin && (
            <>
              <div className="my-32">
                <h2 className="purple heading-lg font-purple font-work-sans weight-600 font-letter-spacing-md line-height-lg">
                  What's your email?
                </h2>
              </div>

              <div className="mb-48">
                <p className="purple text-md font-purple font-work-sans weight-400 font-letter-spacing-sm line-height-md">
                  Don’t ever lose access to your account
                </p>
              </div>
            </>
          )}
          <div className="w-full gap-16">
            <div>
              <ModamilyAppTextInput
                type="email"
                label="Email"
                placeholder="Email Adress"
                reference="emailSign"
                ref="emailInput"
                // icon={emailIcon}
                onContentChange={(text) => {
                  if (typeof text == "boolean") {
                    this.updateText("", "email");
                  } else {
                    this.updateText(text, "email");
                  }
                }}
                onEnterPress={this.onEnterPress}
              />
              {!this.props.isLogin && (
                <p className="helperText pl-4 pt-4">i.e: john@gmail.com</p>
              )}
            </div>

            <div>
              <ModamilyAppTextInput
                type="password"
                label="Password"
                placeholder="Password"
                reference="passwordSign"
                // icon={lockIcon}
                validate={!isLogin}
                ref="passwordInput"
                onContentChange={(text) => {
                  if (typeof text == "boolean") {
                    this.updateText("", "password");
                  } else {
                    this.updateText(text, "password");
                  }
                }}
                onEnterPress={this.onEnterPress}
              />
              {!this.props.isLogin && (
                <p className="helperText pl-4 pt-4">
                  Password must be at least 8 characters long, contain an
                  uppercase letter, a number and a special character
                </p>
              )}
            </div>

            {!this.props.isLogin && (
              <div>
                <ModamilyAppTextInput
                  type="password"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  reference="confirmPassSign"
                  ref="confirmPassInput"
                  onContentChange={(text) => {
                    if (typeof text == "boolean") {
                      this.updateText("", "confPass");
                    } else {
                      this.updateText(text, "confPass");
                    }
                  }}
                  onEnterPress={this.onEnterPress}
                  // icon={lockIcon}
                />

                <p className="helperText pl-4 pt-4">
                  Password's fields should match
                </p>
              </div>
            )}
          </div>
          {this.props.isLogin && (
            <>
              <div className={`register-next-button justify-center mt-48`}>
                <ModamilyAppButton
                  text={"Login"}
                  style="orangeGradient"
                  disabled={isDisabled}
                  onClick={this.onEnterPress}
                />
              </div>
              <div className="formSuggestionTextContainer">
                <p className="formSuggestionText">{suggestionText}</p>
                <div className="loginFormLink">
                  <ModamilyAppLinkText
                    color="red"
                    text={suggestionLinkText}
                    link={suggestionLinkUrl}
                  />
                </div>
              </div>
            </>
          )}

          {errorMessage ? (
            <div className="errorMessageContainer">
              <p className="errorMessageText">{errorMessage}</p>
            </div>
          ) : null}
          <div className="mt-32">
            <p className="purple text-md font-purple font-work-sans weight-400 font-letter-spacing-sm line-height-md">
              {!this.props.isLogin
                ? "Or verify instantly:"
                : "Or continue with:"}
            </p>
          </div>

          <div
            className="background-white border-gray radius-8 shadow-md w-full mt-32 row align-center py-16  pointer social-signin-container"
            id="#prompt_parent_id"
          >
            {
              //   <GoogleLogin
              //   clientId={config.googleClientID}
              //   buttonText=""
              //   onClick={() => {
              //     this.toggleLoading();
              //   }}
              //   onSuccess={(response) => {
              //     this.onSocialSignIn("google", response);
              //     return;
              //   }}
              //   onFailure={(response) => {}}
              //   autoLoad={false}
              //   cookiePolicy={"single_host_origin"}
              //   className="googleLoginButton"
              // />
              // <GoogleLogin
              //   key={"glogin"}
              //   onSuccess={(credentialResponse) => {
              //     // credentialResponse.clientId;
              //     // credentialResponse.credential;
              //     const decodedResponse = decode(credentialResponse.credential);
              //     const response = {
              //       accessToken: credentialResponse.credential,
              //       expires_at: decodedResponse.exp,
              //       profileObj: decodedResponse,
              //     };
              //     // console.info(response);
              //     this.onSocialSignIn("google", response);
              //   }}
              //   onError={() => {
              //     console.log("Login Failed");
              //   }}
              //   type="icon"
              //   size="medium"
              //   prompt_parent_id="prompt_parent_id"
              // />
              // <div>
              //   <p className="purple text-md font-purple font-work-sans weight-400 font-letter-spacing-sm line-height-md">
              //     Sign in with Google
              //   </p>
              // </div>
            }
            <GoogleLoginWrapper
              onSuccess={(credentialResponse) => {
                // credentialResponse.clientId;
                // credentialResponse.credential;
                const decodedResponse = decode(credentialResponse.credential);
                const response = {
                  accessToken: credentialResponse.credential,
                  expires_at: decodedResponse.exp,
                  profileObj: decodedResponse,
                };
                this.onSocialSignIn("google", response);
              }}
            />
          </div>

          <div
            className="background-black  border-gray radius-8 shadow-md w-full mt-32 row align-center py-16  pointer social-signin-container"
            onClick={() => window.AppleID.auth.signIn()}
          >
            <button
              onClick={() => window.AppleID.auth.signIn()}
              className="apple-login"
            >
              <img
                src={appleLogo}
                alt="Login with Apple."
                width="18"
                height="18"
              />
            </button>

            <div>
              <p className="text-white text-md font-purple font-work-sans weight-400 font-letter-spacing-sm line-height-md">
                Sign in with Apple
              </p>
            </div>
          </div>

          {!this.props.isLogin && (
            <div className={`register-next-button justify-end mt-48`}>
              <ModamilyAppButton
                text={"Next"}
                style="orangeGradient"
                disabled={isDisabled}
                onClick={this.onEnterPress}
              />
            </div>
          )}
        </div>
      </>
    );

    return (
      <div className="ModamilyAppRegisterLogin">
        <ModamilyAppNavBar
          hasLinks={false}
          type={isLogin ? "signin" : "register"}
        />
        {component}
      </div>
    );
  }
}

export default ModamilyAppRegisterLogin;
