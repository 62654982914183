import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'
// sagas
import createSagaMiddleware from 'redux-saga';
import rootSage from '../sagas';
// redux-persist
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// local
import reducers from '../reducers';

const persistConfig = {
    key: 'root',
    storage,
};

let enhancers = [];
let middlewares = [];

// Setup saga middleware
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

// Assemble Middleware
if (process.env.NODE_ENV === 'development'){
  enhancers.push(composeWithDevTools(applyMiddleware(...middlewares)));
  } else {
  enhancers.push(applyMiddleware(...middlewares));
}

// Create store & persistStore Setup
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = createStore(persistedReducer, compose(...enhancers));
export const persistor = persistStore(store);

// run sagas
sagaMiddleware.run(rootSage);
