import React, { Component } from "react";
import ModamilyAppGoalBox from "./ModamilyAppGoalBox";

class ModamilyAppGoalsSection extends Component {
	render() {
		return (
			<div className="goalSection">
				<div className="goalImage">
					<img
						className="goalImg"
						src={require("../../resources/images/appAssets/goalImage@3x.png")}
						alt=""
					/>
				</div>
				<div className="goalInfo">
					<div className="goalInfoContentContainer">
						<p className="goalTitle purpleHeaderText">
							How Modamily can support
							<br />
							your family goals
						</p>
						<ModamilyAppGoalBox text="Connect with singles ready to have kids in a traditional romantic relationship" />
						<ModamilyAppGoalBox text="Match with a platonic co-parent that shares your parenting vision and value system" />
						<ModamilyAppGoalBox text="Explore known donor and surrogate options to help you start a family" />
					</div>
				</div>
			</div>
		);
	}
}
export default ModamilyAppGoalsSection;
