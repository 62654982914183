/* eslint-disable react/style-prop-object */
import React from "react";

import decode from "jwt-decode";
import { withApollo } from "react-apollo";
import FormOptions from "../../constants/filterOptions";
import Countries from "../../constants/countries";
import Queries from "../../apollo/queries/queries";

import ModamilyAppLoadingComponent from "../common/ModamilyAppLoadingComponent";
import ModamilyAppTabMenuItem from "../common/ModamilyAppTabMenuItem";
import DropDownMenu from "../form/ModamilyAppDropDownMenu";
import TextInput from "../form/ModamilyAppTextInput";
import MultiSlider from "../form/ModamilyAppMultiSliderInput";
import Slider from "../form/ModamilyAppSliderInput";
import SwitchButton from "../common/ModamilyAppSwitchButton";
import Button from "../common/ModamilyAppButton";
import RoundImage from "../common/ModamilyAppRoundImage";
import ModamilyAppTabMenuHeader from "../common/ModamilyAppTabMenuHeader";
import FilterImage from "../../resources/images/appAssets/filter.svg";
import { v4 as uuidv4 } from "uuid";
import { debounce } from "lodash";
import { decode as htmlDecode } from "he";

const defaultState = {
  email: "",
  firstName: "",
  lastName: "",
  gender: [],
  minAge: 18,
  maxAge: 100,
  minIncome: 25000,
  maxIncome: 1000000,
  minHeight: 48,
  maxHeight: 84,
  distance: 1500,
  zipcode: "",
  country: "",
  haveAbaby: "",
  lookingFor: [],
  religion: [],
  race: [],
  ethnicity: [],
  eyeColor: [],
  hairColor: [],
  subscription: [],
  heightIsMetric: false,
  lastLogin: "",
  count: 0,
  page: 0,
  showMenu: false,
  key: Math.random * 100,
  users: [],
  loading: false,
  buildingCSV: false,
  education: [],
  relationshipStatus: [],
};

class AdminHome extends React.Component {
  count = 0;
  limit = 200;
  totalCount = 0;

  handleGetUsersRequestDebounced = debounce(
    (p) => this.handleGetUsersRequest(p),
    1500
  );

  constructor(props) {
    super(props);
    const prevState = localStorage.getItem("state");
    let iState = defaultState;
    if (prevState) {
      iState = Object.assign({}, defaultState, JSON.parse(prevState));
    }

    this.state = {
      ...iState,
    };
  }

  generateUserList = (data) => {
    let arr = [];

    if (!data) {
      return "No users found!";
    }

    for (let index = 0; index < data.length; index++) {
      const user = data[index];

      //
      arr.push(
        <div className="adminRowUserContainer" key={uuidv4()}>
          <RoundImage
            isUrl
            image={user.images ? user.images[0].path : null}
            name={user.firstName}
          />
          <ModamilyAppTabMenuItem
            key={user.id + "_" + user.firstName}
            header={user.firstName + " " + user.lastName}
            data={user.email}
            userData={user}
            actionType="click"
            onClick={() => {
              this.props.selectUser(user);
            }}
          />
        </div>
      );
    }
    return (
      <div className="adminUserList" key={uuidv4()}>
        {arr}
      </div>
    );
  };

  genderFilterSelect = (gender) => {
    if (gender === "all") {
      this.setState({ gender: [] }, () =>
        this.handleGetUsersRequestDebounced()
      );
    } else {
      this.setState({ gender: [gender] }, () =>
        this.handleGetUsersRequestDebounced()
      );
    }
  };

  updateField = (key, value) => {
    if (
      (key !== "email" &&
        key !== "firstName" &&
        key !== "lastName" &&
        value === "all") ||
      value === "no preference"
    ) {
      value = [];
      if (key === "country") {
        value = "";
      }
    }
    let state = this.state;
    state[key] = value;
    this.setState(state, () => this.handleGetUsersRequestDebounced());
    // this.onEnterPress();
  };

  onEnterPress = () => {
    this.handleGetUsersRequestDebounced();
  };

  onClear = () => {
    localStorage.removeItem("state");
    this.setState(defaultState, () => {
      this.handleGetUsersRequestDebounced();
    });
  };

  sanitizeCSVValue = (val) => {
    let value = val;
    if (typeof val === "string") {
      value = htmlDecode(val);
    }
    return value;
  };

  exportCSV = async () => {
    this.setState({ buildingCSV: true });
    try {
      const result = await this.fetchUsers(1, true);
      if (result && result.data && result.data.users) {
        const users = result.data.users;

        let userKeys = [
          "id",
          "email",
          "firstName",
          "lastName",
          "country",
          "zipcode",
          "age",
          "gender",
          "religion",
          "haveAbaby",
          "race",
          "astralSign",
          "eyeColor",
          "hairColor",
          "height",
          "heightIsMetric",
          "politics",
          "income",
          "phone",
          "diet",
          "bodyType",
          "bloodType",
          "children",
          "education",
          "school",
          "createdAt",
          "updatedAt",
          "userType",
          "subType",
          "storeSubscription",
          "subCreatedAt",
          "subUpdatedAt",
        ];
        const cols = userKeys.join(";");
        let csv = cols + "\n";

        for (let index = 0; index < users.length; index++) {
          const user = users[index];

          let values = [];
          for (let j = 0; j < userKeys.length; j++) {
            const key = userKeys[j];
            if (key in user) {
              const value = this.sanitizeCSVValue(user[key] || "");
              values.push(value);
            }
          }

          if (user.subscription) {
            let subscription = user.subscription;

            values.push(
              subscription.subType === "free" ? "Free" : "Premium",
              subscription.subType,
              subscription.storeSubscription,
              subscription.createdAt,
              subscription.updatedAt
            );
          } else {
            values.push("Free", "Free", "", "", "");
          }
          csv += values.join(";") + "\n";
        }

        let currentdate = new Date();
        let datetime =
          currentdate.getMonth() +
          1 +
          "/" +
          currentdate.getDate() +
          "/" +
          currentdate.getFullYear() +
          "_" +
          currentdate.getHours() +
          ":" +
          currentdate.getMinutes() +
          ":" +
          currentdate.getSeconds();

        const hiddenElement = document.createElement("a");
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
        hiddenElement.target = "_blank";
        hiddenElement.download = `users_${datetime}.csv`;
        hiddenElement.click();

        console.log(csv);

        this.setState({ buildingCSV: false });
      } else {
        this.setState({ buildingCSV: false });
        alert("error");
      }
    } catch (error) {
      console.error(error);
      this.setState({ buildingCSV: false });
    }
  };

  filterButtonClick = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  componentDidMount() {
    // const prevState = localStorage.getItem("state");
    // console.log(prevState);
    // if (prevState) {
    // 	this.setState({
    // 		...JSON.parse(prevState)
    // 	}, () => {
    // 		this.handleGetUsersRequestDebounced();
    // 	})
    // } else {
    this.handleGetUsersRequestDebounced();
    // }
  }

  fetchUsers = async (page, all) => {
    try {
      const { client } = this.props;
      const {
        minHeight,
        maxHeight,
        minAge,
        maxAge,
        minIncome,
        maxIncome,
        distance,
        gender,
        zipcode,
        country,
        religion,
        ethnicity,
        education,
        eyeColor,
        hairColor,
        race,
        politics,
        lookingFor,
        heightIsMetric,
        haveAbaby,
        lastName,
        firstName,
        email,
        lastLogin,
        subscription,
        relationshipStatus,
      } = this.state;

      const response = await client.query({
        query: Queries.adminGetUsers,
        variables: {
          minHeight,
          maxHeight,
          minAge,
          maxAge,
          minIncome,
          maxIncome,
          distance,
          gender,
          zipcode,
          country,
          religion,
          ethnicity,
          education,
          relationshipStatus,
          eyeColor,
          hairColor,
          race,
          politics,
          lookingFor,
          page,
          heightIsMetric,
          haveAbaby,
          lastName,
          firstName,
          email,
          lastLogin,
          subscription,
          all,
        },
      });

      return decode(response.data.adminGetUsers);
    } catch (err) {
      this.setState({ loading: false });
      console.log("Error: ", err);
    }
  };

  handleGetUsersRequest = async (page = this.state.page) => {
    this.setState({ loading: true, showMenu: false });
    const { users, ...restProps } = this.state;

    localStorage.setItem("state", JSON.stringify(restProps));

    try {
      const result = await this.fetchUsers(page);
      if (result && result.data && result.data.users) {
        this.setState({
          users: result.data.users,
          count: result.data.users.length,
          loading: false,
          totalCount: result.data.totalCount,
          page,
        });
      }
    } catch (err) {
      this.setState({ loading: false });
      console.log("Error: ", err);
    }
  };

  clearLastLogin() {
    this.setState(
      {
        lastLogin: "",
      },
      this.handleGetUsersRequestDebounced()
    );
  }

  render = () => {
    // console.log(this.state.minHeight, this.state.maxHeight);
    // const { users } = this.state;

    let menu = (
      <div
        className={
          "adminFilterMenu" +
          (this.state.showMenu ? " adminMenuActive" : " adminMenuHidden")
        }
      >
        <div className="fd-row">
          <div className="sliderControl">
            <div className="slidersContainer">
              <MultiSlider
                className="tito"
                units="years old"
                data={[this.state.minAge, this.state.maxAge]}
                type="age"
                min={18}
                max={100}
                step={1}
                onChange={(values) => {
                  this.setState({ minAge: values[0], maxAge: values[1] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                }}
                key={[this.state.minAge, this.state.maxAge].join(".")}
              />
              Income
              <MultiSlider
                units="$"
                data={[this.state.minIncome, this.state.maxIncome]}
                type="income"
                min={25000}
                max={1000000}
                step={5000}
                onChange={(values) => {
                  this.setState(
                    {
                      minIncome: values[0],
                      maxIncome: values[1],
                    },
                    () => this.handleGetUsersRequestDebounced()
                  );
                }}
              />
              <div className="heightSwitchButtonContainer fd-row">
                <p>Inches</p>
                <div className="heightSwitchButton">
                  <SwitchButton
                    active={!this.state.heightIsMetric}
                    onClick={() => {
                      const heightIsMetric = !this.state.heightIsMetric;
                      this.setState({ heightIsMetric }, () =>
                        this.handleGetUsersRequestDebounced()
                      );
                    }}
                  />
                </div>
                {/* <p>centimeters</p> */}
              </div>
              <MultiSlider
                key={this.state.heightIsMetric}
                units={this.state.heightIsMetric ? "cm" : "in"}
                data={[this.state.minHeight, this.state.maxHeight]}
                type="height"
                min={this.state.heightIsMetric ? 121 : 48}
                max={this.state.heightIsMetric ? 213 : 84}
                step={1}
                onChange={(values) => {
                  this.setState(
                    {
                      minHeight: values[0],
                      maxHeight: values[1],
                    },
                    () => this.handleGetUsersRequestDebounced()
                  );
                }}
              />
              <Slider
                min={0}
                max={10000}
                step={1}
                units="distance"
                value={this.state.distance}
                onChange={(value) => {
                  this.setState({ distance: value }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                }}
              />
            </div>
            <TextInput
              value={this.state.email}
              label="Email"
              onEnterPress={this.onEnterPress}
              onContentChange={(data) => {
                this.updateField("email", data ? data : "");
              }}
              placeholder="Email"
            />
            <TextInput
              value={this.state.firstName}
              label="First Name"
              onEnterPress={this.onEnterPress}
              onContentChange={(data) => {
                this.updateField("firstName", data ? data : "");
              }}
              placeholder="First Name"
            />
            <TextInput
              value={this.state.lastName}
              label="Last Name"
              onEnterPress={this.onEnterPress}
              onContentChange={(data) => {
                this.updateField("lastName", data ? data : "");
              }}
              placeholder="Last Name"
            />
            <div className="admin-date-picker">
              <TextInput
                value={this.state.lastLogin}
                label="Last Login"
                onEnterPress={this.onEnterPress}
                onContentChange={(data) => {
                  this.updateField("lastLogin", data ? data : "");
                }}
                type="date"
              />
              <span
                className="clear-date"
                onClick={() => {
                  this.updateField("lastLogin", "");
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="dropMenuControls">
            <DropDownMenu
              data={FormOptions.genders}
              onItemClick={this.genderFilterSelect}
              type="Gender"
              title="Gender Filter"
              selected={FormOptions.genders.indexOf(this.state.gender[0])}
            />
            <DropDownMenu
              data={FormOptions.lookingFors}
              onItemClick={(data) => {
                if (data === "all") {
                  this.setState({ lookingFor: [] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                } else {
                  this.setState({ lookingFor: [data] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                }
              }}
              type="Looking For"
              title="Looking For Filter"
              selected={FormOptions.lookingFors.indexOf(
                this.state.lookingFor[0]
              )}
            />
            <DropDownMenu
              data={FormOptions.religion}
              onItemClick={(data) => {
                if (data === "all") {
                  this.setState({ religion: [] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                } else {
                  this.setState({ religion: [data] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                }
              }}
              type="Religion"
              title="Religion Filter"
              selected={FormOptions.religion.indexOf(this.state.religion[0])}
            />
            <DropDownMenu
              data={FormOptions.ethnicities}
              onItemClick={(data) => {
                if (data === "all") {
                  this.setState({ ethnicity: [] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                } else {
                  this.setState({ ethnicity: [data] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                }
              }}
              type="Ethnicity"
              title="Ethnicity Filter"
              selected={FormOptions.ethnicities.indexOf(
                this.state.ethnicity[0]
              )}
            />
            <DropDownMenu
              data={FormOptions.races}
              onItemClick={(data) => {
                if (data === "all") {
                  this.setState({ race: [] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                } else {
                  this.setState({ race: [data] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                }
              }}
              type="Race"
              title="Race Filter"
              selected={FormOptions.races.indexOf(this.state.race[0])}
            />
            <DropDownMenu
              data={FormOptions.eyeColor}
              onItemClick={(data) => {
                if (data === "all") {
                  this.setState({ eyeColor: [] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                } else {
                  this.setState({ eyeColor: [data] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                }
              }}
              type="Eye Color"
              title="Eye Color Filter"
              selected={FormOptions.eyeColor.indexOf(this.state.eyeColor[0])}
            />
            <DropDownMenu
              data={FormOptions.haveAbaby}
              onItemClick={(data) => {
                if (data === "all") {
                  this.setState({ haveAbaby: "" }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                } else {
                  this.setState({ haveAbaby: data }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                }
              }}
              type="Plan to have a baby"
              title="When to have a baby Filter"
              selected={FormOptions.haveAbaby.indexOf(this.state.haveAbaby)}
            />
            <DropDownMenu
              data={FormOptions.hairColor}
              onItemClick={(data) => {
                if (data === "all") {
                  this.setState({ hairColor: [] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                } else {
                  this.setState({ hairColor: [data] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                }
              }}
              type="Hair Color"
              title="Hair Color Filter"
              selected={FormOptions.hairColor.indexOf(this.state.hairColor[0])}
            />
            <DropDownMenu
              data={Countries.countryNames}
              onItemClick={(string) => {
                this.updateField("country", string);
              }}
              type="Country"
              title="Country"
              selected={Countries.countryNames.indexOf(this.state.country)}
            />
            <DropDownMenu
              data={FormOptions.subscription}
              onItemClick={(data) => {
                if (data === "all") {
                  this.setState({ subscription: [] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                } else {
                  this.setState({ subscription: [data] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                }
              }}
              type="Subscription"
              title="Subscription"
              selected={FormOptions.subscription.indexOf(
                this.state.subscription[0]
              )}
            />
            <DropDownMenu
              data={FormOptions.educationLevels}
              onItemClick={(data) => {
                if (data === "all") {
                  this.setState({ education: [] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                } else {
                  this.setState({ education: [data] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                }
              }}
              type="Education"
              title="Education"
              selected={FormOptions.educationLevels.indexOf(
                this.state.education[0]
              )}
            />
            <DropDownMenu
              data={FormOptions.relationship}
              onItemClick={(data) => {
                if (data === "all") {
                  this.setState({ relationshipStatus: [] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                } else {
                  this.setState({ relationshipStatus: [data] }, () =>
                    this.handleGetUsersRequestDebounced()
                  );
                }
              }}
              type="Relationship"
              title="Relationship"
              selected={FormOptions.relationship.indexOf(
                this.state.relationshipStatus[0]
              )}
            />
            <TextInput
              value={this.state.zipcode}
              label="Zipcode"
              onEnterPress={this.onEnterPress}
              onContentChange={(data) => {
                this.updateField("zipcode", data ? data : "");
              }}
              placeholder="Zipcode"
            />
          </div>
        </div>
        <div className="buttonControls">
          <div
            className="refreshUserListBtn"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Button
              style="orangeGradient"
              onClick={this.onClear}
              text="Clear"
            />
            <span> </span>
            <Button
              style="orangeGradient"
              onClick={this.onEnterPress}
              text="Refresh"
            />
          </div>
          <div className="refreshUserListBtn">
            <Button
              style="red"
              text={
                this.state.buildingCSV ? "Generating CSV File" : "Export as CSV"
              }
              onClick={() => {
                this.exportCSV();
              }}
            />
          </div>
        </div>
      </div>
    );

    let menuButton = (
      <div onClick={this.filterButtonClick} className="filterButton">
        <div className="buttonImageContainer">
          <img alt={""} src={FilterImage} className="filterImage"></img>
        </div>
      </div>
    );

    let pageAmount = this.state.totalCount / this.limit;

    let pageComponents = [];

    let startingIndex = this.state.page >= 8 ? this.state.page : 0;
    let endingIndex =
      pageAmount >= 8
        ? this.state.page >= 8
          ? this.state.page + 9
          : 10
        : pageAmount;

    for (let index = startingIndex; index < endingIndex; index++) {
      pageComponents.push(
        <div className="pageButton" key={index + this.state.page}>
          <Button
            style={index === this.state.page ? "white" : "orangeGradient"}
            onClick={() => {
              if (index !== this.state.page)
                this.handleGetUsersRequestDebounced(index);
            }}
            text={index + 1}
          />
        </div>
      );
    }
    if (this.state.page >= 8) {
      pageComponents.unshift(
        <div className="pageButton">
          <Button
            style={"orangeGradient"}
            onClick={() => {
              this.handleGetUsersRequestDebounced(
                this.state.page >= 8 ? 0 : this.state.page - 1
              );
            }}
            text={this.state.page >= 8 ? 1 : this.state.page}
          />
        </div>
      );
    }
    console.log("listado de users", this.state.users);
    return (
      <div className="adminHome">
        <div className="fd-row">
          {menu}
          {menuButton}
          {this.state.showMenu ? null : (
            <div className="adminUserListContainer" key={Math.random() * 100}>
              {this.state.loading ? <ModamilyAppLoadingComponent /> : null}
              <div className="paginationContainer fd-row">{pageComponents}</div>
              <ModamilyAppTabMenuHeader
                key={this.state.count}
                text={`showing ${
                  this.state.count * this.state.page +
                  1 +
                  "-" +
                  (this.state.count * this.state.page + this.state.count)
                } results of ${this.state.totalCount}`}
              />
              {this.generateUserList(this.state.users)}
            </div>
          )}
        </div>
      </div>
    );
  };
}

export default withApollo(AdminHome);
