import React from "react";
import downArrow from "../../resources/images/appAssets/dropdownArrow.png";

/**
 * @property {Array(String)} data - data for selection
 * @property {String} title - Title for default display, default is "Select Item"
 * @property {String} type - Display text for when an item is selected, e.g., "{type} : {selectedItem}", default is "Data"
 * @property {Array(Integer)} selected - Indices of selected values from the array
 * @property {Function(Array)} onItemClick - Function fired when an item is clicked, receives the updated list of selected items
 */
export default class ModamilyAppMultipleDropDown extends React.Component {
  state = {
    data: this.props.data || [],
    selected: this.props.selected || [],
    displayMenu: false,
    title: this.props.title || "Select Item",
    type: this.props.type || "Data",
    isTall: this.props.isTall,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selected !== this.props.selected) {
      this.setState({ selected: this.props.selected });
    }
  }

  onItemClick = (dataPoint, index) => {
    this.setState(
      (prevState) => {
        const selectedArray = Array.isArray(prevState.selected) ? prevState.selected : [];
        const selected = selectedArray.includes(index)
          ? selectedArray.filter((i) => i !== index)
          : [...selectedArray, index];
        return { selected };
      },
      () => {
        if (this.props.onItemClick) {
          const selectedItemsArray = Array.isArray(this.state.selected) ? this.state.selected : [];
          const selectedItems = selectedItemsArray.map((i) => this.state.data[i]);
          this.props.onItemClick(selectedItems);
        }
      }
    );
  };

  clearSelection = () => {
    this.setState({ selected: [] }, () => {
      if (this.props.onItemClick) {
        this.props.onItemClick([]);
      }
    });
  };

  showDropdownMenu = (evt) => {
    evt.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  render() {
    let { title, data, selected, type, isTall } = this.state;

    let menuItems = data.map((dataPoint, index) => {
      const isSelectedArray = Array.isArray(selected) ? selected : [];
      
      return (
        <div
          key={index}
          onClick={() => this.onItemClick(dataPoint, index)}
          className={
            "dropdownMenuItem" +
            (isSelectedArray.includes(index) ? " selectedMenuItem" : "")
          }
        >
          <div className="dropdownMenuItemTextContainer">
            <div className="dropdownMenuItemRow">
              <p className="dropdownMenuItemText">{dataPoint}</p>
              {isSelectedArray.includes(index) && (
                <p className="checkItemText">x</p>
              )}
            </div>
          </div>
        </div>
      );
    });

    if (selected.length > 0) {
      title = type + " : " + selected.map((i) => data[i]).join(", ");
    }

    const clearSelectionComponent = (
      <div
        className="clearSelection"
        onClick={this.clearSelection}
      >
        Clear selection
      </div>
    );

    return (
      <>
        {isTall ? (
          <div className="heightContainer">
            <div className="heightContainerMenu">
              {selected.length > 0 && clearSelectionComponent}
              {menuItems}
            </div>
          </div>
        ) : (
          <div className="dropdownMenu">
            <div className="dropdownMenuButton" onClick={this.showDropdownMenu}>
              <p>{title}</p>{" "}
              <img className="dropdownArrow" alt="down-arrow" src={downArrow} />
            </div>
            {this.state.displayMenu ? (
              <div className="dropdownMenuContainer">
                {selected.length > 0 && clearSelectionComponent}
                {menuItems}
              </div>
            ) : null}
          </div>
        )}
      </>
    );
  }
}

