import React, { Component } from "react";
import ModamilyAppButton from "../common/ModamilyAppButton";

class ModamilyAppNewsletterBox extends Component {
  render() {
    return (
      <div className="newsletterBox">
        <div className="newsletterContentContainer">
          <p className="newsletterText">Join Our Newsletter</p>
          <div className="newsTextBox">
            <input type="text" className="newsInput" spellCheck={false} />
            <div className="newsLetterBtn">
              <ModamilyAppButton style="orangeGradient" text="Join" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModamilyAppNewsletterBox;
