import React, { Component } from 'react';
import ModamilyAppButton from "../common/ModamilyAppButton";

class ModamilyAppAdvertisingBox extends Component {
  openEmail = () =>{
    window.open("mailto:support@modamily.com?subject=Interested in becoming a Modamily Partner","_newtab");
  }
  render() {
    return (
      <div className="enterpriseBox">
        <p className="enterpriseText">{this.props.text} </p>
        <div className="enterpriseBtn">
          <ModamilyAppButton text="Interested in becoming a Modamily Partner" style="white" onClick={this.openEmail}/>
        </div>
      </div>
    );
  }
}
export default ModamilyAppAdvertisingBox;
