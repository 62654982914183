exports.lookingGender = ["women", "men", "everyone"];

exports.genders = [
  "woman",
  "man",
  "non-binary",
  "agender",
  "androgyne",
  "androgynous",
  "bigender",
  "cis",
  "cisgender",
  "enby",
  "female to male",
  "ftm",
  "gender fluid",
  "gender nonconforming",
  "gender questioning",
  "gender variant",
  "genderqueer",
  "intersex",
  "male to female",
  "mtf",
  "neither",
  "neutrois",
  "non-binary",
  "other",
  "pangender",
  "trans",
  "trans female",
  "trans male",
  "trans man",
  "trans person",
  "trans woman",
  "transfeminine",
  "transgender",
  "transgender female",
  "transgender male",
  "transgender man",
  "transgender person",
  "transgender woman",
  "transmasculine",
  "transsexual",
  "transsexual female",
  "transsexual male",
  "transsexual man",
  "transsexual person",
  "transsexual woman",
  "two-spirit",
];

exports.educationLevels = [
  "postgrad",
  "undergrad",
  "some college",
  "highschool or equivalent",
  "no preference",
];

exports.politics = [
  "liberal",
  "moderate",
  "conservative",
  "other",
  "no preference",
];

exports.religion = [
  "atheist",
  "christian",
  "catholic",
  "jewish",
  "muslim",
  "buddhist",
  "hinduism",
  "orthodox",
  "spiritual",
  "sikhism",
  "no preference",
];

exports.ethnicities = [
  "African",
  "Arab",
  "Armenian",
  "Australian",
  "Brazilian",
  "Caribbean",
  "Central african",
  "Central american",
  "Central asian",
  "Chinese",
  "Czech",
  "Danish",
  "Dutch",
  "Eastern european",
  "Egyptian",
  "English",
  "Filipino",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Hawaiian",
  "Hungarian",
  "Icelandic",
  "Indian",
  "Indonesian",
  "Irish",
  "Italian",
  "Japanese",
  "Jewish",
  "Korean",
  "Lebanese",
  "Mexican",
  "Native american",
  "Norwegian",
  "Pacific islander",
  "Persian",
  "Polish",
  "Portuguese",
  "Russian",
  "Scottish",
  "Slavic",
  "South african",
  "Spanish",
  "Swedish",
  "Thai",
  "Turkish",
  "Vietnamese",
  "Welsh",
  "West african",
  "Prefer not to say",
];

exports.eyeColor = [
  "black",
  "blue",
  "brown",
  "green",
  "hazel",
  "no Preference",
];

exports.hairColor = [
  "auburn",
  "brown",
  "black",
  "blonde",
  // "dark brown",
  // "dirty blonde",
  // "light brown",
  "red",
  // "strawberry blonde",
  "orange",
  "gray",
  "other",
  "no preference",
];

exports.races = [
  "asian",
  "black",
  "white",
  "hispanic/latin",
  "native american",
  "middle eastern",
  "mixed",
  "no preference",
];

exports.lookingFors = [
  "co-parent",
  "looking for a known sperm donor",
  "open to being a known sperm donor",
];

exports.relationshipStatus = ["single", "in a relationship"];

exports.bodyTypes = [
  "thin",
  "athletic",
  "normal",
  "curvy",
  "overweight",
  "prefer not to say",
];

exports.diet = [
  "anything goes",
  "healthy",
  "vegetarian",
  "vegan",
  "kosher",
  "only eat to survive",
  "other",
];

exports.drinks = ["very often", "socially", "rarely", "not at all"];

exports.drugs = ["often", "sometimes", "never"];

exports.marijuana = ["yes", "sometimes", "no", "prefer not to say"];

exports.smoke = ["yes", "socially", "no"];

exports.fertilization = [
  "IVF",
  "home insemination",
  "artificial insemination",
  "natural insemination",
  "depends on the person",
];

exports.bloodType = ["A+", "A-", "B+", "B-", "AB+", "O+", "O-"];

exports.children = ["I have none", "I have one", "I have two or more children"];

exports.orientation = [
  "straight",
  "gay",
  "bi",
  "undeclared",
  "prefer not to say",
];

exports.haveABaby = [
  "Less than 6 months",
  "6 to 12 months",
  "Over a year from now",
  "Depends on the person",
];
