import React from "react";
import dotLoader from "../../resources/images/appAssets/dotloader.gif";
import cradleLoader from "../../resources/images/appAssets/loader.gif";

/**
 * @property {String} type  - "dot" | "loader" . Determines the type of loading component to render. Use "dot" for text fields. User "loader" for page/large components such as images
 */
export default class ModamilyAppLoadingComponent extends React.Component {
  render() {
    let component = <div className="cradleLoaderContainer">
      <img className="cradleLoader" src={cradleLoader} alt="loader" />
    </div>;

    switch (this.props.type) {
      case "dot":
        component = <img className="dotLoader" src={dotLoader} alt="loader" />;
        break;
      case "loader":
        component = (
          <div className="cradleLoaderContainer">
            <img className="cradleLoader" src={cradleLoader} alt="loader" />
          </div>
        );
        break;
      default: 
      component = (
        <div className="cradleLoaderContainer">
        </div>
      );
    }

    return component;
  }
}
