import React from "react";
import ModamilyAppTextInput from "../form/ModamilyAppTextInput";
import Button from "../common/ModamilyAppButton";
import ModamilyAppFormHeader from "../form/ModamilyAppFormHeader";
import getLocation from "../../services/googleMaps";
import countries from "../../constants/countries";
import ModamilyAppLoadingComponent from "../common/ModamilyAppLoadingComponent";
import ModamilyAppMultipleDropDown from "../form/ModamilyAppMultipleDropDown";

/**
 * @property {Object} data  - { country: [], zipcode: null, latLong: null  } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange - Function that gets called when the form is valid
 */
export default class ModamilyAppHometownForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        country: Array.isArray(this.props.data.country) ? this.props.data.country : [],
        zipcode: this.props.data.zipcode || null,
        latLong: this.props.data.latLong || null,
      },
    };
  }

  componentDidMount() {
    const { type } = this.props;
    if (type !== "UPDATE_SEARCH_PREFERENCES" && (!this.props.data.country || this.props.data.country.length === 0)) {
      this.checkGeolocation();
    } else {
      this.validateCallback();
    }
  }

  validateCallback = () => {
    const { data } = this.state;
    const { validation, invalidate, onValidChange } = this.props;

    if (validation === "custom") {
      onValidChange(data);
    } else if (data.country.length > 0 && data.zipcode) {
      onValidChange(data);
    } else if (!data.zipcode) {
      onValidChange({ country: this.props.data.country, zipcode: null });
    } else {
      invalidate();
    }
  };

  checkGeolocation = () => {
    if ("geolocation" in navigator) {
      this.setState({ isLoading: true });

      navigator.geolocation.getCurrentPosition((position) => {
        let location = {
          city: "",
          country: [],
          zipcode: "",
          latLong: position.coords.latitude + "," + position.coords.longitude,
        };

        getLocation(position.coords).then((data) => {
          const res = JSON.parse(data).results;
          console.log(res, "response from google");
          if (res.length === 0) {
            window.alert("Sorry, an error occurred. \nPlease try again later");
            this.setState({ data: location }, this.validateCallback);
            return null;
          }

          for (let index = 0; index < res.length; index++) {
            const locationObj = res[index];
            if (locationObj.address_components) {
              for (let y = 0; y < locationObj.address_components.length; y++) {
                const address_components = locationObj.address_components[y];
                if (address_components.types.indexOf("locality") > -1) {
                  location.city = address_components.long_name;
                } else if (address_components.types.indexOf("country") > -1) {
                  location.country.push(address_components.long_name);
                } else if (address_components.types.indexOf("postal_code") > -1) {
                  location.zipcode = address_components.long_name;
                }

                if (location.zipcode && location.city && location.country.length > 0) {
                  break;
                }
              }
              if (location.zipcode && location.city && location.country.length > 0) {
                break;
              }
            }
            if (location.zipcode && location.city && location.country.length > 0) {
              break;
            }
          }
          this.setState(
            { data: location, isLoading: false },
            this.validateCallback
          );
        });
        this.setState({ isLoading: false });
      });

      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  updateName = (key, text) => {
    if (!text && key !== "zipcode") {
      return this.props.invalidate();
    }

    this.setState(
      ({ data }) => ({
        data: { ...data, [key]: text || "" },
      }),
      this.validateCallback
    );
  };

  onCountrySelect = (countries) => {
    this.setState(
      ({ data }) => ({
        data: { ...data, country: countries },
      }),
      this.validateCallback
    );
  };

  render() {
    const { isLoading, data } = this.state;
    const { isSearchForm } = this.props;

    const selectedCountries = data.country.map((country) => 
      countries.countryNames.findIndex((countryName) => countryName === country)
    );
    
    if (isLoading) return <ModamilyAppLoadingComponent />;
    return (
      <div className="currentLocationForm">
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            Where do you live?
          </h2>
        </div>

        <div className="locationDropDowwnContainer">
          <ModamilyAppMultipleDropDown
            key={data.country}
            selected={selectedCountries}
            data={countries.countryNames}
            title="Select Country"
            type="Country"
            onItemClick={this.onCountrySelect}
          />
          <div className="ModamilyAppOnboarding__work--role">
            <p>ie. United States</p>
          </div>
        </div>

        <div className="zipcodeInputContainer">
          <ModamilyAppTextInput
            onContentChange={(text) => {
              this.updateName("zipcode", text);
            }}
            value={data.zipcode}
            label="Zip Code"
          />
          <div className="ModamilyAppOnboarding__work--role">
            <p>Zip Code</p>
          </div>
        </div>
      </div>
    );
  }
}