exports.USE_SOCKET_IO_CHAT_SYSTEM = true;
let bucketUrl = process.env.REACT_APP_BUCKET_URL;
let stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
let apiUrl = process.env.REACT_APP_API_URL;

//Used for backend communication
exports.backendUrn = apiUrl;
exports.backendUri =
  process.env.REACT_APP_ENV !== "development"
    ? "https://" + apiUrl
    : "http://" + apiUrl;

//Used for image reference
exports.AWS_URL = bucketUrl;

//Used for social API'S
exports.googleClientID =
  "420814389971-89odcpsodpu6056q0ou6atj76lr2hifo.apps.googleusercontent.com";
exports.facebookAppID = "1472587806338388";
exports.stripePublicKey = stripePublicKey;

exports.appStoreUrl = "https://apps.apple.com/us/app/modamily/id902242970";
exports.googlePlayUrl =
  "https://play.google.com/store/apps/details?id=com.Modamily.Modamily";
