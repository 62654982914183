import React, { useState } from "react";

//form
import FormHeader from "../../../form/ModamilyAppFormHeader";
import ButtonGroup from "../../../form/ModamilyAppButtonGroup";

// cosntants
import Options from "../../../../constants/formOptions";
import ModamilyAppCheckButton from "../../../form/ModamilyAppCheckButton";
import { capitalize } from "lodash";
import ModamilyAppRadioButton from "../../../form/ModamilyAppRadioButton";
import { useEffect } from "react";
import { religion, activityOptions } from "../../../../constants/filterOptions";

const ReligionForm = ({ data, onValidChange }) => {
  const [religionSelected, setReligionSelected] = useState(data.religion);
  const [activitySelected, setActivitySelected] = useState(
    data.religionActivity
  );
  const religionOptions = religion.slice(0, religion.length - 1);

  useEffect(() => {
    onValidChange({
      religion: religionSelected,
      religionActivity: activitySelected,
    });
  }, [religionSelected, activitySelected]);

  const checkOptions = (o) => {
    setReligionSelected(o);
  };

  const checkActivity = (a) => {
    setActivitySelected(a);
  };

  const selectedReligion = religionOptions.indexOf(religionSelected);
  const selectedActivity = activityOptions.indexOf(activitySelected);

  return (
    <div className="searchPrefForm">
      <div className="searchPrefLookingForFormContainer">
        <FormHeader text="What are you looking for?" />
        <p className="purpleText">What’s your current religion, if any?</p>
        <ButtonGroup
          selected={selectedReligion}
          type="radio"
          data={religionOptions}
          onButtonClick={checkOptions}
        />

        <p className="purpleText">How serious are you about it?</p>
        <ButtonGroup
          selected={selectedActivity}
          type="radio"
          data={activityOptions}
          onButtonClick={checkActivity}
        />
      </div>
    </div>
  );
};

export default ReligionForm;
