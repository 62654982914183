import React from "react";
import gearActive from "../../resources/images/appAssets/gear.svg";
import gearInactive from "../../resources/images/appAssets/gear_inactive.svg";
import ProfileMenu from "../common/ModamilyAppProfileMenu";
import UpgradeModal from "../modals/ModamilyAppUpgrade";

/**
 * @property {String} username - the username for the label
 */
export default class ModamilyAppProfileButton extends React.Component {
	state = {
		active: false,
		displayUpgrade: false
	};

	openModal = () => {
		this.setState({ displayUpgrade: true });
	};

	closeModal = () => {
		this.setState({ displayUpgrade: false });
	};

	toggleMenu = () => {
		if (!this.state.displayUpgrade) {
			let active = !this.state.active;
			this.setState({ active });
		}
	};

	onClick = evt => {
		this.toggleMenu();
	};

	closeMenu = () => {
		this.setState({ active: false });
	};

	clickHandler = e => {
		let className = e.target.className;
		if (className != "profileMenu profileMenuActive") {
			this.closeMenu();
		}
	};

	render() {
		let { username } = this.props;

		return (
			<div className="profileButtonContainer" onClick={this.onClick}>
				<div className="profileNameContainer">
					<p className="profileNameText purpleText regularWeight">{username}</p>
				</div>
				<div className="profileButtonIconContainer">
					<img
						className="profileButtonIcon"
						src={this.state.active ? gearActive : gearInactive}
					/>
				</div>
				<ProfileMenu active={this.state.active} openModal={this.openModal} />
				<UpgradeModal
					closeModal={this.closeModal}
					active={this.state.displayUpgrade}
				/>
			</div>
		);
	}
}
