import React, { Component } from "react";
import ModamilyAppButton from "../common/ModamilyAppButton";
import { Redirect } from "react-router-dom";

class ModamilyAppSignUpHome extends Component {
	state = {
		redirect: false,
		route: ""
	};

	redirectRegister = () => {
		this.setState({ redirect: true, route: "/register" });
	};

	downloadApp = () => {
		if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
			window.open("https://www.apple.com/ios/app-store/", "_blank");
		} else if (/Android/i.test(navigator.userAgent)) {
			window.open(
				"https://play.google.com/store?utm_source=na_Med&utm_medium=hasem&utm_content=Mar0519&utm_campaign=Evergreen&pcampaignid=MKT-DR-na-us-1000189-Med-hasem-py-Evergreen-Mar0519-Text_Search_BKWS-id_100566_%7cEXA%7cONSEM_kwid_43700023142506977&gclid=Cj0KCQjwtr_mBRDeARIsALfBZA560JMlKsrF5WlLvA0CDbIm2PFp60SQTLhZHZV0vN81wliNECY4yb8aAiYyEALw_wcB&gclsrc=aw.ds",
				"_blank"
			);
		} else {
			window.open(
				"https://play.google.com/store?utm_source=na_Med&utm_medium=hasem&utm_content=Mar0519&utm_campaign=Evergreen&pcampaignid=MKT-DR-na-us-1000189-Med-hasem-py-Evergreen-Mar0519-Text_Search_BKWS-id_100566_%7cEXA%7cONSEM_kwid_43700023142506977&gclid=Cj0KCQjwtr_mBRDeARIsALfBZA560JMlKsrF5WlLvA0CDbIm2PFp60SQTLhZHZV0vN81wliNECY4yb8aAiYyEALw_wcB&gclsrc=aw.ds",
				"_blank"
			);
		}
	};

	render() {
		if (this.state.redirect) {
			return <Redirect to={this.state.route} />;
		}

		return (
			<div className="signPage">
				<div className="signInfo">
					<div className="signContentContainer">
						<p className="signTitle">Modamily reimagines relationships.</p>
						<p className="signSubtitle">
							Modamily empowers our members by connecting them to a global
							community who are all ready to have kids, whether it be in a
							romantic, co-parenting, or known donor relationship. Welcome to
							Family 2.0.
						</p>
						<div className="signPgBtnContainer">
							{/* <div className="signPgBtn">
								<ModamilyAppButton
									text="Sign Up Today"
									style="orangeGradient"
									isStandard={true}
									onClick={this.redirectRegister}
								/>
							</div> */}
							<div className="signPgBtn">
								<ModamilyAppButton
									text="Comming soon!"
									style="white"
									isStandard={true}
									// onClick={this.downloadApp}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="signImage">
					<img
						className="iPhoneImg"
						src={require("../../resources/images/appAssets/iPhone@3x.png")}
						alt=""
					/>
				</div>
			</div>
		);
	}
}
export default ModamilyAppSignUpHome;
