import React from 'react';

//form
import FormHeader from "../../../form/ModamilyAppFormHeader";
import MultiSliderInput from "../../../form/ModamilyAppMultiSliderInput";

const HeightRange = ({searchPreferences, handleUpdate}) => (
  <div className="searchPrefForm">
		<div className="searchPrefHeightRangeFormContainer">
			<FormHeader text="Height Range" />
			<div className="heightRangeSliderContainer">
				{/* TODO support centimeters */}
				<MultiSliderInput
					type={"height"}
					units={"inches"}
					min={48}
					max={84}
					data={[
						searchPreferences.minHeight || 54,
						searchPreferences.maxHeight || 84
					]}
					step={1}
					onChange={values => {
						searchPreferences.minHeight = values[0];
						searchPreferences.maxHeight = values[1];
						handleUpdate(searchPreferences);
					}}
				/>
			</div>
		</div>
	</div>
)

export default HeightRange;