/* eslint-disable default-case */
import React, { Component } from "react";
import ModamilyAppButton from "../components/common/ModamilyAppButton";
import { Redirect } from "react-router-dom";
import ModamilyAppNavBar from "../components/common/ModamilyAppNavBar";
import ModamilyAppFooter from "../components/common/ModamilyAppFooter";

//import onboarding components
import ModamilyAppName from "../components/onboarding/ModamilyAppNameForm";
import ModamilyAppBirth from "../components/onboarding/ModamilyAppBirthForm";
import ModamilyAppGender from "../components/onboarding/ModamilyAppGenderForm";
import ModamilyAppSexualOrientation from "../components/onboarding/ModamilyAppSexualOrientation";
import ModamilyAppHomeTown from "../components/onboarding/ModamilyAppHometown";
import ModamilyAppCurrentLocation from "../components/onboarding/ModamilyAppCurrentLocation";
import ModamilyAppLookingFor from "../components/onboarding/ModamilyAppLookingFor";
import ModamilyAppWork from "../components/onboarding/ModamilyAppWork";
import ModamilyAppEducation from "../components/onboarding/ModamilyAppEducation";
import ModamilyAppIncome from "../components/onboarding/ModamilyAppIncome";
import ModamilyAppReligion from "../components/onboarding/ModamilyAppReligion";
import ModamilyAppPolitics from "../components/onboarding/ModamilyAppPolitics";
import ModamilyAppHeight from "../components/onboarding/ModamilyAppHeight";
import ModamilyAppEyeColor from "../components/onboarding/ModamilyAppEyeColor";
import ModamilyAppNaturalHair from "../components/onboarding/ModamilyAppNaturalHair";
import ModamilyAppRace from "../components/onboarding/ModamilyAppRace";
import ModamilyAppEthnicity from "../components/onboarding/ModamilyAppEthnicity";
import ModamilyAppProfilePhotos from "../components/onboarding/ModamilyAppProfilePhotos";
import ModamilyAppConversationStarters from "../components/onboarding/ModamilyAppConversationStarters";
import ModamilyAppCoparentRanks from "../components/onboarding/ModamilyAppCoparentRanks";
import ModamilyAppOnboardingCongrats from "../components/onboarding/ModamilyAppOnboardingCongrats";
import ModamilyAppHaveABaby from "../components/onboarding/ModamilyAppHaveABaby";
import ModamilyAppFertilizationMethods from "../components/onboarding/ModamilyAppFertilizationMethods";

import Queries from "../apollo/queries/queries";
import Mutations from "../apollo/mutations/mutations";
import decode from "jwt-decode";
import { withApollo } from "react-apollo";
import ModamilyAppLoadingComponent from "../components/common/ModamilyAppLoadingComponent";
import ModamilyOnboardingFooterText from "../components/common/ModamilyOnboardingFooterText";

class ModamilyAppOnboarding extends Component {
  state = {
    step: 0,
    user: {},
    data: {},
    loading: true,
    fireMutation: false,
    disableButton: true,
    getOnboardingStatus: true,
    scrollToBottom: false,
    startingStep: 0,
    footerActive: true,
    hasCoParentOption: false,
    hasReligionOption: false,
    hasEducationLevel: false,
  };

  componentDidMount = async () => {
    const { client } = this.props;
    const response = await client.query({
      query: Queries.getOnboardingStatus,
    });

    const onboarding = decode(response.data.getOnboardingStatus);
    const user = onboarding.data.user;
    const screen = this.handleGetStatusSuccess(onboarding.data);

    if (user) {
      this.setState(
        {
          user,
          data: {
            firstName: user.firstName,
            lastName: user.lastName,
          },
          step: screen,
        },
        this.checkForSocialMediaAutoFill
      );
    }
  };

  checkForSocialMediaAutoFill = () => {
    let facebookToken = localStorage.getItem("social.facebook.token");
    if (facebookToken) {
      let response = JSON.parse(
        localStorage.getItem("social.facebook.signInResponse")
      );

      let data = this.state.data;
      let keys = Object.keys(response);

      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        switch (key) {
          case "name":
            let arr = response[key].split(" ");

            let fName = arr[0];
            let lName = arr[arr.length - 1];

            data.firstName = fName;
            data.lastName = lName;

            break;
          case "gender":
            data.gender = response[key];

            break;
        }
      }
    }
  };

  createErrorAlert = (message) => {
    alert(message);
  };

  hideFooter = (status) => {
    // console.log(status, "updateing footer status");
    this.setState({ footerActive: status });
  };

  handleGetStatusSuccess = (data) => {
    let keys = Object.keys(data);
    let screen = -1;
    // for (let index = 0; index < keys.length; index++) {
    //   const key = keys[index];

    //   if (key !== "user" && !data[key]) {
    //     switch (key) {
    //       case "hasUserInfo":
    //       case "hasProfileInfo":
    //         screen = data.user.onboardingStep || 0;
    //         break;
    //       case "hasProfileImage":
    //         screen = data.user.onboardingStep || 0;
    //         // screen = 17;
    //         break;
    //       case "hasConversationStarters":
    //         screen = data.user.onboardingStep || 0;
    //         // screen = 18;
    //         break;
    //       case "hasCoparentRanks":
    //         screen = data.user.onboardingStep || 0;
    //         // screen = 19;
    //         break;
    //     }
    //     break;
    //   }
    // }

    if (data.user.onboardingStatus !== 2) {
      return data.user.onboardingStep;
    } else {
      return screen;
    }
  };

  skipFullProfile = () => {
    this.setState(
      {
        data: {
          ...this.state.data,
          skipFullProfile: true,
        },
      },
      () => {
        // console.info(this.state.step);
        this.nextStep();
      }
    );
  };

  handleGetStatusError = (error) => {
    console.log(error);
    return null;
  };

  handleCreateProfileSuccess = (data) => {
    return null;
  };

  previousStep = () => {
    const step = this.state.step - 1;
    // console.info("INFO PREVIOUS", step);
    this.setState({ step, disableButton: false });
    // debugger;
  };

  nextStep = async () => {
    let step = this.state.step;
    let lastStep = this.state.step;
    let data = this.state.data;
    step++;
    let mutation = null;
    let fireMutation = false;
    let mutationVariables = null;
    const { client } = this.props;
    let user = this.state.user;
    let endFlow = false;

    let formattedCountry;
    if(Array.isArray(data.country)) {
      formattedCountry = data.country.join(", ");
    } else {
      formattedCountry = data.country;
    }

    switch (step) {
      case 1:
        await client
          .mutate({
            mutation: Mutations.updateUser,
            variables: {
              firstName: data.firstName,
              onboardingStep: step,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateUser);
            // console.log("first", p);
            user = {
              ...this.state.user,
              firstName: data.firstName,
            };
          });

        break;
      case 2:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              dob: data.dob,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            // console.log("dob", p);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 3:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              gender: data.gender.toLowerCase(),
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 4:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              lookingFor: data.lookingFor,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 5:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              meetGender: data.orientation,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 6:
        const images = [];
        data.images.forEach((i) => {
          if (i.base64) {
            images.push({ base64: i.base64, uri: i.name });
          }
        });

        if (images.length) {
          await client.mutate({
            mutation: Mutations.uploadImages,
            variables: {
              images,
            },
          });
        }
        break;
      case 7:
        await client.mutate({
          mutation: Mutations.updateUser,
          variables: {
            // If skipfullProfile === true, set to 1 (completed basics)
            // If the user has his country at least we can see browse.
            // We should create default prefs at this stage.
            onboardingStatus: data.skipFullProfile ? 1 : 0,
          },
        });

        user = {
          ...user,
          onboardingStatus: data.skipFullProfile ? 1 : 0,
        };
        break;
      case 8:
        await client.mutate({
          mutation: Mutations.updateUser,
          variables: {
            country: formattedCountry,
            zipcode: data.zipcode,
            skipFullProfile: user.onboardingStatus === 1,
          },
        });

        user = {
          ...user,
          country: formattedCountry,
          zipcode: data.zipcode,
        };

        if (user.onboardingStatus === 1) {
          step = -1;
        }

        break;
      case 9:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              haveAbaby: data.haveAbaby,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 10:
        // Hometown
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              hometown: data.hometown,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 11:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              income: data.income,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 12:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              eyeColor: data.eyeColor,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 13:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              hairColor: data.hairColor,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 14:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              race: data.race,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 15:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              ethnicity: data.ethnicity,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 16:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              occupationTitle: data.occupationTitle,
              occupationWorkplace: data.occupationWorkplace,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 17:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              education: data.educationLevel,
              school: data.school,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 18:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              religion: data.religion,
              religionActivity: data.religionActivity,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 19:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              politics: data.politics,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 20:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              height: data.height,
              heightIsMetric: data.isMetric,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };
          });

        break;
      case 21:
        await client
          .mutate({
            mutation: Mutations.createConversationStarters,
            variables: {
              list: JSON.stringify(data.conversationStarters),
            },
          })
          .then((c) => {
            const convo = decode(c.data.createConversationStarters);

            user = {
              ...this.state.user,
              userConversationStarters: convo.data.conversationStarters,
            };
          });

        break;
      case 22:
        await client
          .mutate({
            mutation: Mutations.createCoparentRanks,
            variables: {
              qualities: data.coparentRanks.join("."),
            },
          })
          .then((c) => {
            const qualities = decode(c.data.createCoParentRanks);

            user = {
              ...this.state.user,
              userCoparentRank: qualities.data.qualities,
            };
          });
        break;
      case 23:
        await client
          .mutate({
            mutation: Mutations.updateProfileSettings,
            variables: {
              fertilization: data.fertilization,
            },
          })
          .then((c) => {
            const p = decode(c.data.updateProfileSettings);
            user = {
              ...this.state.user,
              profile: p.data,
            };

            endFlow = true;
          });

        break;
    }

    let variablesFlow = {
      onboardingStep: step,
    };
    if (endFlow) {
      variablesFlow.onboardingStatus = 2;
    }

    await client.mutate({
      mutation: Mutations.updateUser,
      variables: {
        ...variablesFlow,
      },
    });

    this.setState({
      user,
      step: endFlow ? -1 : step,
      disableButton: true,
      lastStep,
    });
  };

  invalidate = () => {
    this.setState({ disableButton: true });
  };

  validateStep = (data) => {
    // console.info("====", data);

    this.setState({
      data: {
        ...data,
      },
      disableButton: false,
      scrollToBottom: false,
    });
  };

  scrollToBottom = () => {
    this.setState({ scrollToBottom: true });
  };

  handleHasCoparentOptions = (opt) => {
    this.setState({ hasCoParentOption: opt });
  };

  handleHasReligionOption = (opt) => {
    this.setState({ hasReligionOption: opt });
  };

  handleEducationLevel = (boolean) => {
    this.setState({ hasEducationLevel: boolean });
    if (boolean && !this.state.data.school) {
      this.invalidate();
    }
  };

  render() {
    let {
      step,
      data,
      disableButton,
      errorState,
      lastStep,
      user,
      hasCoParentOption,
      hasEducationLevel,
    } = this.state;
    let stateData = data;
    // console.log("step", step);
    // console.log(user, user.profile?.profileEthnicities);

    if (errorState) {
      if (lastStep === 17) {
        // Last Step was image upload step
        this.createErrorAlert(
          "Error uploading image, try uploading samller image"
        );
        stateData = { ...stateData, images: [] };
      }
      this.setState({
        step: lastStep,
        lastStep: lastStep - 1,
        errorState: null,
        data: stateData,
      });
    }

    const children = {
      "-1": <Redirect to={"/browse"}></Redirect>,
      0: (
        <ModamilyAppName
          data={{
            firstName: user.firstName,
            lastName: user.lastName,
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
        />
      ),
      1: (
        <ModamilyAppBirth
          data={{ dob: user.profile?.dob }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
        />
      ),
      2: (
        <ModamilyAppGender
          data={{
            gender: user.profile?.gender,
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
        />
      ),
      3: (
        <ModamilyAppLookingFor
          data={{
            lookingFor:
              user.profile?.profileLookingFors?.map((l) => l.lookingFor) || [],
            gender: user.profile?.gender,
          }}
          hasCoParentOption={this.state.hasCoParentOption}
          handleHasCoparentOptions={this.handleHasCoparentOptions}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
          nextStep={this.nextStep}
        />
      ),
      4: (
        <ModamilyAppSexualOrientation
          data={{
            orientation:
              user.profile?.searchFilters?.searchFiltersGenders[0]?.gender ||
              "",
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
        />
      ),
      5: (
        <ModamilyAppProfilePhotos
          data={{
            images: user.userProfileImages || [],
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
          scrollToBottom={this.scrollToBottom}
        />
      ),
      6: (
        <ModamilyAppOnboardingCongrats
          skipFullProfile={this.skipFullProfile}
          nextStep={this.nextStep}
        />
      ),
      7: (
        <ModamilyAppCurrentLocation
          data={{
            country: user.country,
            zipcode: user.zipcode,
            latLong: stateData.latLong,
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
        />
      ),
      8: (
        <ModamilyAppHaveABaby
          data={{
            haveAbaby: user.profile?.haveAbaby || "",
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
        />
      ),
      9: (
        <ModamilyAppHomeTown
          data={{
            hometown: user.profile?.hometown,
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
        />
      ),
      10: (
        <ModamilyAppIncome
          data={{
            income: user.profile?.income || 100000,
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
        />
      ),
      11: (
        <ModamilyAppEyeColor
          data={{
            eyeColor: user.profile?.eyeColor,
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
          scrollToBottom={this.scrollToBottom}
        />
      ),
      12: (
        <ModamilyAppNaturalHair
          data={{
            hairColor: user.profile?.hairColor,
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
          scrollToBottom={this.scrollToBottom}
        />
      ),
      13: (
        <ModamilyAppRace
          data={{ race: user.profile?.race }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
          scrollToBottom={this.scrollToBottom}
        />
      ),
      14: (
        <ModamilyAppEthnicity
          data={{
            ethnicity:
              user.profile?.profileEthnicities?.map((e) => e.ethnicity) || [],
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
          scrollToBottom={this.scrollToBottom}
        />
      ),
      15: (
        <ModamilyAppWork
          data={{
            occupationTitle: user.profile?.occupationTitle,
            occupationWorkplace: user.profile?.occupationWorkplace,
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
        />
      ),
      16: (
        <ModamilyAppEducation
          data={{
            educationLevel: user.profile?.education,
            school: user.profile?.school,
          }}
          handleEducationLevel={this.handleEducationLevel}
          hasEducationLevel={hasEducationLevel}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
        />
      ),
      17: (
        <ModamilyAppReligion
          data={{
            religion: user.profile?.religion || "",
            religionActivity: user.profile?.religionActivity || "",
          }}
          hasReligionOption={this.state.hasReligionOption}
          handleHasReligionOption={this.handleHasReligionOption}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
          scrollToBottom={this.scrollToBottom}
        />
      ),
      18: (
        <ModamilyAppPolitics
          data={{
            politics: user.profile?.politics,
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
          scrollToBottom={this.scrollToBottom}
        />
      ),

      19: (
        <ModamilyAppHeight
          data={{
            height: user.profile?.height,
            isMetric: user.profile?.heightIsMetric,
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
          scrollToBottom={this.scrollToBottom}
        />
      ),
      20: (
        <ModamilyAppConversationStarters
          data={{
            conversationStarters: user?.userConversationStarters || [],
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
          scrollToBottom={this.scrollToBottom}
          hideFooter={this.hideFooter}
        />
      ),
      21: (
        <ModamilyAppCoparentRanks
          data={{
            coparentRanks: user?.userCoparentRank?.qualities.split(".") || [
              "Physical attractiveness",
              "Intelligence",
              "Creativity",
              "Financial stability / income",
              "Shared value system",
              "Same religion",
              "Health",
            ],
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
          scrollToBottom={this.scrollToBottom}
          hideFooter={this.hideFooter}
        />
      ),
      22: (
        <ModamilyAppFertilizationMethods
          data={{
            fertilization:
              user.profile?.profileFertilizations?.map(
                (f) => f.fertilization
              ) || [],
          }}
          invalidate={this.invalidate}
          onValidChange={this.validateStep}
        />
      ),
    };

    let footerClass = this.state.footerActive
      ? "onboarding"
      : "onboardingHidden";

    if (
      (step === 3 && !this.state.hasCoParentOption) ||
      (step === 16 && !this.state.hasEducationLevel)
    ) {
      footerClass = "onboardingHidden";
    }

    return (
      <div ref="onboarding" className="ModamilyAppOnboarding">
        {this.state.step !== 6 && (
          <ModamilyAppNavBar
            type="onboarding"
            step={step}
            previousStep={this.previousStep}
            nextStep={this.nextStep}
          />
        )}
        <div className="onboardingContainer">
          <div className="onboardingFormContainer">{children[step]}</div>
          {!this.state.loading && <ModamilyAppLoadingComponent />}
        </div>
        <ModamilyAppFooter type={footerClass}>
          <div className="onboardingButtonContainer">
            {step === 0 && (
              <ModamilyOnboardingFooterText text="This will be shown on your profile so make sure you use your real name." />
            )}
            {step === 1 && (
              <ModamilyOnboardingFooterText text="We only show your age to potential matches, not your actual birthday." />
            )}

            {step === 2 && (
              <ModamilyOnboardingFooterText text="This will be hidden to everyone by default, you can always change it later." />
            )}

            {step === 3 && this.state.hasCoParentOption && (
              <ModamilyOnboardingFooterText text="We will use this to find the perfect match for you. You can change it later." />
            )}

            {step === 4 && (
              <ModamilyOnboardingFooterText text="We will use this to find the perfect match for you. You can change it later." />
            )}

            {/* PHOTOS */}
            {step === 5 && <ModamilyOnboardingFooterText text="" />}

            {step >= 7 && step <= 15 && (
              <ModamilyOnboardingFooterText
                text="This will be shown on your profile.
                You can always hide or change it later."
              />
            )}

            {step === 16 && this.state.hasEducationLevel && (
              <ModamilyOnboardingFooterText
                text="This will be shown on your profile.
              You can always hide or change it later."
              />
            )}

            {step === 17 && this.state.hasRelitionOption && (
              <ModamilyOnboardingFooterText
                text="This will be shown on your profile.
              You can always hide or change it later."
              />
            )}

            {step > 17 && (
              <ModamilyOnboardingFooterText
                text="This will be shown on your profile.
                You can always hide or change it later."
              />
            )}

            <div
              className={
                step === 6 ||
                (step === 16 && !this.state.hasEducationLevel) ||
                (step === 3 && !this.state.hasCoParentOption) ||
                (step === 17 && !this.state.hasReligionOption)
                  ? "hidden"
                  : "OnboardingButtonContainer"
              }
            >
              <ModamilyAppButton
                disabled={disableButton}
                style="orangeGradient"
                text="Next"
                onClick={() => this.nextStep()}
              />
            </div>
          </div>
        </ModamilyAppFooter>
      </div>
    );
  }
}

export default withApollo(ModamilyAppOnboarding);
