import React, { Component } from "react";
import ModamilyAppLinkText from "./ModamilyAppLinkText";
import ModamilyAppSeparator from "./ModamilyAppSeparator";

class ModamilyAppSiteLinks extends Component {
	render() {
		return (
			<div className="siteLinks">
				<ModamilyAppLinkText color="white" text="SIGN UP" link="/register" />
				<ModamilyAppSeparator />
				<ModamilyAppLinkText
					newTab={true}
					color="white"
					text="Terms"
					link="/terms"
				/>
				<ModamilyAppSeparator />
				<ModamilyAppLinkText
					newTab={true}
					color="white"
					text="Privacy"
					link="/privacy"
				/>
				<ModamilyAppSeparator />
				<ModamilyAppLinkText
					newTab={true}
					color="white"
					text="About"
					link="/aboutUs"
				/>
			</div>
		);
	}
}
export default ModamilyAppSiteLinks;
