import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import wellDoneIcon from "../resources/images/appAssets/WellDoneIcon.png";

const ModamilyAppWellDone = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push("/register");
    }, 4000);
  }, [history]);

  return (
    <div className="ModamilyAppWellDone__body">
      <div className="ModamilyAppWellDone__body--image">
        <img
          src={wellDoneIcon}
          alt="Well Done Icon"
          className="welldone-icon"
        />
      </div>

      <div className="ModamilyAppWellDone__body--info">
        <h2>Well done!</h2>

        <p>Your account is now secure</p>
      </div>
    </div>
  );
};

export default ModamilyAppWellDone;
