import React from "react";
import ModamilyAppButtonGroup from "../form/ModamilyAppButtonGroup";
import { ethnicities } from "../../constants/formOptions";
import ModamilyAppTextInput from "../form/ModamilyAppTextInput";
import ModamilyAppScrollBarOptions from "../common/ModamilyAppScrollBarOptions";
import _ from "lodash";

/**
 * @property data [Object] - { ethnicity:null } - default data for the form
 * @property invalidate [Function] - Function that gets called when the form is invalid
 * @property onValidChange[Function]- Function that gets called when the form is valid
 */
export default class ModamilyAppEthnicity extends React.Component {
  state = {
    data: this.props.data || { ethnicity: [] },
    query: "",
    ethnicityOptions: ethnicities,
  };

  componentWillMount = () => {
    let data = this.state.data.ethnicity;
    if (data.length > 0) {
      this.props.onValidChange(data);
    } else {
      this.props.invalidate();
    }
  };

  selectItem = (item) => {
    let data = this.state.data.ethnicity;
    if (data.indexOf(item) > -1) {
      data.splice(data.indexOf(item), 1);
    } else {
      data.push(item);
    }

    if (data.length > 0) {
      this.props.onValidChange({ ethnicity: data });
    } else {
      this.props.invalidate();
    }
    this.setState({ data: { ethnicity: data } });
  };

  ethnicityQueryUpdate = (data) => {
    console.info(data);
    this.filterList(data);
    // this.setState({ query: data });
  };

  filterList = (query) => {
    const updateList = _.cloneDeep(ethnicities).filter((item) =>
      item.toLocaleLowerCase().includes(query.toLocaleLowerCase())
    );
    this.setState({ ethnicityOptions: updateList });
  };

  render() {
    let selectedIndexes = [];
    for (let index = 0; index < this.state.data.ethnicity?.length; index++) {
      const ethnicity = this.state.data.ethnicity[index];
      let i = this.state.ethnicityOptions.indexOf(ethnicity);
      if (i > -1) {
        selectedIndexes.push(i);
      }
    }

    return (
      <div className="ethnicityForm">
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            Would you describe yourself as…
          </h2>
        </div>
        <ModamilyAppTextInput
          onContentChange={(text) => {
            this.ethnicityQueryUpdate(text);
          }}
          reference="ethnicityInput"
          ref="ethnicityComponent"
          label="Ethnicity"
          placeholder="Search Ethnicity"
          // value={this.state.query}
        />
        <ModamilyAppScrollBarOptions>
          <ModamilyAppButtonGroup
            type="check"
            data={this.state.ethnicityOptions}
            onButtonClick={this.selectItem}
            selected={selectedIndexes}
          />
        </ModamilyAppScrollBarOptions>
      </div>
    );
  }
}
