import React, { Component } from "react";

class ModamilyAppArticleBanner extends Component {
	render() {
		return (
			<div className="articleBanner">
				<p className="articleBannerTxt">as featured in</p>
				<div className="articleContainer">
					<img
						className="articleBtn"
						src={require("../../resources/images/appAssets/logos@3x.png")}
						alt="Articles"
					/>
				</div>
			</div>
		);
	}
}
export default ModamilyAppArticleBanner;
