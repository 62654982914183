import React, { Component } from "react";
import "../src/styles/css/main.css";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContext } from "react-dnd";

class App extends Component {
  render() {
    return (
      <div className="App">
        <div id="fb-root" />
        <script
          type="text/javascript"
          src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        />
        <script
          async
          defer
          crossOrigin="anonymous"
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.3&appId=1472587806338388&autoLogAppEvents=1"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://unpkg.com/react-flexbox-grid@2.1.2/dist/react-flexbox-grid.css"
        />
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(App);
