import React from "react";
import ButtonGroup from "../form/ModamilyAppButtonGroup";
import ModamilyAppScrollBarOptions from "../common/ModamilyAppScrollBarOptions";

/**
 * @property {Object} data  - { politics: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppPolitics extends React.Component {
  state = {
    data: this.props.data || { politics: "" },
  };

  buttonOptions = [
    "apolitical",
    "liberal",
    "moderate",
    "conservative",
    "other",
    "prefer not to say",
  ];

  componentDidMount = () => {
    this.validate();
  };

  validate = () => {
    let data = this.state.data;

    if (!data.politics) {
      this.props.invalidate();
    } else {
      this.props.onValidChange(data);
    }
  };

  handleButtonClick = (value, index, isActive) => {
    let data = this.state.data;
    data.politics = isActive ? value : null;
    this.setState({ data }, this.validate);
  };

  render = () => {
    let data = this.state.data;
    let selectedIndex = -1;
    if (data.politics) {
      selectedIndex = this.buttonOptions.indexOf(data.politics.toLowerCase());
    }

    return (
      <div className="hairColorForm">
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            What political ideology do you have?
          </h2>
        </div>
        <ModamilyAppScrollBarOptions>
          <ButtonGroup
            type="radio"
            selected={selectedIndex}
            data={this.buttonOptions}
            onButtonClick={this.handleButtonClick}
          ></ButtonGroup>
        </ModamilyAppScrollBarOptions>
      </div>
    );
  };
}
