import React from "react";

import TabMenuHeader from "../../common/ModamilyAppTabMenuHeader";
import TabMenuItem from "../../common/ModamilyAppTabMenuItem";

const haveABabyOpts = {
  "1-6": "Less than 6 months",
  "6-12": "6 to 12 months",
  "+12": "Over a year from now",
  "?": "Depends on the person",
};

class SearchPreferencesMenu extends React.Component {
  onPremiumOptionClick = (index, subType) => {
    if (subType) {
      if (subType.toUpperCase() !== "FREE") {
        this.props.setView(index);
      } else {
        this.props.showModal();
      }
    } else {
      this.props.showModal();
    }
  };
  getDistanceDescription = (searchPreferences) => {
    let distanceTxt = "";
    if (searchPreferences) {
      if (searchPreferences.distance) {
        distanceTxt = searchPreferences.distance + " miles ";
      }

      if (searchPreferences.country) {
        distanceTxt += searchPreferences.distance
          ? `- ${searchPreferences.country} `
          : ` ${searchPreferences.country} `;
      }

      if (searchPreferences.zipcode) {
        distanceTxt += searchPreferences.country
          ? `- ${searchPreferences.zipcode} `
          : ` ${searchPreferences.zipcode} `;
      }
    }
    return distanceTxt;
  };

  render = () => {
    let subscription = JSON.parse(localStorage.getItem("user")).subscription;
    let searchPreferences = this.props.searchPreferences;
    console.log(searchPreferences);

    let subType = "FREE";

    if (subscription) {
      subType = subscription.subType;
    }
    return (
      <div className="searchPreferencesMenu">
        <TabMenuHeader text="BASIC SEARCH" />
        <TabMenuItem
          header="I'M LOOKING FOR"
          data={
            searchPreferences
              ? searchPreferences.gender.join(", ") +
                " - " +
                (searchPreferences.lookingFor.join(", ") || "")
              : ""
          }
          onClick={() => {
            this.props.setView(1);
          }}
        />
        <TabMenuItem
          header="DISTANCE, COUNTRY & ZIPCODE"
          data={this.getDistanceDescription(searchPreferences)}
          onClick={() => {
            this.props.setView(2);
          }}
        />
        {/* <TabMenuItem
					header="COUNTRY & ZIPCODE"
					data={searchPreferences ? searchPreferences.country : ""}
					onClick={() => {
						this.props.setView(12);
					}}
				/> */}
        <TabMenuItem
          header="AGE RANGE"
          data={
            searchPreferences
              ? searchPreferences.minAge + "-" + searchPreferences.maxAge
              : ""
          }
          onClick={() => {
            this.props.setView(3);
          }}
        />

        <TabMenuHeader text="PREMIUM SEARCH" />
        <div className="premiumSearchOptions">
          {subType.toUpperCase() === "FREE" ? (
            <div className="overlayPremium">
              <div className="upgradeCard">
                <div
                  className="upgradeCardContent"
                  onClick={this.props.showModal}
                >
                  <p className="upgradeCardHeader">Want more search options?</p>
                  <p>
                    <u>Upgrade Account</u>
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          <TabMenuItem
            header="HAVE A BABY"
            data={
              searchPreferences && searchPreferences.haveABaby
                ? haveABabyOpts[searchPreferences.haveABaby]
                : "none"
            }
            onClick={() => {
              this.onPremiumOptionClick(12, subType);
            }}
          />
          <TabMenuItem
            header="HEIGHT"
            data={
              searchPreferences
                ? searchPreferences.minHeight
                  ? searchPreferences.minHeight +
                    "-" +
                    searchPreferences.maxHeight
                  : "none"
                : ""
            }
            onClick={() => {
              this.onPremiumOptionClick(4, subType);
            }}
          />
          <TabMenuItem
            header="EDUCATION LEVEL"
            data={
              searchPreferences
                ? searchPreferences.education[0]
                  ? searchPreferences.education.join(", ")
                  : "none"
                : ""
            }
            onClick={() => {
              this.onPremiumOptionClick(5, subType);
            }}
          />
          <TabMenuItem
            header="INCOME"
            data={
              searchPreferences
                ? searchPreferences.minIncome
                  ? "$" +
                    searchPreferences.minIncome +
                    " - " +
                    "$" +
                    searchPreferences.maxIncome
                  : "none"
                : "none"
            }
            onClick={() => {
              this.onPremiumOptionClick(6, subType);
            }}
          />
          <TabMenuItem
            header="RELIGION"
            data={
              searchPreferences
                ? searchPreferences.religion[0]
                  ? searchPreferences.religion.join(", ")
                  : "none"
                : "none"
            }
            onClick={() => {
              this.onPremiumOptionClick(7, subType);
            }}
          />
          <TabMenuItem
            header="POLITICS"
            data={
              searchPreferences
                ? searchPreferences.politics[0]
                  ? searchPreferences.politics.join(", ")
                  : "none"
                : "none"
            }
            onClick={() => {
              this.onPremiumOptionClick(11, subType);
            }}
          />
          <TabMenuItem
            header="EYE COLOR"
            data={
              searchPreferences
                ? searchPreferences.eyeColor[0]
                  ? searchPreferences.eyeColor.join(", ")
                  : "none"
                : "none"
            }
            onClick={() => {
              this.onPremiumOptionClick(9, subType);
            }}
          />
          <TabMenuItem
            header="ETHNICITY"
            data={
              searchPreferences
                ? searchPreferences.ethnicity[0]
                  ? searchPreferences.ethnicity.join(", ")
                  : "none"
                : "none"
            }
            onClick={() => {
              this.onPremiumOptionClick(10, subType);
            }}
          />
          <TabMenuItem
            header="RACE"
            data={
              searchPreferences
                ? searchPreferences.race[0]
                  ? searchPreferences.race.join(", ")
                  : "none"
                : "none"
            }
            onClick={() => {
              this.onPremiumOptionClick(8, subType);
            }}
          />
        </div>
      </div>
    );
  };
}

export default SearchPreferencesMenu;
