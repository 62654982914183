import React, { Component } from "react";
import ModamilyAppButton from "../common/ModamilyAppButton";

class ModamilyAppConciergeBox extends Component {
  openEmail = () => {
    window.open(
      "mailto:concierge@modamily.com?subject=Modamily Concierge",
      "_newtab"
    );
  };

  render() {
    return (
      <div className="conciergeBox">
        <img
          className="conciergePhoto"
          src={require("../../resources/images/appAssets/concierge@3x.png")}
          alt=""
        />
        <div className="conciergeInfoBox">
          <p className="conciergeTitle">Join Modamily Concierge</p>
          <p className="conciergeInfo">
            Take the stress and time-consuming nature out of your online search
            and let our Concierge Group recommend and vet high quality potential
            co-parents, sperm/egg donors, or surrogates.
          </p>
          <div className="conciergeBtnContainer">
            <ModamilyAppButton
              text="Learn more"
              style="orangeGradient"
              onClick={this.openEmail}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default ModamilyAppConciergeBox;
