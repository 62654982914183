import { takeEvery, fork, put } from 'redux-saga/effects';
import * as actions from '../actions/searchPreferences';

function* updateSearchPreferences({ payload: { searchPreferences} }) {
  try {
    if(searchPreferences) {
      yield put(actions.updateSearchPreferencesSuccess(searchPreferences))
    } else {
      throw Error('searchPreferences data doesn\' exist!');
    }
  } catch(e) {
    console.log('error updating search preferences', e);
  }
}

function* watchUpdateSearchPreferencesRequest() {
  yield takeEvery(actions.Types.UPDATE_SEARCH_PREFERENCES_REQUEST, updateSearchPreferences);
}


function* updateFetchProfiles({ payload: { shouldFetchProfiles } }) {
  try {
    yield put(actions.updateFetchProfilesSuccess(!!shouldFetchProfiles))
  } catch(e) {
    console.log('error updating shouldFetchProfiles', e);
  }
}

function* watchUpdateFetchProfilesRequest() {
  yield takeEvery(actions.Types.UPDATE_FETCH_PROFILES_REQUEST, updateFetchProfiles);
}

const searchPreferencesSagas = [
  fork(watchUpdateSearchPreferencesRequest),
  fork(watchUpdateFetchProfilesRequest)
];

export default searchPreferencesSagas;
