import React from "react";
import ModamilyAppTextInput from "../form/ModamilyAppTextInput";
import ModamilyAppFormHeader from "../form/ModamilyAppFormHeader";
// import ModamilyAppDropDownMenu from "../form/ModamilyAppDropDownMenu";
// import countries from "../../constants/countries";

/**
 * @property {Object} data  - { hometownCountry: null, hometownZipcode: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppHometownLocationForm extends React.Component {
  state = {
    data: this.props.data || { hometown: null },
  };

  componentDidMount = () => {
    let data = this.state.data;

    if (!data.hometown) {
      this.props.invalidate();
    } else {
      this.props.onValidChange(data);
    }
  };
  onEnterPress = (evt) => {
    let data = this.state.data;

    if (!data.hometown) {
      this.props.invalidate();
    } else {
      this.props.onValidChange(data);
    }
  };

  updateName = (key, text) => {
    let stateData = this.state.data;

    if (!text) {
      this.props.invalidate();
    }

    stateData[key] = text;

    let state = this.state;

    state.data = stateData;

    this.setState(state, () => {
      this.onEnterPress();
    });
  };

  onCountrySelect = (value) => {
    let data = this.state.data;
    data.hometown = value;
    this.setState({ data });
  };

  render() {
    return (
      <div className="nameForm">
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            Where did you grow up?
          </h2>
        </div>
        <div className="zipcodeInputContainer">
          <ModamilyAppTextInput
            onEnterPress={this.onEnterPress}
            value={this.state.data.hometown}
            onContentChange={(text) => {
              this.updateName("hometown", text);
            }}
            reference="zipcodeInput"
            ref="zipcodeComponent"
            label="i.e. Los Angeles, CA"
          />
          <div className="ModamilyAppOnboarding__work--role">
            <p>i.e. Los Angeles, CA</p>
          </div>
        </div>
      </div>
    );
  }
}
