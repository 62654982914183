import axios from "axios";
import { backendUri } from "../config/config";

export const apiClient = axios.create({
  baseURL: backendUri + "/api",
  withCredentials: true,
  headers: {
    authorization: localStorage.getItem("auth.token")
      ? `Bearer ${localStorage.getItem("auth.token")}`
      : "",
  },
});
