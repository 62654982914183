import React from "react";
import ModamilyAppRadioButton from "../form/ModamilyAppRadioButton";
import ModamilyAppCheckButton from "../form/ModamilyAppCheckButton";

/**
 * @property {Integer} selected - the index that corresponds to the selected value in the data array
 * @property {Array(String)} data - the array of data
 * @property {String} type - "check" || "radio"
 * @property {Function(value, index)} onButtonClick - function run when a button is clicked the function takes in the clicked value and its index in the array. If type is radio                                                     the the function also recieves a third arg named isActive determining if the button was clicked active
 */
export default class ModamilyAppButtonGroup extends React.Component {
  constructor(props) {
    super(props);

    let selectedType =
      this.props.type === "check"
        ? this.props.selected || []
        : this.props.selected;

    this.state = {
      selected: selectedType,
      data: this.props.data,
      type: this.props.type ? this.props.type : "check",
    };
    // console.log(this.state, this.props.selected);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.manualSelection) {
      if (nextProps.customSelected) {
        return { selected: nextProps.customSelected };
      }
    } else {
      const selectedType =
        nextProps.type === "check"
          ? nextProps.selected || []
          : nextProps.selected;

      return {
        selected: selectedType,
        data: nextProps.data,
      };
    }
  }

  onButtonClick = (value, index, isActive) => {
    let selected = this.state.selected;

    switch (this.state.type) {
      case "radio":
        selected = index == this.state.selected && !isActive ? -1 : index;
        this.setState({ selected }, () => {
          if (this.props.onButtonClick) {
            this.props.onButtonClick(value, index, isActive);
          }
        });
        break;
      case "check":
        if (isActive) {
          selected.push(index);
        } else {
          selected = selected.filter((s) => s !== index);
        }
        selected = Array.from(new Set(selected)); //unique

        this.setState({ selected }, () => {
          if (this.props.onButtonClick) {
            this.props.onButtonClick(value, isActive ? index : -1, selected);
          }
        });
        break;
    }
  };

  render() {
    let { selected, data, type } = this.state;
    let btnComponentArr = [];

    for (let index = 0; index < data.length; index++) {
      const dataPoint = data[index];
      switch (type) {
        case "radio":
          btnComponentArr.push(
            <ModamilyAppRadioButton
              text={dataPoint}
              key={index}
              active={selected == index}
              onClick={(isActive) => {
                this.onButtonClick(dataPoint, index, isActive);
              }}
            />
          );
          break;
        case "check":
          btnComponentArr.push(
            <ModamilyAppCheckButton
              text={dataPoint}
              key={index}
              active={selected.indexOf(index) != -1}
              onClick={(isActive) => {
                this.onButtonClick(dataPoint, index, isActive);
              }}
              manualSelection={this.props.manualSelection}
            />
          );

          break;
      }
    }
    return <div className="buttonGroupContainer">{btnComponentArr}</div>;
  }
}
