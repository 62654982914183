import React from "react";

import PageContainer from "../components/common/ModamilyPageContainer";
import NavBar from "../components/common/ModamilyAppNavBar";
import Footer from "../components/common/ModamilyAppFooter";
import Button from "../components/common/ModamilyAppButton";
import TextInput from "../components/form/ModamilyAppTextInput";
import FormHeader from "../components/form/ModamilyAppFormHeader";

import { Mutation } from "react-apollo";
import Mutations from "../apollo/mutations/mutations";
import LoadingComponent from "../components/common/ModamilyAppLoadingComponent";
import decode from "jwt-decode";
import { Redirect } from "react-router-dom";

import lockIcon from "../resources/images/appAssets/lock.png";

export default class ModamilyAppResetPassword extends React.Component {
  state = {
    password: null,
    confPassword: null,
    valid: false,
  };

  validate = () => {
    if (this.state.password) {
      if (this.state.confPassword) {
        if (this.state.confPassword == this.state.password) {
          this.setState({ valid: true });
        } else {
          this.setState({ valid: false });
        }
      } else {
        this.setState({ valid: false });
      }
    } else {
      this.setState({ valid: false });
    }
  };

  updateField = (key, value) => {
    let state = this.state;
    state[key] = value;
    // console.log(state, "update state");
    this.setState(state, () => this.validate());
  };

  handleResponse = (data) => {
    console.log(data);
    let response = decode(data.forgotPW);
    if (response.action === "success") {
      return <Redirect to="/login" />;
    } else {
      window.alert("Error please try again later");
    }
  };

  render = () => {
    const params = this.props.match.params;

    let hash = params.hash;

    return (
      <Mutation mutation={Mutations.resetPassword}>
        {(mutation, { loading, error, data }) => {
          if (loading) {
            return <LoadingComponent />;
          } else if (data) {
            return this.handleResponse(data);
          } else if (error) {
            console.log(error);
            window.alert("Error please try again later!");
          }

          return (
            <div>
              <NavBar type="onboarding" />
              <PageContainer>
                <div className="resetPasswordPage">
                  <FormHeader text="Reset Password" />
                  <p className="purpleText lightWeight">
                    Password must be at least 8 characters long, contain an
                    uppercase letter, a number and a special character
                  </p>
                  <TextInput
                    type="password"
                    label="Password"
                    reference="passSign"
                    ref="passInput"
                    icon={lockIcon}
                    value={this.state.password}
                    validate={true}
                    onContentChange={(data, valid) => {
                      this.updateField("password", data ? data : null);
                    }}
                    onEnterPress={this.onEnterPress}
                  />
                  <TextInput
                    type="password"
                    label="Confirm password"
                    reference="confPassSign"
                    ref="confPassInput"
                    icon={lockIcon}
                    value={this.state.confPassword}
                    validate={true}
                    onContentChange={(data, valid) => {
                      this.updateField("confPassword", data ? data : null);
                    }}
                    onEnterPress={this.onEnterPress}
                  />
                </div>
              </PageContainer>
              <Footer type="onboarding">
                <div className="resetPasswordButtonContainer">
                  <Button
                    text="Reset"
                    style="red"
                    disabled={!this.state.valid}
                    onClick={(e) => {
                      let vars = {
                        hash,
                        newPW: this.state.password,
                      };

                      mutation({ variables: vars });
                    }}
                  />
                </div>
              </Footer>
            </div>
          );
        }}
      </Mutation>
    );
  };
}
