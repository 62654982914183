import React, { Component } from "react";
import moment from "moment";

import ModamilyAppNavBar from "../components/common/ModamilyAppNavBar";
import ModamilyAppLoadingComponent from "../components/common/ModamilyAppLoadingComponent";
import ModamilyAppRoundImage from "../components/common/ModamilyAppRoundImage";
import DropDownMenu from "../components/form/ModamilyAppDropDownMenu";
import Badge from "../components/common/ModamilyAppBadge";
import TextInput from "../components/form/ModamilyAppTextInput";

import searchImg from "../resources/images/appAssets/search.png";

import MessageModal from "../components/modals/ModamilyAppMessageChat";
import MessageModalSocketIO from "../components/modals/ModamilyAppMessageChatSocketIo";

import UpgradeModal from "../components/modals/ModamilyAppUpgrade";

import Queries from "../apollo/queries/queries";
import { Query } from "react-apollo";
import decode from "jwt-decode";
import { USE_SOCKET_IO_CHAT_SYSTEM } from "../config/config";
import { ThreeDots } from "react-loading-icons";

class ModamilyAppMessages extends Component {
  state = {
    user: JSON.parse(localStorage.getItem("user")),
    displayMessageModal: false,
    selectedSort: "Unread",
    displayUpgrade: false,
    selectedUser: null,
    searchString: "",
    refetch: false,
  };

  sortingOptions = ["Unread", "Recent", "Archived"];

  selectedUser;

  /**
   * This displays all the matches that a user may have that they have not yet messaged
   */
  viewAllMatches = () => {
    this.setState({ viewAll: !this.state.viewAll });
  };

  /**
   * Sort the conversations by whether the last message has been read
   */
  unreadFunction = (conversations, isArchived = false) => {
    const areUnreadMessages = conversations.some(
      (conv) =>
        conv.lastMessage &&
        conv.lastMessage.author_id !== this.state.user.id &&
        !Boolean(conv.lastMessage.read) &&
        conv.archived === isArchived
    );
    let sortedConversations = [];
    if (areUnreadMessages) {
      sortedConversations = conversations.sort(
        (conversation1, conversation2) => {
          let isConversation1Read = true;
          let isConversation2Read = true;
          if (
            conversation1.lastMessage !== null &&
            conversation1.lastMessage.author_id !== this.state.user.id &&
            Boolean(conversation1.lastMessage.read) === false
          ) {
            isConversation1Read = false;
          }
          if (
            conversation2.lastMessage !== null &&
            conversation2.lastMessage.author_id !== this.state.user.id &&
            Boolean(conversation2.lastMessage.read) === false
          ) {
            isConversation2Read = false;
          }
          return isConversation1Read === isConversation2Read
            ? 0
            : isConversation1Read
            ? 1
            : -1;
        }
      );
    } else {
      sortedConversations = this.recentFunction(conversations, isArchived);
    }

    return sortedConversations;
  };

  /**
   * Sort the conversations by when the last message was sent
   */
  recentFunction = (conversations, isArchived = false) => {
    let sortedConversations = conversations
      .filter((c) => c.archived === isArchived)
      .sort((conversation1, conversation2) => {
        let conversation1LastSent = null;
        let conversation2LastSent = null;
        if (conversation1.lastMessage) {
          conversation1LastSent =
            conversation1.lastMessage && conversation1.lastMessage.created_at
              ? conversation1.lastMessage.created_at
              : conversation1.lastMessage.createdAt;
        }

        if (conversation2.lastMessage) {
          conversation2LastSent =
            conversation2.lastMessage && conversation2.lastMessage.created_at
              ? conversation2.lastMessage.created_at
              : conversation2.lastMessage.createdAt;
        }
        return (
          moment(conversation2LastSent).unix() -
          moment(conversation1LastSent).unix()
        );
      });
    return sortedConversations;
  };

  /**
   * Sort the conversations by how close the other user is to you
   */
  nearbyFunction = (conversations) => {
    let sortedConversations = conversations.sort(
      (conversation1, conversation2) => {
        // compare/subtract distances here
      }
    );
    return sortedConversations;
  };

  /**
   * Separate Matches from Conversations based on whether or not there is a message sent
   * @param data
   */
  separateMatchesFromConversations = (data) => {
    let matches = [];
    let expiredMatches = [];
    let conversations = [];
    const archived = [];
    for (let i = 0; i < data.length; i++) {
      // if (data[i].archived) {
      //   archived.push(data[i]);
      // } else
      if ("lastMessage" in data[i] && data[i].lastMessage !== null) {
        conversations.push(data[i]);
      } else {
        // This is only for matches that you have not yet messaged
        if (this.isMatchExpired(data[i].createdAt)) {
          expiredMatches.push(data[i]);
        } else {
          let exp = this.getHoursLeftToMessage(
            this.getHoursSinceMatched(data[i].createdAt)
          );
          data[i].timeLeft = exp;
          matches.push(data[i]);
        }
      }
    }
    // archived
    return { matches, expiredMatches, conversations };
  };

  /**
   * Determines whether or not a match is expired
   * @param whenMatchCreated
   * @returns {boolean}
   */
  isMatchExpired(whenMatchCreated) {
    return this.getHoursSinceMatched(whenMatchCreated) >= 24;
  }

  /**
   * Get number of hours left to message
   * @param hoursSinceMatched
   * @returns {number}
   */
  getHoursLeftToMessage = (hoursSinceMatched) => {
    return 24 - hoursSinceMatched;
  };

  /**
   * Gets the number of hours it has been since the match was created
   * @param whenMatchCreated
   * @returns {number}
   */
  getHoursSinceMatched = (whenMatchCreated) => {
    const createdAt = new Date(whenMatchCreated);
    const currentDate = new Date();

    // 1000ms * 60sec * 60min
    const msInOneHour = 1000 * 60 * 60;
    return Math.ceil(
      (currentDate.getTime() - createdAt.getTime()) / msInOneHour
    );
  };

  searchFunction = (text, conversations) => {
    if (text !== "") {
      let searchResults = [];
      let lowerText = text.toLowerCase();
      for (let i = 0; i < conversations.length; i++) {
        let otherUserName = (
          conversations[i].otherUser.firstName +
          " " +
          conversations[i].otherUser.lastName
        ).toLowerCase();
        if (otherUserName.includes(lowerText)) {
          searchResults.push(conversations[i]);
        }
      }
      return searchResults;
    } else {
      return conversations;
    }
  };

  scrollHorizontally = (e) => {
    e = window.event || e;
    var delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
    document.getElementById("expiredMatchesRow").scrollLeft -= delta * 40; // Multiplied by 40
    e.preventDefault();
  };

  openConversation = (user) => {
    this.selectedUser = user;
    this.modalTop = window.scrollY;

    this.setState({ displayMessageModal: true, scrollDisabled: true }, () => {
      let element = document.getElementById("messageModalCurtain");

      if (element) {
        document.body.style.height = "100vh";
        document.body.style.overflow = "hidden";
        element.style.top = `${this.modalTop}`;
      }
    });
  };

  closeConversation = () => {
    this.setState({ displayMessageModal: false, scrollDisabled: false }, () => {
      document.body.style.height = "auto";
      document.body.style.overflow = "visible";
    });
    this.selectedUser = null;
  };

  openUpgrade = () => {
    this.setState({ displayUpgrade: true });
  };

  closeUpgrade = () => {
    this.setState({ displayUpgrade: false });
  };

  getChatRoundImage = (match) => {
    return (
      <ModamilyAppRoundImage
        isUrl={true}
        image={
          match.otherUserProfileImage ? match.otherUserProfileImage.path : null
        }
        name={match.otherUser.firstName}
      />
    );
  };

  isPremiumUser = (user) => {
    return (
      (user.subscription &&
        user.subscription.subType.toLowerCase() !== "free") ||
      user.subscription.storeSubscription !== ""
    );
  };

  getChatHoursBadge = (match) => {
    if (this.isPremiumUser(this.state.user)) {
      return this.getChatRoundImage(match);
    }

    return (
      <Badge type="text" text={match.timeLeft + "hr"} color="orange">
        {this.getChatRoundImage(match)}
      </Badge>
    );
  };

  renderMainComponent = (matches, expiredMatches, conversations, refetch) => {
    let matchesComponents = [];
    let expiredComponents = [];
    let conversationComponents = [];
    // const archivedComponents = [];

    if (this.isPremiumUser(this.state.user)) {
      matches = [...matches, ...expiredMatches];
      expiredMatches = [];
    };

    // for (let index = 0; index < archives.length; index++) {
    //   const archived = archives[index];
    //   archivedComponents.push(
    //     <div
    //       className="likeUserContainer"
    //       key={archived.otherUser.firstName + archived.otherUser.lastName}
    //       onClick={() => {
    //         this.openConversation(archived);
    //       }}
    //     >
    //       {this.getChatHoursBadge(archived)}

    //       <div className="likeNameContainer">
    //         <p className="purpleText regularWeight">
    //           {archived.otherUser.firstName}
    //         </p>
    //       </div>
    //     </div>
    //   );
    // }

    for (let index = 0; index < matches.length; index++) {
      const match = matches[index];
      matchesComponents.push(
        <div
          className="likeUserContainer"
          key={match.otherUser.firstName + match.otherUser.lastName}
          onClick={() => {
            this.openConversation(match);
          }}
        >
          {this.getChatHoursBadge(match)}

          <div className="likeNameContainer">
            <p className="purpleText regularWeight">
              {match.otherUser.firstName}
            </p>
          </div>
        </div>
      );
    }

    for (let index = 0; index < expiredMatches.length; index++) {
      const match = expiredMatches[index];
      expiredComponents.push(
        <div
          className="likeUserContainer"
          key={match.otherUser.firstName + match.otherUser.lastName}
          onClick={() => {
            this.openConversation(match);
          }}
        >
          {this.getChatHoursBadge(match)}

          <div className="likeNameContainer">
            <p className="purpleText regularWeight">
              {match.otherUser.firstName}
            </p>
          </div>
        </div>
      );
    }

    conversations = this.searchFunction(this.state.searchString, conversations);

    for (let index = 0; index < conversations.length; index++) {
      const convo = conversations[index];

      if (convo.lastMessage.content_type) {
        let lastMessageWasYours =
          convo.lastMessage.author_id === this.state.user.id;

        let lastMessage =
          (lastMessageWasYours ? "You: " : "") +
          (convo.lastMessage.content_type.toLowerCase() === "image"
            ? "Image"
            : convo.lastMessage.content);

        let date = new Date(convo.lastMessage.created_at);
        const imgSrc = convo.otherUserProfileImage
          ? convo.otherUserProfileImage.path
          : null;
        let isConversationRead = true;
        if (
          convo.lastMessage !== null &&
          convo.lastMessage.author_id !== this.state.user.id &&
          Boolean(convo.lastMessage.read) === false
        ) {
          isConversationRead = false;
        }

        conversationComponents.push(
          <div
            key={index + convo.id + imgSrc}
            className="conversationTab"
            onClick={(e) => {
              this.openConversation(convo);
            }}
            style={{
              backgroundColor: isConversationRead ? "white" : "#d3d3d3",
            }}
          >
            <div className="conversationTabContainer">
              <ModamilyAppRoundImage
                size="Regular"
                image={imgSrc}
                name={convo.otherUser.firstName}
                isUrl={true}
              />
              <div className="recentMessageContainer">
                <div className="userNameContainer">
                  <p className="purpleText">
                    {date.toLocaleTimeString() +
                      " " +
                      date.toLocaleDateString()}
                  </p>
                </div>
                <div className="userNameContainer">
                  <p className="purpleText">{convo.otherUser.firstName}</p>
                </div>
                <div
                  className={
                    "lastMessageContainer" +
                    (lastMessageWasYours ? " italicStyle" : "")
                  }
                >
                  <p className="purpleText regularWeight">{lastMessage}</p>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        let lastMessageWasYours =
          convo.lastMessage.author_id === this.state.user.id;

        let date = new Date(convo.lastMessage.createdAt);

        let lastMessage =
          (lastMessageWasYours ? "You: " : "") +
          (convo.lastMessage.contentType.toLowerCase() === "image"
            ? "Image"
            : convo.lastMessage.content);
        console.log("convo", convo);
        const imgSrc = convo.otherUserProfileImage
          ? convo.otherUserProfileImage.path
          : null;

        conversationComponents.push(
          <div
            key={index + convo.id + imgSrc}
            className="conversationTab"
            onClick={(e) => {
              this.openConversation(convo);
            }}
          >
            <div className="conversationTabContainer">
              <ModamilyAppRoundImage
                size="Regular"
                image={imgSrc}
                name={convo.otherUser.firstName}
                isUrl={true}
              />
              <div className="recentMessageContainer">
                <p className="purpleText">
                  {date.toLocaleTimeString() + " " + date.toLocaleDateString()}
                </p>
                <div className="userNameContainer">
                  <p className="purpleText">{convo.otherUser.firstName}</p>
                </div>
                <div
                  className={
                    "lastMessageContainer" +
                    (lastMessageWasYours ? " italicStyle" : "")
                  }
                >
                  <p className="purpleText regularWeight">{lastMessage}</p>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    if (matchesComponents.length === 0) {
      matchesComponents.push(
        <div key="noUsersLiked" className="noLikedUsers">
          <p className="noLikedUsersText">You have no matches yet!</p>
        </div>
      );
    }

    if (conversationComponents.length === 0) {
      conversationComponents.push(
        <div key="noLikedUsers" className="noLikedUsers">
          <p className="noLikedUsersText">
            {this.state.searchString === ""
              ? "You have no messages"
              : "No converstions found"}
          </p>
        </div>
      );
    }

    let expiredComponentRender = this.isPremiumUser(this.state.user) ? (
      <div className="matchesContainer">
        <div className="matchesHeaderContainer">
          <p className="greyText semiBoldWeight">YOUR EXPIRED MATCHES</p>
        </div>
        <div className="matchListContainer">
          <div
            className="userLikesRow"
            id="expiredMatchesRow"
            ref={(e) => {
              if (e) {
                e.addEventListener(
                  "DOMMouseScroll",
                  this.scrollHorizontally,
                  false
                );
                e.addEventListener(
                  "mousewheel",
                  this.scrollHorizontally,
                  false
                );
              }
            }}
          >
            {expiredComponents}
          </div>
        </div>
      </div>
    ) : (
      <div className="matchesContainer">
        <div className="matchesHeaderContainer">
          <p className="greyText semiBoldWeight">YOUR EXPIRED MATCHES</p>
        </div>
        <div className="matchListContainer">
          <div className="noLikedUsers" onClick={this.openUpgrade}>
            <p className="noLikedUsersText">You must be a premium member!</p>
          </div>
        </div>
        <UpgradeModal
          active={this.state.displayUpgrade}
          closeModal={this.closeUpgrade}
        />
      </div>
    );

    if (expiredMatches.length === 0) {
      expiredComponentRender = null;
    }

    return (
      <div>
        <div className="matchesContainer" style={{ overflow: "scroll" }}>
          <div className="matchesHeaderContainer">
            <p className="greyText semiBoldWeight">YOUR MATCHES</p>
          </div>
          <div className="matchListContainer">{matchesComponents}</div>
        </div>
        {expiredComponentRender}
        <div className="convosContainer">
          <div className="convoHeaderContainer">
            <p className="greyText semiBoldWeight">YOUR MESSAGES</p>
            <div className="sortingBoxContainer">
              <DropDownMenu
                key={this.state.selectedSort}
                title={"Sort by"}
                type={"Sort"}
                selected={this.sortingOptions.indexOf(this.state.selectedSort)}
                data={this.sortingOptions}
                onItemClick={(item) => {
                  this.setState({ selectedSort: item });
                }}
              />
              <TextInput
                onContentChange={(data) => {
                  this.setState({ searchString: data });
                }}
                value={this.state.searchString}
                icon={searchImg}
              />
            </div>
          </div>
          <div className="convoListContainer">{conversationComponents}</div>
        </div>
        {/* {archivedComponents.length > 0 && (
          <div className="matchesContainer" style={{ overflow: "scroll" }}>
            <div className="matchesHeaderContainer">
              <p className="greyText semiBoldWeight">ARCHIVED CONVERSATIONS</p>
            </div>
            <div className="matchListContainer">{archivedComponents}</div>
          </div>
        )} */}
        {!USE_SOCKET_IO_CHAT_SYSTEM && (
          <MessageModal
            key={
              this.selectedUser
                ? this.selectedUser.id
                : Math.floor(Math.random() * 1000)
            }
            user={this.selectedUser}
            active={this.state.displayMessageModal}
            close={() => {
              refetch();
              this.closeConversation();
            }}
            convoId={this.selectedUser ? this.selectedUser.id : null}
          />
        )}
        {USE_SOCKET_IO_CHAT_SYSTEM && this.state.displayMessageModal && (
          <MessageModalSocketIO
            key={
              this.selectedUser
                ? this.selectedUser.id
                : Math.floor(Math.random() * 1000)
            }
            user={this.selectedUser}
            active={this.state.displayMessageModal}
            close={() => {
              setTimeout(() => {
                refetch();
                this.closeConversation();
              }, 500);
            }}
            convoId={this.selectedUser ? this.selectedUser.id : null}
          />
        )}
      </div>
    );
  };

  render() {
    return (
      <div className="ModamilyAppMessages">
        <ModamilyAppNavBar currentLink="messages" type="inApp" />
        <Query query={Queries.getConversations}>
          {({ loading, error, data, refetch }) => {
            let matches = null;
            let expiredMatches = null;
            let conversations = null;
            let archived = null;

            if (loading) {
              return (
                <div className="loading-container">
                  <ThreeDots
                    fill="rgb(240, 127, 70)"
                    height="4rem"
                    width="auto"
                  />
                </div>
              );
            }
            if (error) {
              console.log(error);
            }

            if (data) {
              const response = decode(data.getConversations);

              const obj = this.separateMatchesFromConversations(response.data);
              matches = obj.matches;
              expiredMatches = obj.expiredMatches;

              switch (this.state.selectedSort) {
                case "Unread":
                  conversations = this.unreadFunction(obj.conversations);
                  break;
                case "Nearby":
                  conversations = this.recentFunction(obj.conversations);
                  break;
                case "Archived":
                  conversations = this.unreadFunction(obj.conversations, true);
                  break;
                case "Recent":
                  conversations = this.recentFunction(obj.conversations);
                  break;
                default:
                  conversations = this.unreadFunction(obj.conversations);
                  break;
              }
            }

            return (
              <div key={"messagePageContainer"} className="likesPageContainer">
                {matches ? (
                  this.renderMainComponent(
                    matches,
                    expiredMatches,
                    conversations,
                    refetch
                  )
                ) : (
                  <div className="loading-container">
                    <ThreeDots
                      fill="rgb(240, 127, 70)"
                      height="4rem"
                      width="auto"
                    />
                  </div>
                )}
              </div>
            );
          }}
        </Query>

        <div className="likesPageContainer" />
      </div>
    );
  }
}

export default ModamilyAppMessages;
