import React from "react";

/**
 * @property {Boolean} disabled - Disables button
 * @property {String} text  - Button text
 * @property {String} style - Changes the style of the button
 * @property {Function} onClick - Function ran when the button is clicked
 */

export default class ModamilyAppButton extends React.Component {
	render = () => {
		let buttonContainer = "button";
		let buttonText = "buttonText";

		let disabled = this.props.disabled ? this.props.disabled : false;

		if (disabled) {
			buttonContainer += " disabledButton";
		}

		switch (this.props.style) {
			case "orangeGradient":
				buttonContainer += " gradientOrangeButton";
				buttonText += " whiteText";
				break;
			case "red":
				buttonContainer += " redButton";
				buttonText += " whiteText";
				break;
			case "orange":
				buttonContainer += " orangeButton";
				buttonText += " whiteText";
				break;
			case "white":
				buttonContainer += " whiteButton";
				buttonText += " redText";
				break;
			case "clear":
				buttonContainer += " clearButton";
				buttonText += " whiteText";
				break;
			default:
				buttonContainer += " whiteButton";
				buttonText += " redText";
				break;
		}

		return (
			<div
				onClick={
					disabled ? null : this.props.onClick ? this.props.onClick : null
				}
				className={buttonContainer}>
				<p className={buttonText}>{this.props.text}</p>
			</div>
		);
	};
}
