const hasCompleteProfile = user => {
  const hasUserInfo = ('firstName' in user && user.firstName && user.firstName.length);
  const hasProfileImages = ('images' in user && user.images && user.images.length);

  return hasUserInfo && hasProfileImages; // && hasConversationStarters && hasCoparentRanks;
};



export const getBrowseDataFiltered = (data = [], filters) => {
  let { 
    minHeight, 
    maxHeight,
    education, 
    ethnicity, 
    eyeColor, 
    politics, 
    race, 
    religion,
    orientations } = filters;
  if(!Array.isArray(data)) data = Array(data);
  
  minHeight /= 39.7;
  maxHeight /= 39.7;

  if (education) {
    education = education.filter(e => e != 'no preference');
  }
  if (ethnicity) {
    ethnicity = ethnicity.filter(e => e != 'none' && e != 'prefer not to say');
  }
  if (orientations) {
    orientations = orientations.filter(e => e != 'none' && e != 'prefer not to say');
  }
  if (religion) {
    religion = religion.filter(e => e != 'no preference');
  }
  if (politics) {
    politics = politics.filter(e => e != 'no preference');
  }
  if (race) {
    race = race.filter(e => e != 'no preference');
  }
  if (eyeColor) {
    eyeColor = eyeColor.filter(e => e != 'no preference');
  }

  return {
    data
  };
}