import React from "react";
import ModamilyAppFormHeader from "../../../form/ModamilyAppFormHeader";
import ModamilyAppButtonGroup from "../../../form/ModamilyAppButtonGroup";

/**
 * @property {Object} data - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 * @property {Array(String)} options - data for selection
 * @property {String} formHeader - text for the form header
 * @property {String} buttonType - "check" or "radio"
 */
export default class ModamilyAppAdditionalDetailsForm extends React.Component {
  state = {
    data: this.props.data,
    options: this.props.options,
    valid: false,
  };

  componentDidMount = () => {
    let data = this.state.data;
    let keys = Object.keys(data);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      if (!data[key]) {
        this.props.invalidate();
        return;
      }
    }

    this.props.onValidChange(data);
  };

  onButtonClick = (value, index, isActive) => {
    let data = this.state.data;
    let keys = Object.keys(data);
    if (this.props.buttonType == "radio") {
      if (isActive) {
        let obj = {};

        obj[keys[0]] = value;

        this.setState({ data: { ...obj } });
        this.props.onValidChange(obj);
      } else {
        this.props.invalidate();
      }
    } else {
      let arr = data[keys[0]];

      let index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      } else {
        arr.push(value);
      }

      if (arr.length === 0) {
        this.props.invalidate();
      } else {
        this.props.onValidChange({ [keys[0]]: arr });
      }

      this.setState({ data: { [keys[0]]: arr } });
    }
  };

  render() {
    let keys = Object.keys(this.state.data);

    let val = this.state.data[keys[0]];

    let index = this.state.options.indexOf(val);

    if (index < 0) {
      index = null;
    }

    return (
      <div className="genderForm">
        <ModamilyAppFormHeader text={this.props.formHeader} />
        <ModamilyAppButtonGroup
          onButtonClick={this.onButtonClick}
          data={this.state.options}
          type={this.props.buttonType}
          selected={index}
        />
      </div>
    );
  }
}
