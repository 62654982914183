import React from "react";

import PageContainer from "../components/common/ModamilyPageContainer";
import NavBar from "../components/common/ModamilyAppNavBar";
import Footer from "../components/common/ModamilyAppFooter";
import Button from "../components/common/ModamilyAppButton";
import TextInput from "../components/form/ModamilyAppTextInput";
import FormHeader from "../components/form/ModamilyAppFormHeader";

import { Redirect, Link } from "react-router-dom";

import { Mutation } from "react-apollo";
import Mutations from "../apollo/mutations/mutations";
import decode from "jwt-decode";
import LoadingComponent from "../components/common/ModamilyAppLoadingComponent";

import emailIcon from "../resources/images/appAssets/email_purple.png";

/**
 * @property {String} confirmFor - the api or process needed to confirm email
 * @property {String} email -  default email for the form
 */
export default class ModamilyAppConfirmEmail extends React.Component {
  state = {
    email: this.props.email || null,
    valid: false,
  };

  componentDidMount = () => {
    let state = this.state;
    switch (this.props.confirmFor) {
      case "facebook":
        let user = JSON.parse(
          localStorage.getItem("social.facebook.signInResponse")
        );
        state.email = user.email;
        break;
    }

    this.setState(state, this.validate);
  };

  validate = () => {
    if (this.state.email) {
      this.setState({ valid: true });
    } else {
      this.setState({ valid: false });
    }
  };

  updateEmail = (email, valid) => {
    this.setState({ email, valid });
  };

  generateFacebookMutationVariables = () => {
    let response = JSON.parse(
      localStorage.getItem("social.facebook.signInResponse")
    );
    return {
      email: this.state.email || null,
      expoPushToken: "",
      token: response.accessToken || null,
      name: response.name || "",
      id: response.id || null,
    };
  };

  submitMutation = (mutation, variables) => {
    mutation({ variables: variables });
  };

  handleFacebookResponse = (data) => {
    let response = decode(data.facebookSignUp);
    console.log(response);
    if (response.action === "success") {
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("auth.token", response.data.modamilyToken);
      if (response.found) {
        localStorage.removeItem("social.facebook.signInResponse");
        return <Redirect to="/browse" />;
      } else {
        return <Redirect to="/onboarding" />;
      }
    } else {
      return <Redirect to="/login" />;
    }
  };

  handleResetResponse = (data) => {
    let response = decode(data.requestPasswordChange);

    if (response.action === "success") {
      window.alert("Success " + response.data.message);
    } else {
      window.alert("Error " + response.data.message);
    }
    return <Redirect to="/login" />;
  };

  render = () => {
    let mutation = null;

    switch (this.props.confirmFor) {
      case "facebook":
        mutation = Mutations.facebookSignIn;
        break;
      case "resetPassword":
        mutation = Mutations.requestPasswordChange;
        break;
    }

    return (
      <Mutation mutation={mutation}>
        {(mutation, { loading, error, data }) => {
          if (loading) {
            return <LoadingComponent />;
          }

          if (data) {
            switch (this.props.confirmFor) {
              case "facebook":
                return this.handleFacebookResponse(data);
              case "resetPassword":
                return this.handleResetResponse(data);
            }
          }

          if (error) {
            window.alert("Error occured please try again later!");
            return <Redirect to="/login" />;
          }

          return (
            <div className="ModamilyAppConfirmEmailPage">
              <NavBar type="onboarding" />

              <PageContainer>
                <div className="confirmEmailPage">
                  <FormHeader text="Confirm Email" />
                  <TextInput
                    type="email"
                    label="Email"
                    reference="emailSign"
                    ref="emailInput"
                    icon={emailIcon}
                    value={this.state.email}
                    validate={true}
                    onContentChange={(data, valid) => {
                      this.updateEmail(data, valid);
                    }}
                    onEnterPress={this.onEnterPress}
                  />
                </div>
              </PageContainer>
              <Footer type="onboarding">
                <div className="rowButtonsContainer">
                  <div className="confirmEmailButtonContainer">
                    <Button
                      text="Confirm"
                      style="orangeGradient"
                      disabled={!this.state.valid}
                      onClick={(e) => {
                        let vars = {};

                        switch (this.props.confirmFor) {
                          case "facebook":
                            vars = this.generateFacebookMutationVariables();
                            break;
                          case "resetPassword":
                            vars = { email: this.state.email };
                            break;
                          default:
                            throw new Error(
                              "property 'confirmFor' was not found"
                            );
                        }

                        this.submitMutation(mutation, vars);
                      }}
                    />
                  </div>
                  <div className="confirmEmailButtonContainer">
                    <Link to="/login">
                      <Button
                        text="cancel"
                        style="white"
                        onClick={(e) => {
                          return null;
                        }}
                      />
                    </Link>
                  </div>
                </div>
              </Footer>
            </div>
          );
        }}
      </Mutation>
    );
  };
}

const generateRandomKey = () => {
  return Math.floor(1000 * Math.random());
};
