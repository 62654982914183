import React from 'react';

//forms
import FormHeader from "../../../form/ModamilyAppFormHeader";
import ButtonGroup from "../../../form/ModamilyAppButtonGroup";

// cosntants
import Options from "../../../../constants/formOptions";

const Races = ({ searchPreferences, handleUpdate, selectedRacesIndexes }) => (
  <div className="searchPrefForm">
		<div className="searchPrefIncomeRangeFormContainer">
			<FormHeader text="Races" />
			<ButtonGroup
				manualSelection={true}
				customSelected={selectedRacesIndexes}
				data={Options.races}
				onButtonClick={(value, index) => {
					if (index > -1) {
						if(value.toLowerCase() === 'no preference') {
							searchPreferences.race = [value.toLowerCase()]
						} else {
							const indexOfPNTS = searchPreferences.race.indexOf('no preference');
							if ( indexOfPNTS > -1) { 
								searchPreferences.race.splice(
									indexOfPNTS,
									1
								);
							}
							searchPreferences.race.push(
								value.toLowerCase()
							);
						}
					} else {
						searchPreferences.race.splice(
							searchPreferences.race.indexOf(
								value.toLowerCase()
							),
							1
						);
					}
					handleUpdate(searchPreferences);
				}}
			/>
		</div>
	</div>
);

export default Races;