import React from "react";
import ButtonGroup from "../form/ModamilyAppButtonGroup";

/**
 * @property {Object} data  - { hairColor: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppFertilizationMethods extends React.Component {
  state = {
    data: this.props.data || { fertilization: [] },
  };

  buttonOptions = [
    "in vitro fertilization (IVF)",
    "home insemination",
    "artificial insemination",
    "natural insemination",
    "depends of the person",
  ];

  componentWillMount = () => {
    this.validate();
  };

  validate = () => {
    let data = this.state.data;
    if (!data.fertilization) {
      this.props.invalidate();
    } else if (data.fertilization) {
      this.props.onValidChange(data);
    }
  };

  handleButtonClick = (value, index, isActive) => {
    let data = this.state.data;
    data.fertilization = isActive ? [value] : [];
    this.props.scrollToBottom && this.props.scrollToBottom();
    this.validate();
  };

  render = () => {
    let data = this.state.data;
    const fertilizacion = data?.fertilization.length
      ? data.fertilization[0]
      : "";
    let fertilizationIndex = this.buttonOptions.indexOf(fertilizacion);

    return (
      <div className="hairColorForm">
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            What fertilization methods are you open to?
          </h2>
        </div>
        <ButtonGroup
          selected={fertilizationIndex}
          type="radio"
          data={this.buttonOptions}
          onButtonClick={this.handleButtonClick}
        ></ButtonGroup>
      </div>
    );
  };
}
