import React from "react";
import ModamilyAppFormHeader from "../form/ModamilyAppFormHeader";
import ModamilyAppButtonGroup from "../form/ModamilyAppButtonGroup";
import ModamilyAppDropDownMenu from "../form/ModamilyAppDropDownMenu";
import { genders } from "../../constants/formOptions";

/**
 * @property {Object} data  - { gender:null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppGenderForm extends React.Component {
  state = {
    data: this.props.data || { gender: null },
    valid: false,
  };

  componentDidMount = () => {
    let data = this.state.data;
    if (!data.gender) {
      this.props.invalidate();
    } else {
      this.props.onValidChange(data);
    }
  };

  buttonOptions = genders.slice(0, 3);
  dropDownOptions = genders.slice(3);

  updateValue = (key, data) => {
    let stateData = this.state.data;

    if (!data) {
      this.props.invalidate();
    }

    stateData[key] = data;

    let state = this.state;

    state.data = stateData;

    this.setState(state);
    this.onEnterPress();
  };

  onButtonClick = (value, index, isActive) => {
    if (isActive) {
      this.setState(
        {
          data: { gender: value },
        },
        this.props.onValidChange({ gender: value })
      );
    } else {
      this.setState(
        {
          data: { gender: null },
        },
        this.props.invalidate()
      );
    }
  };

  onDropdownSelection = (value) => {
    if (value) {
      this.setState(
        {
          data: { gender: value },
        },
        this.props.onValidChange({ gender: value })
      );
    } else {
      this.props.invalidate();
    }
  };

  render() {
    const buttonOpts = this.buttonOptions;
    let index = buttonOpts.indexOf(this.state.data.gender);
    let ddIndex = undefined;
    if (index < 0) {
      index = undefined;
      ddIndex = this.dropDownOptions.indexOf(this.state.data.gender);
    }
    console.info(this.state.data.gender, index, ddIndex);
    return (
      <div className="hairColorForm hasDropdown">
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            How do you identify yourself?
          </h2>

          <p>Show your true colors!</p>
        </div>
        <ModamilyAppButtonGroup
          onButtonClick={this.onButtonClick}
          data={this.buttonOptions}
          type="radio"
          selected={index}
        />
        <ModamilyAppDropDownMenu
          selected={ddIndex}
          data={this.dropDownOptions}
          title="More gender options"
          hideType={true}
          onItemClick={this.onDropdownSelection}
        />
      </div>
    );
  }
}
