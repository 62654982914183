import React from "react";

import ProgressBar from "@ramonak/react-progress-bar";

import statusBar from "../../resources/images/appAssets/statusBar.png";
import shieldCheck from "../../resources/images/appAssets/WellDoneIcon.png";
import backChevron from "../../resources/images/appAssets/back.png";
import { useHistory } from "react-router-dom";

const ShieldHeader = ({ el }) => {
  return (
    <div className="ModamilyAppStatusBar">
      <img src={statusBar} alt="status bar" className="statusBar" />

      <img src={shieldCheck} alt="shield check" className="shieldCheck" />
    </div>
  );
};

const SteperHeader = ({ step, previousStep }) => {
  const router = useHistory();
  return (
    <div className="ModamilyAppStatusBar steper">
      <div className="steperNavigationContainer">
        <div
          className={
            step === 0
              ? "steperNavigation steperNavigationWithoutPrevious"
              : "steperNavigation"
          }
        >
          {step === 0 ? null : (
            <img
              onClick={() => (previousStep ? previousStep() : router.push("/"))}
              src={backChevron}
              alt="status bar"
              className="previousPage"
            />
          )}

          <div className="steperNumbers">
            {step && (
              <>
                <h4>{`${step + 1}`} </h4>
                <h4>/ 6</h4>
              </>
            )}
          </div>
        </div>
      </div>

      {/* <div className="statusbarSteper">
        <img src={statusBar} alt="status bar" className="statusBar" />
      </div> */}
      <ProgressBar
        completed={((step + 1) / 7) * 100}
        bgColor="#D25466"
        width="531px"
        height="5px"
        borderRadius="113.911px"
        isLabelVisible={false}
        baseBgColor="#cf506830"
        labelColor="#e80909"
      />
    </div>
  );
};

const SkipHeader = ({ previousStep, nextStep, step }) => {
  console.log(step);
  return (
    <div className="ModamilyAppStatusBar steper">
      <div className="steperNavigationContainer">
        <div className="steperNavigation">
          <div onClick={() => previousStep()}>
            <img src={backChevron} alt="status bar" className="previousPage" />
          </div>

          <h4 onClick={() => nextStep()}>Skip</h4>
        </div>
      </div>

      <ProgressBar
        completed={(step / 27) * 100}
        bgColor="#D25466"
        width="531px"
        height="5px"
        borderRadius="113.911px"
        isLabelVisible={false}
        baseBgColor="#cf506830"
        labelColor="#e80909"
      />
    </div>
  );
};

const SteperBackChevronHeader = ({ previousStep }) => {
  return (
    <div className="ModamilyAppStatusBar steper">
      <div className="steperNavigationContainer">
        <div className="steperNavigation" onClick={() => previousStep()}>
          <img src={backChevron} alt="status bar" className="previousPage" />
        </div>
      </div>
    </div>
  );
};

const PreviousOnboardingHeader = ({ step }) => {
  return (
    <div className="ModamilyAppStatusBar steper">
      <div className="row align-center w-full">
        <ProgressBar
          completed={(step / 4) * 100}
          bgColor="#D25466"
          width="465px"
          height="5px"
          borderRadius="113.911px"
          isLabelVisible={false}
          baseBgColor="#cf506830"
          labelColor="#e80909"
        />

        <img src={shieldCheck} alt="shield check" className="shieldCheck " />
      </div>
    </div>
  );
};

const ModamilyAppNavHeaders = ({ component, step, previousStep, nextStep }) => {
  const headers = {
    shieldHeader: <ShieldHeader />,
    steperHeader: <SteperHeader step={step} previousStep={previousStep} />,
    steperBackChevronHeader: (
      <SteperBackChevronHeader previousStep={previousStep} />
    ),
    skipHeader: (
      <SkipHeader nextStep={nextStep} previousStep={previousStep} step={step} />
    ),
    previousOnboardingHeader: <PreviousOnboardingHeader step={step} />,
  };

  return headers[component];
};

export default ModamilyAppNavHeaders;
