import React from "react";
import dotMenu from "../../resources/images/appAssets/extraLinks.png";

/**
 * @property {Array(String)} options - an array of options listed
 * @property {Array(Function)} functions - an array of functions mapping to the options respectively
 */
export default class ModamilyAppDotMenu extends React.Component {
	state = {
		active: false
	};
	componentDidMount = () => {
		document.addEventListener("mousedown", this.handleClickOutside);
	};

	componentWillUnmount = () => {
		document.removeEventListener("mousedown", this.handleClickOutside);
	};
	/**
	 * Set the wrapper ref
	 */
	setWrapperRef = node => {
		this.popUpMenuRef = node;
	};

	/**
	 * Alert if clicked on outside of element
	 */
	handleClickOutside = event => {
		let active = this.state.active;
		if (this.popUpMenuRef && !this.popUpMenuRef.contains(event.target)) {
			if (active) {
				this.setState({ active: !active });
			}
		}
	};

	render = () => {
		let { options, functions } = this.props;

		let optionComponents = [];

		for (let index = 0; index < options.length; index++) {
			const option = options[index];

			optionComponents.push(
				<div key={index} onClick={this.state.active ? functions[index] : null}>
					<div className="menuItem">
						<p className="itemText purpleText regularWeight">{option}</p>
					</div>
				</div>
			);
		}

		return (
			<div ref={this.setWrapperRef} className="popUpMenuContainer">
				<div className="popUpMenuButtonContainer">
					<div
						onClick={evt => {
							let active = this.state.active;
							this.setState({ active: !active });
						}}
						className="popUpMenuButton">
						<img className="dotImg" src={dotMenu} />
					</div>
				</div>
				<div
					className={
						this.state.active
							? "popUpMenuItemContainerActive"
							: "popUpMenuItemContainerHidden"
					}>
					{optionComponents}
				</div>
			</div>
		);
	};
}
