import React from "react";
import ModamilyAppFormHeader from "../form/ModamilyAppFormHeader";
import ModamilyAppButtonGroup from "../form/ModamilyAppButtonGroup";
import ModamilyOnboardingFooterText from "../common/ModamilyOnboardingFooterText";
import closePurpleImg from "../../resources/images/appAssets/closePurple.png";
import ModamilyAppRadioButton from "../form/ModamilyAppRadioButton";

/**
 * @property data [Object] - { lookingFor:null } - default data for the form
 * @property invalidate [Function] - Function that gets called when the form is invalid
 * @property onValidChange[Function]- Function that gets called when the form is valid
 */
export default class ModamilyAppLookingFor extends React.Component {
  state = {
    data: this.props.data || { lookingFor: [] },
  };

  componentDidMount = () => {
    let data = this.state.data.lookingFor;
    if (data.length > 0) {
      this.props.onValidChange({ lookingFor: data });
    } else {
      this.props.invalidate();
    }
  };

  buttonOptions = [
    "co-parent",
    "looking for a known sperm donor",
    "open to being a known sperm donor",
  ];

  coParentOptions = [
    {
      text: "Dating (Romantic)",
      value: "co-parent-romantic",
    },
    {
      text: "Platonic (Friends)",
      value: "co-parent-friends",
    },
    {
      text: "Open To Both",
      value: "co-parent",
    },
  ];

  componentWillMount = () => {
    if (this.state.data.gender === "man") {
      this.buttonOptions = ["co-parent", "open to being a known sperm donor"];
    } else if (this.state.data.gender === "woman") {
      this.buttonOptions = ["co-parent", "looking for a known sperm donor"];
    } else {
      return this.buttonOptions;
    }
  };

  selectItem = (item) => {
    let data = this.state.data.lookingFor;
    if (data.indexOf(item) > -1) {
      data.splice(data.indexOf(item), 1);
    } else {
      data.push(item);
    }

    if (data.length > 0) {
      this.props.onValidChange({ lookingFor: data });
    } else {
      this.props.invalidate();
    }
    this.setState({ data: { lookingFor: data } });
  };

  onSelectCoParentType = (value) => {
    const data = this.state.data.lookingFor.map((l) =>
      l.startsWith("co-parent") ? value : l
    );
    this.props.onValidChange({ lookingFor: data });
    this.setState({
      data: { lookingFor: data },
    });
  };

  render() {
    console.log(this.state.data.gender);
    const selectedIndexes = [];

    for (let index = 0; index < this.state.data.lookingFor.length; index++) {
      const lookingFor = this.state.data.lookingFor[index];

      let i = this.buttonOptions.indexOf(lookingFor);
      if (lookingFor.startsWith("co-parent")) {
        i = this.buttonOptions.indexOf("co-parent");
      }

      if (i != -1) {
        selectedIndexes.push(i);
      }
    }

    const checkCoparentOption = (option) => option === 0;

    const validateCoParentOption = (boolean) => {
      if (selectedIndexes.some(checkCoparentOption)) {
        this.props.handleHasCoparentOptions(boolean);
      } else {
        this.props.nextStep();
      }
    };

    const coParentType = this.state.data.lookingFor?.find((f) =>
      f.startsWith("co-parent")
    );

    const disabledNext = this.state.data.lookingFor.length === 0;
    return (
      <div className="lookingForForm">
        {/* <ModamilyAppFormHeader text="Looking For" /> */}
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            {!this.props.hasCoParentOption
              ? "What are you looking for?"
              : "What type of Co-Parenting?"}
          </h2>

          {!this.props.hasCoParentOption && (
            <p>Choose the ones that apply to you or to your couple.</p>
          )}
        </div>

        {!this.props.hasCoParentOption ? (
          <>
            <ModamilyAppButtonGroup
              type="check"
              data={this.buttonOptions}
              onButtonClick={this.selectItem}
              selected={selectedIndexes}
            />

            <div className="row mt-48 align-center OnboardingButtonContainer">
              <ModamilyOnboardingFooterText text="We will use this to find the perfect match for you. You can change it later." />

              <div className="lookingForButton">
                <button
                  disabled={disabledNext}
                  className={`gradientOrangeButton whiteText button ${
                    disabledNext ? "disabledButton" : ""
                  }`}
                  onClick={() => validateCoParentOption(true)}
                >
                  Next
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row align-center px-20 border-selected ">
              <div>
                <p>Co-Parent</p>
              </div>
              <div
                onClick={() => validateCoParentOption(false)}
                className="closeButtonImg"
              >
                <img
                  className="closeImg"
                  src={closePurpleImg}
                  alt="Close button"
                />
              </div>
            </div>
            <div className="hairColorForm">
              {this.coParentOptions.map((co, idx) => {
                return (
                  <ModamilyAppRadioButton
                    text={co.text}
                    key={idx}
                    active={co.value === coParentType}
                    onClick={(isActive) => {
                      this.onSelectCoParentType(co.value);
                    }}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  }
}
