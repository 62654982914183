import React from "react";
import Button from "../common/ModamilyAppButton";
import LoadingComponent from "../common/ModamilyAppLoadingComponent";

import { Mutation, Query } from "react-apollo";
import decode from "jwt-decode";
import Mutations from "../../apollo/mutations/mutations";

import closeImg from "../../resources/images/appAssets/closePurple.png";

/**
 * @property {Boolean} active - determines whether the modal is hidden or active
 * @property {Function} closeModal - a function to close the modal
 */
export default class ModamilyAppConfirmDeactivate extends React.Component {
	componentWillReceiveProps(props) {
		if (props.active) {
			window.scrollTo({left: 0, top: 0});
		}
	}

	render = () => {
		return (
			<Mutation mutation={Mutations.deleteAccount}>
				{(mutation, { loading, error, data }) => {
					if (loading) {
						return (
							<div className="modalCurtain">
								<div className="modalLoading">
									<LoadingComponent />
								</div>
							</div>
						);
					}
					if (error) {
						console.log(error);
					}

					if (data) {
						console.log(data, "deactivate returned");
						if (data.deleteUser === "success") {
							localStorage.clear();
							window.location.reload();
						}
					}

					let active = this.props.active;

					return (
						<div
							className={
								"modalCurtain" + (active ? " curtainDrag" : " modalHidden")
							}>
							<div className="modalCard" style={{ minHeight: "50vh", height: "auto" }}>
								<div className="modalContentContainer">
									<div
										onClick={this.props.closeModal}
										className="modalCancelContainer">
										<img className="modalCancelImg" src={closeImg} />
									</div>
									<div className="modalTextContainer">
										<p className="modalText">
											Are you sure you want to DEACTIVATE your account?
										</p>
										<p style={{ padding: "1rem" }}>
											Deactivating your account, doesn’t cancel your subscription. You need to manage your subscription in account settings or the app store where you made the purchase.
										</p>
									</div>
									<div className="modalButtonsContainer">
										<div className="modalOptionButton">
											<Button
												onClick={() => {
													mutation();
												}}
												style="white"
												text={"Yes, Deactivate"}
											/>
										</div>
										<div className="modalOptionButton">
											<Button
												onClick={() => {
													this.props.closeModal();
												}}
												style="orangeGradient"
												text={"No,  Don't!"}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				}}
			</Mutation>
		);
	};
}
