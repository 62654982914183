import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { backendUri } from "../config/config";

const httpLink = createHttpLink({
  uri: backendUri + "/api/ql",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("auth.token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      accept: "application/json",
      authorization: token ? `Bearer ${token}` : "",
      "Access-Control-Allow-Origin": "*",
    },
  };
});

// This is exported so that we can easily call client.resetStore() on logout or deletion of user
// https://www.apollographql.com/docs/react/recipes/authentication#login-logout
export default new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
  fetchOptions: {
    mode: "no-cors",
  },
});
