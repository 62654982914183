import React, { useState } from "react";
import { withApollo } from "react-apollo";
import PinInput from "react-pin-input";
import { useHistory } from "react-router-dom";
import mutations from "../apollo/mutations/mutations";
import ModamilyAppStatusBar from "../components/ModamilyAppStatusBar";
import decode from "jwt-decode";

const ModamilyAppVerifyPhoneNumber = ({ client }) => {
  const [error, setError] = useState();
  const [code, setCode] = useState();
  const router = useHistory();

  const validateCode = async (completedCode) => {
    const codeToSend = completedCode || code;
    const id = window.localStorage.getItem("phoneid");
    const loggedUser = window.localStorage.getItem("auth.token");

    console.log(id, code);
    const result = await client.mutate({
      mutation: mutations.phoneVerificationCode,
      variables: { id, code: codeToSend },
    });
    const response = decode(result.data.phoneVerificationCode);
    if (response.action === "success") {
      window.localStorage.setItem("token", response.data.token);
      // If being forced to update current logged in user
      if (loggedUser) {
        const result2 = await client.mutate({
          mutation: mutations.updateUser,
          variables: {
            phoneVerification: id,
            phoneVerificationHash: response.data.token,
          },
        });
      }
      router.push("/register");
    } else {
      // window.sessionStorage.removeItem("phoneid");
      setError("Code no longer valid");
    }
  };

  const tryAgain = () => {
    router.push("/phone-number");
  };

  return (
    <div className="ModamilyAppPhoneNumber__body">
      <ModamilyAppStatusBar />

      <div className="ModamilyAppPhoneNumber__info">
        <h2>Verify your number</h2>

        <p>
          Enter the code we’ve sent by text to {router.location.state.phone}{" "}
          <b>I need to change it</b>
        </p>
      </div>

      <div>
        <PinInput
          length={6}
          initialValue=""
          onChange={(value) => setCode(value)}
          type="numeric"
          inputMode="number"
          style={{ padding: "10px", display: "flex", flexDirection: "row" }}
          inputStyle={{ borderColor: "#DBD5DC" }}
          inputFocusStyle={{ borderColor: "#4A2D4F" }}
          onComplete={(value) => validateCode(value)}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          placeholder="*"
        />
      </div>
      {error && (
        <div>
          <span className="error">{error}</span>
          <span className="retry" onClick={tryAgain}>
            Try again
          </span>
        </div>
      )}

      <div className="ModamilyAppPhoneNumber__footer">
        <p>
          <b>You should receive it</b> within <b>30s</b>
        </p>

        <button onClick={validateCode}>Next</button>
      </div>
    </div>
  );
};

export default withApollo(ModamilyAppVerifyPhoneNumber);
