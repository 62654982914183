import React from "react";

import { Link } from "react-router-dom";
import NavBar from "../components/common/ModamilyAppNavBar";
import FormHeader from "../components/form/ModamilyAppFormHeader";
import PageContainer from "../components/common/ModamilyPageContainer";

export default class ModamilyAppPrivacyPolicy extends React.Component {
	render = () => {
		return (
			<div className="ModamilyPrivacyPolicy">
				<NavBar type="onboarding" />

				<PageContainer>
					<FormHeader text="Privacy Policy" />
					<br />
					<p>Updated: Sept. 2019</p>
					<br />
					<p className="policyParagraph">
						Modamily, Inc. (“MODAMILY,” “We” or “Us”) has created this Privacy
						Policy to demonstrate our commitment to our users and their privacy
						and to communicate our information gathering and dissemination
						practices with regard to the MODAMILY Service. By accessing, using,
						and/or downloading any materials or contents from the Service, you
						acknowledge and agree to this Privacy Policy and also agree to the
						<accent className="redText">
							<Link to="terms"> Terms of Use</Link>
						</accent>{" "}
						of the Service at. MODAMILY may share information among our
						subsidiaries or sites that MODAMILY own or control, but such sharing
						of information is always governed under the terms of this Privacy
						Policy. If you do not agree with the Privacy Policy or the Terms of
						Use, you may not use the Service.
					</p>
					<br />
					<p className="policySectionHeader purpleText boldWeight">
						INFORMATION WE COLLECT
					</p>
					<br />
					<p>
						(a) MODAMILY collects personal information which may include but is
						not limited to the your name, mailing address, phone number, email
						address, cookies, IP logs, and other information that you provide to
						become a member/subscriber/registrant of the Service or respond to a
						request you submit and MODAMILY may also obtain personal
						identification records and other personal documentation if the
						subject users elects to utilizes the “User-Submitted Validation”
						function of the Service (collectively “Personal Information”). When
						you subscribe or register with the Service, MODAMILY will also
						collect payment information which is necessary to process your
						membership/subscription/registration. If you fill out a Contact Us
						form or email us, MODAMILY will use that information to respond to
						your request. Your Personal Information will not be distributed or
						shared with third parties unless it is to transact such business as
						you have contracted MODAMILY to do, to comply with any legal
						processes and/or law enforcement requests, or in order to conduct
						any business as MODAMILY, in its sole subjective discretion, deem
						reasonable.
					</p>
					<br />
					<p>
						(b) MODAMILY collects location information if you enable your mobile
						device or browser to send it to us, such as by use of geolocation
						features in the device(s) through which you access the Service, or
						by inference from other information we collect (for example, your IP
						address indicates the general geographic region from which you are
						connecting to the Internet). (c) MODAMILY collects login information
						and friend lists for social media accounts, such as your Facebook,
						Instagram, Spotify, Google, and LinkedIn accounts, that you use to
						register your MODAMILY account.
					</p>
					<br />
					<p>
						(c) MODAMILY collects and stores information when you use the
						Service, including but not limited to, the IP address from which you
						access the Websites, the date and time, the IP address of the web
						site from which you linked to the Websites, the name of the file or
						words you searched on the Websites, items clicked on a page, the
						browser and operating system used. This information is used to
						measure the number of visitors to the various sections of the
						Websites and identify system performance.
					</p>
					<br />
					<p>
						(d) MODAMILY uses Persistent Cookies to gain aggregate metrics on
						site usage to understand how people are using its Websites and how
						MODAMILY can make it better. You may have software on your computer
						that will allow you to decline or deactivate Internet cookies, but
						if you do so, some features of the Websites may not work properly
						for you. For instructions on how to remove cookies from your hard
						drive, go to your browser options for detailed instructions. In
						addition, further information regarding cookies may be available on
						other websites or from your Internet service or browser provider.
					</p>
					<br></br>
					<p>
						(e) MODAMILY uses web metrics services to track activity on its
						Websites. MODAMILY uses this information to help it develop the
						Websites, analyze patterns of usage, and to make the Website more
						useful. This information is not used for associating search terms or
						patterns of site navigation with individual users.
					</p>
					<br />
					<p className="policySectionHeader purpleText boldWeight">
						HOW WE USE THE INFORMATION WE COLLECT
					</p>
					<br />
					<p>
						We use the information that we collect to provide and maintain the
						Service as we deem appropriate in our sole discretion. We may also
						use the information to contact you to further discuss interest in
						our company, the services that we provide, and to send information
						regarding our company or partners, such as promotions and events.
						You may be invited to receive an email newsletter or other
						correspondence by providing an email address. Your email address and
						any Personal Information will not be distributed or shared with
						third parties unless it is to transact such business as you have
						contracted us to do, to comply with any legal processes and/or law
						enforcement requests, or in order to conduct any business as we, in
						our sole subjective discretion, deem reasonable. We may also email
						(or send messages via other methods) information regarding updates
						to the Service or MODAMILY, and may send a newsletter, or other
						correspondence. You will have an opportunity to unsubscribe to any
						emails or newsletters by clicking unsubscribe on emails sent to your
						account. Even if you are removed from any such list, if you use our
						services, you will continue to receive email correspondence from
						MODAMILY related to the services. Any opt-out by you is not deemed
						valid until processed by MODAMILY. It is your obligation to verify
						that you have been opted-out. MODAMILY shall not be liable for
						problems with the opt-out procedures.
					</p>
					<br />
					<p className="policySectionHeader purpleText boldWeight">
						DISCLOSURE OF USER INFORMATION TO THIRD PARTIES
					</p>
					<br />
					<p>
						In addition to the use of information described above, MODAMILY
						reserves the right to disclose information provided by Users
						(including Personal Information) as follows:
					</p>
					<br></br>
					<p>
						Disclosure By Law. You acknowledge and agree that we may disclose
						information you provide if required to do so by law, at the request
						of a third party, or if we, in our sole discretion, believe that
						disclosure is reasonable to (1) comply with the law, requests or
						orders from law enforcement, or any legal process (whether or not
						such disclosure is required by applicable law); (2) protect or
						defend MODAMILY’s, or a third party’s, rights or property; or (3)
						protect someone’s health or safety, such as when harm or violence
						against any person (including you) is threatened.
					</p>
					<br />
					<p>
						Disclosure to Third Party Service Providers. We may share your
						information with third party service providers to perform functions
						on our behalf. Examples include but are not limited to processing
						payments, handling billing disputes and collections, analyzing data,
						providing marketing assistance and providing customer service.
						MODAMILY may also partner with third party service providers for
						fraud prevention and/or identity verification. Your information will
						be treated as private and confidential by such third party service
						providers and not used for any other purpose than we authorize.
					</p>
					<br></br>
					<p>
						Data/Information Transfer As a Result of Sale of Business. In the
						event that MODAMILY is involved in a bankruptcy, merger,
						acquisition, reorganization or sale of assets, MODAMILY reserves the
						right to sell or transfer User information (including Personal
						Information) as part of any such transaction. To the maximum extent
						allowable by law, the Privacy Policy will apply to the User
						information as transferred to the successor entity. However, User
						information submitted after a transfer to a successor entity may be
						subject to a new privacy policy adopted by the successor entity.
					</p>
					<br></br>
					<p className="policySectionHeader purpleText boldWeight">
						ACCESS TO YOUR PERSONAL INFORMATION
					</p>
					<br></br>
					<p>
						We give you account settings and tools to access and control your
						personal data. You may access your personal information by signing
						into your account. From there you can correct or modify your
						information.
					</p>
					<br></br>
					<p>
						Should you close your account, certain information will actively
						persist on the Service, including messages you posted on our
						Service. In addition, personal information may remain in our
						archives and may persist internally for our administrative purposes,
						legal compliance reasons, to the extent permitted by law. In
						addition, we typically do not remove information you posted publicly
						through or on our Service.
					</p>
					<br></br>
					<p className="policySectionHeader purpleText boldWeight">
						DATA RETENTION
					</p>
					<br></br>
					<p>
						We keep your information, such as your name, email address and phone
						number for as long as you keep your account in existence. In some
						instances, we will delete the information you provide to us after it
						is no longer needed or your account is deemed inactive. We also keep
						information about you and your use of the Service for long as it is
						necessary for our legitimate business interests, for legal reasons,
						and to prevent harm to you, any third party, or us.
					</p>
					<br></br>
					<p className="policySectionHeader purpleText boldWeight">CHILDREN</p>
					<br></br>
					<p>
						The Service is not intended for persons under 18 years of age
						(“Child” or “Children”) and is not intentionally developed for or
						directed to Children. We do not knowingly solicit or collect any
						information from Children nor do we knowingly market our products or
						services to Children. If we learn that we have collected information
						of a Child, we will take steps to delete such information from our
						files as soon as possible and, if applicable, terminate that Child’s
						account. You may notify and direct MODAMILY to delete any Child’s
						account, by contacting MODAMILY at contact@modamily.com.
					</p>
					<br></br>
					<p className="policySectionHeader purpleText boldWeight">
						COOKIES AND DO NOT TRACK SIGNALS
					</p>
					<br></br>
					<p>
						When you view our Websites, we may store some information on your
						computer's hard drive. This information will be in the form of a
						"Cookie." Most web browsers automatically accept Cookies, but you
						can change your browser to prevent that. Without accepting a Cookie,
						your use of the Service may be affected.
					</p>
					<br></br>
					<p>
						Do Not Track Signals from Web Browsers – The Websites are not
						presently configured to support Do Not Track (“DNT”) signals from
						web browsers.
					</p>
					<br></br>
					<p className="policySectionHeader purpleText boldWeight">
						SITE SECURITY
					</p>
					<br></br>
					<p>
						Please note that data that is transported over an open network, such
						as the Internet or e-mail, may be accessible to anybody. MODAMILY
						cannot guarantee the confidentiality of any communication or
						material transmitted via such open networks. When disclosing any
						personal information via an open network, you should remain mindful
						of the fact that it is potentially accessible to others, and
						consequently, can be collected and used by others without your
						consent. Your data may be lost during transmission or may be
						accessed by unauthorized parties. MODAMILY does not accept any
						liability for direct or indirect losses as regards the security of
						your Personal Information or data during its transfer via Internet.
						Please use other means of communication if you think this is
						necessary or prudent for security reasons.
					</p>
					<br></br>
					<p>
						For network security purposes and to ensure that our Service remains
						available to all users, MODAMILY employs commercial software
						programs to monitor network traffic and attempt to identify
						unauthorized attempts to upload or change information, or otherwise
						cause damage. All payment information is transmitted via Secure
						Socket Layer (SSL) technology, encrypted into our payment gateway,
						and accessible only by those authorized with special access rights
						to such systems who are required to keep the information
						confidential.
					</p>
					<br></br>
					<p className="policySectionHeader purpleText boldWeight">
						EXTERNAL SITE LINKS
					</p>
					<br></br>
					<p>
						The Service may link to web sites created and maintained by public
						and private organizations which may include or offer third party
						products or services. When you access a linked site you may be
						disclosing private information. It is your responsibility to keep
						such information private and confidential. These third party sites
						have separate and independent privacy policies. MODAMILY does not
						control or guarantee the accuracy, relevance, timeliness, or
						completeness of information contained on a linked website and does
						not have responsibility or liability for the content and activities
						of these linked sites. If you click a link to an outside website,
						you will leave the Service and are subject to the privacy and
						security policies of the owners/sponsors of the outside website.
						MODAMILY cannot authorize the use of copyrighted materials contained
						in linked websites. Users must request such authorization from the
						sponsor of the linked website. MODAMILY is not responsible and
						disclaims any responsibility for transmissions users receive from
						linked websites.
					</p>
					<br></br>
					<p className="policySectionHeader purpleText boldWeight">
						YOUR CALIFORNIA PRIVACY RIGHTS
					</p>
					<br></br>
					<p>
						California law permits our customers who are California residents to
						request certain information about our disclosure of personal
						information to third parties for their direct marketing purposes
						during the preceding calendar year. This request is free and may be
						made once a year. To make such a request, please write to us at the
						following address:
					</p>
					<br></br>
					<p>
						MODAMILY, INC.
						<br></br>
						750 N. San Vicente Blvd., 8th Flr.
						<br></br>
						West Hollywood, CA 90069
						<br></br>
						INTERNATIONAL PRIVACY LAWS
					</p>
					<br></br>
					<p>
						If you are visiting the Service from outside of the United States,
						please be aware that you are sending information (including personal
						data) to the United States, where our servers are located. That
						information may then be transferred within the United States or back
						out of the United States to other countries outside of your country
						of residence, depending on the type of information and how it is
						stored by us. These countries (including the United States) may not
						have data protection laws as comprehensive or protective as those in
						the country of your residence, however, our collection, use, and
						storage of your personal data will at all times remain governed by
						this Privacy Policy.
					</p>
					<br></br>
					<p className="policySectionHeader purpleText boldWeight">
						SEVERABILITY
					</p>
					<br></br>
					<p>
						Any provision of this Privacy Policy found to be unenforceable will
						not void nor effect other provisions of the agreement.
					</p>
					<br></br>
					<p className="policySectionHeader purpleText boldWeight">
						CHANGES TO PRIVACY POLICY
					</p>
					<br></br>
					<p>
						MODAMILY may revise this Privacy Policy from time to time as new
						features are added to the Service or as laws change that may affect
						our services. When MODAMILY make changes to the Privacy Policy, they
						are reflected on this page. Any revised Privacy Policy will apply
						both to information MODAMILY already has about you at the time of
						the change, and any Personal Information submitted or received after
						the change takes effect. MODAMILY strongly recommends that you
						periodically reread this Privacy Policy, to see if there have been
						any changes to our policies that may affect you.
					</p>
					<br></br>
					<p>
						If you have any questions regarding these terms or wish to contact
						us for any matter:
					</p>
					<br></br>
					<p>
						Email:
						<accent className="redText">
							{" "}
							<a target="_blank" href="mailto:contact@modamily.com">
								contact@modamily.com
							</a>
						</accent>{" "}
					</p>
				</PageContainer>
			</div>
		);
	};
}
