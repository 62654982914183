import React from "react";
import dislikeLoader from "../../resources/images/appAssets/dislikeLarge.png";
import likeLoader from "../../resources/images/appAssets/likeLarge.png";

/**
 * @property {String} type  - "like" | "unlike" . Determines the type of loading component to render.
 */
export default class ModamilyAppLikeLoadingComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let component = <div>Loading...</div>
    switch (this.props.type) {
      case "like":
        component = (<div className="loader" style={Styles.getLoaderStyles(true)}>
                      <img className="loader__image" src={likeLoader} alt="loader" style={Styles.loader__image}/>
                    </div>);
        break;
      case "dislike":
        component = (<div className="loader" style={Styles.getLoaderStyles(false)}>
                      <img className="loader__image" src={dislikeLoader} alt="loader" style={Styles.loader__image}/>
                    </div>);
        break;
    }

    return component;
  }
}

const Styles = {
  getLoaderStyles: (isLike) => {
    return {
      lineHeight: '100vh',
      backgroundColor: isLike?'rgba(240,127,70,0.4)':'rgba(74,45,79,0.4)',
      display: 'block',
      height: '100vh',
      textAlign: 'center',
      position: 'fixed',
      top: '0vh',
      left: '0vw',
      width: '100%',
      zIndex: 100,
      overflow: 'auto',
    }
  },
  loader: {
    alignItems:'center',
    backgroundColor: 'rgba(240,127,70,0.4)',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    position: 'fixed',
    top: Math.ceil(document.documentElement.scrollTop) + 'px',
    width: '100%',
    zIndex: 100,
    overflow: 'auto',
  },
  loader__image: {
    height: '6rem',
    width: '6rem'
  }
}
