import React from 'react';
import { Mutation } from 'react-apollo';

/**
 * @property mutation - a GraphQL mutation.
 * @property variables - Object with keys matching the required fields in the mutation, the values should also match the types required.
 * @property loadingComponent - the component to render while the mutation is loading.
 * @property onError - the function run when an error occurs.
 * @property onSuccess - the function run when the mutation completes successfully.
 *
 * @description returns null. Alter state in the parent component to demount this component;
 */

const ModamilyAppmutationRenderer = ({
  mutation,
  variables,
  loadingComponent,
  onError,
  onSuccess
}) => (
  <Mutation mutation={mutation} variables={variables}>
    {(mutation, { loading, data, error }) => {
      if (loading) return loadingComponent;
      if (error) return onError(error);
      if (data) return onSuccess(data);

      mutation(variables);

      console.log(data, error)

      return loadingComponent;
    }}
  </Mutation>
);

export default ModamilyAppmutationRenderer;
