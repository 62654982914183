import React from "react";

/**
 * @property {Boolean} active - determines whether the button is active or not
 * @property {Function} onClick - function fired when button is clicked. Takes in a Boolean, isActive, describing the current state after the click
 */
export default class ModamilyAppSwitchButton extends React.Component {
	render = () => {
		let active = this.props.active;

		return (
			<div className="switchButtonContainer">
				<div
					onClick={() => {
						this.props.onClick(!active);
					}}
					className={"switch" + (active ? " activeSwitch" : " inactiveSwitch")}>
					<div
						className={
							"switchButton" +
							(active ? " activeSwitchButton" : " inactiveSwitchButton")
						}
					/>
				</div>
			</div>
		);
	};
}
