import React, { Component } from "react";
import ModamilyAppAdvertisingBox from "./ModamilyAppAdvertisingBox";
import partners from "../../constants/partners.json";
import Link from "../common/ModamilyAppLinkText";
import FormHeader from "../form/ModamilyAppFormHeader";
import ArrowImg from "../../resources/images/appAssets/dropdownArrow.png";
/**
 * @property {String} image image name
 * @property {String} name partner name
 * @property {String} description partner description
 */
class ModamilyPartnerBox extends Component {
	render = () => {
		return (
			<div className="partnerBox">
				<div className="partnerImgCont">
					<img
						alt="partnerImg"
						className="partnerImg"
						src={require("../../resources/images/partners/" +
							this.props.image)}></img>
				</div>
				<div className="partnerContentCont">
					<div className="partnerNameCont">
						<p className="partnerName">{this.props.name}</p>
					</div>
					<div className="partnerDescCont">
						<p className="partnerDesc">{this.props.description}</p>
					</div>
					<div className="partnerLinkCont">
						<Link newTab color="red" text="VISIT" link={this.props.link}></Link>
					</div>
				</div>
			</div>
		);
	};
}

class ModamilyAppPartnerSection extends Component {
	state = {
		index: 0,
		max: Math.floor(partners.length / 2) - 1
	};

	next = () => {
		let i = this.state.index;
		i += 1;
		if (i > this.state.max) {
			i = 0;
		}
		this.setState({ index: i });
	};

	back = () => {
		let i = this.state.index;
		i -= 1;
		if (i < 0) {
			i = this.state.max;
		}
		this.setState({ index: i });
	};

	render() {
		let boxes = [];
		let { index, max } = this.state;

		for (let x = 0; x < partners.length; x++) {
			const partner = partners[x];
			boxes.push(
				<ModamilyPartnerBox
					key={partner.image}
					name={partner.name}
					image={partner.image}
					description={partner.desc}
					link={partner.link}></ModamilyPartnerBox>
			);
		}
		let displayBoxes = [];
		let backControl =
			max > 0 ? (
				<div className="partnerControls" onClick={this.back}>
					<img className="partnerBackArrow" alt="partnerBackArrow" src={ArrowImg}></img>
				</div>
			) : null;

		let nextControl =
			max > 0 ? (
				<div className="partnerControls" onClick={this.next}>
					<img className="partnerNextArrow" alt="partnerBackArrow" src={ArrowImg}></img>
				</div>
			) : null;

		if (max > 0) {
			displayBoxes = boxes.splice(index * 2, 2);
		} else {
			displayBoxes = boxes;
		}

		return (
			<div className="partnerSection">
				<div className="partnerSectionHeader">
					<FormHeader text="Sites you should know"></FormHeader>
				</div>
				<div className="partnerBoxContainer">
					{backControl}
					<div className="displayBoxesContainer">{displayBoxes}</div>
					{nextControl}
				</div>
				<ModamilyAppAdvertisingBox
					text="interested in advertising with modamily?"
					click={"mailto:support@modamily.com?subject=Advertise with Modamily"}
				/>
			</div>
		);
	}
}
export default ModamilyAppPartnerSection;
