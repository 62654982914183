import React from 'react';

//forms
import FormHeader from "../../../form/ModamilyAppFormHeader";
import ButtonGroup from "../../../form/ModamilyAppButtonGroup";

// cosntants
import Options from "../../../../constants/formOptions";

const Politics = ({ searchPreferences, handleUpdate, selectedPoliticsIndexes }) => (
  <div className="searchPrefForm">
		<div className="searchPrefIncomeRangeFormContainer">
			<FormHeader text="Politics" />
			<ButtonGroup
				manualSelection={true}
				customSelected={selectedPoliticsIndexes}
				data={Options.politics}
				onButtonClick={(value, index) => {
					if (index > -1) {
						if(value.toLowerCase() === 'no preference') {
							searchPreferences.politics = [value.toLowerCase()]
						} else {
							const indexOfPNTS = searchPreferences.politics.indexOf('no preference');
							if ( indexOfPNTS > -1) { 
								searchPreferences.politics.splice(
									indexOfPNTS,
									1
								);
							}
							searchPreferences.politics.push(
								value.toLowerCase()
							);
						}
					} else {
						searchPreferences.politics.splice(
							searchPreferences.politics.indexOf(
								value.toLowerCase()
							),
							1
						);
					}
					handleUpdate(searchPreferences);
				}}
			/>
		</div>
	</div>
);

export default Politics;