import React from "react";
import Slider from "react-input-slider";
import { ThemeContext } from "@emotion/core";

/**
 * @property value - [Integer] - value of the slider
 * @property units - [String] - "currency" || "distance" -units of the slider
 * @property min - [Integer] - min value of the slider
 * @property max - [Integer] - max value of the slider
 * @property step - [Integer] - incremental value of slider
 * @property onChange -[Function(value)] - a function that is called when the data is changed;
 */
export default class ModamilyAppSliderInput extends React.Component {
  state = {
    value: this.props.value || 0,
    units: this.props.units,
    minValue: this.props.min || 0,
    maxValue: this.props.max || 100,
    step: this.props.step || 1,
    fromIncome: this.props.fromIncome || false,
  };

  componentDidMount = () => {
    let value = this.state.value;
    if (value > 0) {
      this.props.onChange(value);
    }
  };

  onValueChange = (x) => {
    // console.log(x);
    this.setState({ value: x.x });
  };

  render = () => {
    let { value, units, isRange, maxValue, minValue, step } = this.state;

    let label = null;

    switch (units) {
      case "currency":
        label = (
          <div className="sliderLabel">
            <p className="purpleText-income">{`$` + value}</p>
          </div>
        );
        break;
      case "distance":
        label = (
          <div className="sliderLabel">
            <p className="purpleText">{`${value} miles`}</p>
          </div>
        );
        break;
    }

    return (
      <div className="sliderContainer">
        {label}
        <div className="sliderInput">
          <Slider
            styles={{
              track: {
                backgroundColor: "#ade9ef",
                width: 327,
              },
              active: {
                backgroundColor: "#4a2d4f",
              },
              thumb: {
                width: 20,
                height: 20,
                backgroundColor: "#4a2d4f",
              },
            }}
            axis="x"
            xstep={step}
            x={value}
            xmax={maxValue}
            xmin={minValue}
            onChange={this.onValueChange}
            onDragEnd={() => {
              console.log("drag end");
              this.props.onChange(this.state.value);
            }}
          />
        </div>
        {this.state.fromIncome && (
          <div className="modamilyIncomeSliderLabels">
            <p>-$25k</p>

            <p>+$1m</p>
          </div>
        )}
      </div>
    );
  };
}
