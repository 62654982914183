import { takeEvery, fork, put } from 'redux-saga/effects';
import * as actions from '../actions/browse';

function* getBrowse({ payload: { browseData} }) {
  try {
    if(browseData) {
      yield put(actions.getBrowseSuccess(browseData))
    } else {
      throw Error('getBrowse data doesn\' exist!');
    }
  } catch(e) {
    console.log('error fetching Browse data', e);
  }
}

function* watchGetBrowseRequest() {
  yield takeEvery(actions.Types.GET_BROWSE_REQUEST, getBrowse);
}


function* setLike({ payload: { userid } }) {
  try {
    if(userid) {
      yield put(actions.setLikeSuccess(userid))
    } else {
      throw Error('userid data doesn\' exist!');
    }
  } catch(e) {
    console.log('error liking user', e);
  }
}

function* watchSetLikeRequest() {
  yield takeEvery(actions.Types.SET_LIKE_REQUEST, setLike);
}


function* setDislike({ payload: { userid } }) {
  try {
    if(userid) {
      yield put(actions.setDislikeSuccess(userid))
    } else {
      throw Error('userid data doesn\' exist!');
    }
  } catch(e) {
    console.log('error disliking user', e);
  }
}

function* watchSetDislikeRequest() {
  yield takeEvery(actions.Types.SET_DISLIKE_REQUEST, setDislike);
}

const browseSagas = [
  fork(watchGetBrowseRequest),
  fork(watchSetLikeRequest),
  fork(watchSetDislikeRequest),
];

export default browseSagas;