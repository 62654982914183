import React from "react";

import decode from "jwt-decode";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";

import Mutations from "../apollo/mutations/mutations";

import ModamilyAppButton from "../components/common/ModamilyAppButton";
import ModamilyAppNavBar from "../components/common/ModamilyAppNavBar";
import ModamilyFormHeader from "../components/form/ModamilyAppFormHeader";
import ModamilyAppTextInput from "../components/form/ModamilyAppTextInput";
import ModamiyAppPageContainer from "../components/common/ModamilyPageContainer";
import ModamilyAppLoadingComponent from "../components/common/ModamilyAppLoadingComponent";

import lockIcon from "../resources/images/appAssets/lock.png";
import emailIcon from "../resources/images/appAssets/email_purple.png";

export default class AdminControl extends React.Component {
	state = {
		email: "",
		password: "",
		errorMessage: null,
		attempts: parseInt(localStorage.getItem("auth.admin.attempts")) || 0,
		redirect: false,
		valid: false
	};

	componentDidMount = () => {
		localStorage.clear();
	};

	componentWillUnmount = () => {
		let attempts = this.state.attempts;

		localStorage.setItem("auth.admin.attempts", attempts);
	};

	validate = () => {
		let state = this.state;
		if (state.email && state.password) {
			this.setState({ valid: true });
		} else {
			this.setState({ valid: false });
		}
	};

	updateText = (text, key) => {
		this.setState({ [key]: text }, this.validate);
	};

	resetAttempts = () => {
		let attempts = this.state.attempts;

		if (attempts >= 5) {
			this.setState({ attempts: 0 });
		}
	};

	checkAttempts = () => {
		return this.state.attempts < 5;
	};

	render = () => {
		return (
			<Mutation mutation={Mutations.adminSignIn}>
				{(adminSignIn, { loading, error, data }) => {
					let errorMessage = "Error, please try again later";
					let resetFunction = null;
					let redirect = this.state.redirect;

					if (redirect) {
						return <Redirect to="/admin/control" />;
					}

					if (loading) {
						return <ModamilyAppLoadingComponent />;
					} else if (error) {
						console.log(error);
					} else if (data) {
						let response = decode(data.adminSignIn);
						if (response.action == "success") {
							localStorage.clear();
							localStorage.setItem("auth.token", response.data.adminToken);
							localStorage.setItem(
								"auth.admin.token",
								response.data.adminToken
							);
							return <Redirect to="/admin/control" />;
						}
					}

					return (
						<div className="ModamilyAppAdminSignIn">
							<ModamilyAppNavBar hasLinks={false} type={"admin"} />
							<ModamiyAppPageContainer>
								<div className="adminForm">
									<ModamilyFormHeader text="Admin" />
									<ModamilyAppTextInput
										type="email"
										label="Email"
										reference="emailSign"
										ref="emailInput"
										icon={emailIcon}
										onContentChange={text =>
											this.updateText(text ? text : "", "email")
										}
										onEnterPress={this.onEnterPress}
									/>
									<ModamilyAppTextInput
										type="password"
										label="Password"
										reference="passwordSign"
										icon={lockIcon}
										ref="passwordInput"
										onContentChange={text =>
											this.updateText(text ? text : "", "password")
										}
									/>
									<div className="signFormBtnContainer">
										<ModamilyAppButton
											text={"Login"}
											style="orangeGradient"
											disabled={!this.state.valid}
											onClick={e => {
												if (this.checkAttempts()) {
													let obj = {
														email: this.state.email,
														password: this.state.password
													};

													adminSignIn({
														variables: obj
													}).then(data => {
														this.setState({ attempts: this.state.attempts++ });
													});
												} else {
													alert(
														"Too many invalid attempts please try again later"
													);
													resetFunction = setTimeout(
														this.resetAttempts,
														300000
													);
												}
											}}
										/>
									</div>
								</div>
							</ModamiyAppPageContainer>
						</div>
					);
				}}
			</Mutation>
		);
	};
}
