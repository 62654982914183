import React, { Component } from "react";

import ModamilyAppHomeBanner from "../components/home/ModamilyAppHomeBanner";
import ModamilyAppArticleBanner from "../components/home/ModamilyAppArticleBanner";
import ModamilyAppSignUpHome from "../components/home/ModamilyAppSignUpHome";
import ModamilyAppGoalsSection from "../components/home/ModamilyAppGoalsSection";
import ModamilyAppPartnerSection from "../components/home/ModamilyAppPartnerSection";
import ModamilyAppConciergeBox from "../components/home/ModamilyAppConciergeBox";
import ModamilyAppFooter from "../components/common/ModamilyAppFooter";
import ModamilyAppNavBar from "../components/common/ModamilyAppNavBar";
import { Redirect } from "react-router-dom";

class ModamilyAppHome extends Component {
	render() {
		if (localStorage.getItem("auth.token") && localStorage.getItem("user")) {
			return <Redirect to="/browse" />;
		}

		return (
			<div className="ModamilyAppHome">
				<ModamilyAppNavBar type="default" />
				<ModamilyAppHomeBanner />
				<ModamilyAppArticleBanner />
				<ModamilyAppSignUpHome />
				<ModamilyAppGoalsSection />
				<ModamilyAppPartnerSection />
				<ModamilyAppConciergeBox />
				<ModamilyAppFooter type="home" />
			</div>
		);
	}
}

export default ModamilyAppHome;
