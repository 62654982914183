import React, { Component } from 'react';
import { appStoreUrl, googlePlayUrl } from '../../config/config.js';

class ModamilyAppHomeBanner extends Component {
  appStore = () => {
    window.open(appStoreUrl, '_blank');
  };

  playStore = () => {
    window.open(googlePlayUrl, '_blank');
  };

  render() {
    return (
      <div className="banner homeBanner">
        <div className="bannerContentContainer">
          <p className="bannerTitle bannerText">
            A new way
            <br />
            to family
          </p>
          <p className="bannerSubtitle bannerText">
            Join our community of people who are all ready to have kids
          </p>
        </div>
      </div>
    );
  }
}
export default ModamilyAppHomeBanner;
