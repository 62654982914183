import React from "react";
import babyface from "../../resources/images/appAssets/babyface.png";
import ModamilyAppButton from "../common/ModamilyAppButton";
export default class ModamilyAppOnboardingCongrats extends React.Component {
  render() {
    const {
      props: { skipFullProfile, nextStep },
    } = this;

    return (
      <div className="nameForm">
        <div className="ModamilyAppOnboarding__info flex-center">
          <div>
            <img
              src={babyface}
              alt="Babyface"
              className="ModamilyAppOnboardingCongrats__babyface"
            />
          </div>

          <h2 className="ModamilyAppOnboarding__info--title">Great job!</h2>

          <p>
            Want to perfect your <span>profile and get more likes?</span>
          </p>
        </div>
        <div className="ModamilyAppOnboarding__footer--info congrats-button flex-center">
          <div>
            <button onClick={() => nextStep()}>Let's do it!</button>
          </div>

          <div className="maybeLaterButton">
            <button onClick={() => skipFullProfile()}>Maybe later</button>
          </div>

          {/* <ModamilyAppButton
            style={this.props.style}
            text={this.props.text}
            onClick={() => this.props.nextStep()}
          /> */}
        </div>
      </div>
    );
  }
}
