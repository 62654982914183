import { all } from 'redux-saga/effects';

import UserSagas from './users';
import BrowseSagas from './browse';
import SearchPreferencesSagas from './searchPreferences';

export default function* rootSaga() {
  yield all([
    ...UserSagas,
    ...BrowseSagas,
    ...SearchPreferencesSagas
  ])
}