import React, { Component } from "react";

import ModamilyAppTextInput from "../form/ModamilyAppTextInput";
import ModamilyAppFormHeader from "../form/ModamilyAppFormHeader";
import ModamilyAppDropDownMenu from "../form/ModamilyAppDropDownMenu";

/**
 * @property {Object} data  - { height: null, isMetric: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
class ModamilyAppHeight extends Component {
  constructor(props) {
    super(props);

    let data = this.props.data || { height: null, isMetric: false };
    let feet = 0;
    let inches = 0;
    let centimeters = undefined;

    data.isMetric = data.isMetric || false;

    if (data.isMetric) {
      centimeters = data.height;
    } else {
      feet = Math.floor(data.height / 12);
      inches = data.height % 12;
    }

    this.state = {
      data,
      feet,
      inches,
      centimeters,
    };
  }

  componentDidMount() {
    this.validate();
  }

  menuOptions = ["Feet", "Centimeters"];

  validate = () => {
    let data = this.state;

    let height = { ...data.data };

    if (height.isMetric && data.centimeters > 0) {
      height.height = data.centimeters;
    } else {
      height.height =
        (isNaN(data.inches) ? 0 : data.inches) +
        (isNaN(data.feet) ? 0 : data.feet) * 12;
    }
    this.setState({ data: height }, () => {
      if (
        (!height.isMetric &&
          typeof height.height !== "number" &&
          height.height !== Number(height.height)) ||
        (height.isMetric && !height.height)
      ) {
        this.props.invalidate();
      } else {
        this.props.onValidChange(height);
      }
    });
  };

  unitSelection = (units) => {
    let data = this.state.data;
    let isMetric = units.toUpperCase() === "CENTIMETERS";
    data.isMetric = isMetric;
    this.setState({ data }, this.validate);
  };

  feetUpdate = (data) => {
    this.setState({ feet: parseInt(data) }, this.validate);
  };

  inchesUpdate = (data) => {
    this.setState({ inches: parseInt(data) }, this.validate);
  };

  centimetersUpdate = (data) => {
    this.setState({ centimeters: parseInt(data) }, this.validate);
  };

  render() {
    let { data, centimeters, feet, inches } = this.state;
    let isMetric = data.isMetric;

    let label = isMetric ? "Centimeters" : "Feet";

    let inputs = (
      <div className="heightInputs">
        <div>
          <div className="heightInputsLabel">
            <p>{label}</p>
          </div>
          <ModamilyAppTextInput
            key={label}
            onContentChange={(data) => {
              if (typeof data === "boolean") {
                isMetric ? this.centimetersUpdate("0") : this.feetUpdate("0");
              } else {
                isMetric ? this.centimetersUpdate(data) : this.feetUpdate(data);
              }
            }}
            value={isMetric ? centimeters : feet}
            maxLength={isMetric ? 3 : 1}
            label={label}
            type="number"
            allowZero={!isMetric ? true : false}
          />
        </div>
        {isMetric ? null : (
          <div>
            <div className="heightInputsLabel">
              <p>Inches</p>
            </div>
            <ModamilyAppTextInput
              onContentChange={(data) => {
                if (typeof data === "boolean") {
                  this.inchesUpdate("0");
                } else {
                  this.inchesUpdate(data);
                }
              }}
              value={inches}
              maxLength={2}
              label="Inches"
              type="number"
              allowZero={!isMetric ? true : false}
            />
          </div>
        )}
      </div>
    );

    let selectedIndex = isMetric ? 1 : 0;

    return (
      <div className="heightForm">
        <div className="ModamilyAppOnboarding__info">
          <h2 className="ModamilyAppOnboarding__info--title">
            How tall are you?
          </h2>
        </div>
        <ModamilyAppDropDownMenu
          selected={selectedIndex}
          data={this.menuOptions}
          title="Select Units"
          type="Unit"
          onItemClick={this.unitSelection}
          isTall={true}
        />
        {inputs}
      </div>
    );
  }
}

export default ModamilyAppHeight;
