import React from "react";
import Avatar from "react-avatar";
import clsx from "clsx";
import { Query } from "react-apollo";
import queries from "../../apollo/queries/queries";
import LoadingComponent from "../common/ModamilyAppLoadingComponent";
import decode from "jwt-decode";
import FormHeader from "../form/ModamilyAppFormHeader";
import DotMenu from "../common/ModamilyAppDotMenu";
import { AWS_URL } from "../../config/config";

//Import images
import dislikeButton from "../../resources/images/appAssets/dislikeColor.png";
import likeButton from "../../resources/images/appAssets/likeColor.png";
import closeWhite from "../../resources/images/appAssets/closeWhite.png";
import genderMaleImg from "../../resources/images/appAssets/genderMale.png";
import genderFemaleImg from "../../resources/images/appAssets/genderFemale.png";
import eyecolorImg from "../../resources/images/appAssets/eyecolor.png";
import hairImg from "../../resources/images/appAssets/hair.png";
import ethnicityImg from "../../resources/images/appAssets/ethnicity.png";
import heightImg from "../../resources/images/appAssets/height.png";
import orientationImg from "../../resources/images/appAssets/orientation.png";
import raceImg from "../../resources/images/appAssets/race.png";
import astrology from "../../resources/images/appAssets/astrology.png";

import { Mutation } from "react-apollo";

import mutations from "../../apollo/mutations/mutations";

/**
 * @property {Object} user - user to render in full profile view
 * @property {Boolean} disableCardActions - whether to show the like or dislike buttons
 * @property {Function} exitFullProfile - function used to hide profile modal
 */
export default class ModamilyAppFullProfile extends React.Component {
  constructor(props) {
    super(props);
    const { users, disableCardActions } = this.props;
    const currentUser = JSON.parse(localStorage.getItem("user"));
    this.state = {
      slideIndex: 0,
      disableCardActions: disableCardActions,
      spotifyArtists: [],
      showDotMenu: currentUser.id !== this.props.user.id,
    };
  }

  componentWillUnmount = () => {
    if (document.getElementById("spotifyArtists")) {
      document
        .getElementById("spotifyArtists")
        .removeEventListener("mousewheel", this.scrollHorizontally);
      document
        .getElementById("spotifyArtists")
        .removeEventListener("DOMMouseScroll", this.scrollHorizontally);
    }
  };

  scrollHorizontally = (e) => {
    e = window.event || e;
    var delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
    document.getElementById("spotifyArtists").scrollLeft -= delta * 40; // Multiplied by 40
    e.preventDefault();
  };

  pickImage = (index) => {
    this.setState({ slideIndex: index || 0 });
  };

  renderSpotifyComponent = (user) => {
    if (!user.socialTokens) {
      return null;
    }
    if (!user.socialTokens.spotifyToken) {
      return null;
    }

    if (this.state.spotifyArtists.length === 0) {
      // const url = "https://api.spotify.com/v1/me/top/artists";
      // let response = fetch(url, {
      // 	headers: { Authorization: "Bearer " + user.socialTokens.spotifyToken }
      // })
      // 	.then(r => r.json())
      // 	.then(data => {
      // 		this.setState({ spotifyArtists: data.items });
      // 	});
    } else {
      let artistComponents = [];

      for (let index = 0; index < this.state.spotifyArtists.length; index++) {
        const artist = this.state.spotifyArtists[index];

        artistComponents.push(
          <div className="artistContainer">
            <div className="artistCover">
              <img alt="artist" src={artist.images[0].url} />
            </div>
            <div className="artistName">
              <p className="artistNameText">{artist.name}</p>
            </div>
          </div>
        );
      }

      return (
        <div className="artistsContainer">
          <FormHeader text={user.firstName + "'s Top artists"} />
          <div
            ref={(e) => {
              if (e) {
                e.addEventListener(
                  "DOMMouseScroll",
                  this.scrollHorizontally,
                  false
                );
                e.addEventListener(
                  "mousewheel",
                  this.scrollHorizontally,
                  false
                );
              }
            }}
            id="spotifyArtists"
            className="topArtistContainer"
          >
            {artistComponents}
          </div>
        </div>
      );
    }
  };

  renderAdditionDetailsComponent = (user) => {
    let relationComponent = user.relationshipStatus ? (
      <div className="profileItemContainer">
        <div className="profileItemTitleContainer">
          <p className="greyText profileItemTitle">RELATIONSHIP STATUS</p>
        </div>
        <div className="profileItemContainer">
          <p className="purpleText regularWeight profileItems">
            {user.relationshipStatus}
          </p>
        </div>
      </div>
    ) : null;

    let bodyTypeComponent = user.bodyType ? (
      <div className="profileItemContainer">
        <div className="profileItemTitleContainer">
          <p className="greyText profileItemTitle">BODY TYPE</p>
        </div>
        <div className="profileItemContainer">
          <p className="purpleText regularWeight profileItems">
            {user.bodyType}
          </p>
        </div>
      </div>
    ) : null;

    let dietComponent = user.diet ? (
      <div className="profileItemContainer">
        <div className="profileItemTitleContainer">
          <p className="greyText profileItemTitle">DIET</p>
        </div>
        <div className="profileItemContainer">
          <p className="purpleText regularWeight profileItems">{user.diet}</p>
        </div>
      </div>
    ) : null;

    let drinksComponent = user.drinks ? (
      <div className="profileItemContainer">
        <div className="profileItemTitleContainer">
          <p className="greyText profileItemTitle">DRINKS</p>
        </div>
        <div className="profileItemContainer">
          <p className="purpleText regularWeight profileItems">{user.drinks}</p>
        </div>
      </div>
    ) : null;

    let drugsComponent = user.drugs ? (
      <div className="profileItemContainer">
        <div className="profileItemTitleContainer">
          <p className="greyText profileItemTitle">DRUGS</p>
        </div>
        <div className="profileItemContainer">
          <p className="purpleText regularWeight profileItems">{user.drugs}</p>
        </div>
      </div>
    ) : null;

    let marijuanaComponent = user.marijuana ? (
      <div className="profileItemContainer">
        <div className="profileItemTitleContainer">
          <p className="greyText profileItemTitle">MARIJUANA</p>
        </div>
        <div className="profileItemContainer">
          <p className="purpleText regularWeight profileItems">
            {user.marijuana}
          </p>
        </div>
      </div>
    ) : null;

    let smokeComponent = user.smoke ? (
      <div className="profileItemContainer">
        <div className="profileItemTitleContainer">
          <p className="greyText profileItemTitle">SMOKE</p>
        </div>
        <div className="profileItemContainer">
          <p className="purpleText regularWeight profileItems">{user.smoke}</p>
        </div>
      </div>
    ) : null;

    let fertilizationComponent =
      user.fertilization && user.fertilization.length > 0 ? (
        <div className="profileItemContainer">
          <div className="profileItemTitleContainer">
            <p className="greyText profileItemTitle">FERTILIZATION OPTIONS</p>
          </div>
          <div className="profileItemContainer">
            <p className="purpleText regularWeight profileItems">
              {user.fertilization.join(", ")}
            </p>
          </div>
        </div>
      ) : null;

    let bloodTypeComponent = user.bloodType ? (
      <div className="profileItemContainer">
        <div className="profileItemTitleContainer">
          <p className="greyText profileItemTitle">BLOOD TYPE</p>
        </div>
        <div className="profileItemContainer">
          <p className="purpleText regularWeight profileItems">
            {user.bloodType}
          </p>
        </div>
      </div>
    ) : null;

    let childrenComponent = user.children ? (
      <div className="profileItemContainer">
        <div className="profileItemTitleContainer">
          <p className="greyText profileItemTitle">CHILDREN</p>
        </div>
        <div className="profileItemContainer">
          <p className="purpleText regularWeight profileItems">
            {user.children}
          </p>
        </div>
      </div>
    ) : null;

    let speaksComponent = user.speaks ? (
      <div className="profileItemContainer">
        <div className="profileItemTitleContainer">
          <p className="greyText profileItemTitle">SPEAKS</p>
        </div>
        <div className="profileItemContainer">
          <p className="purpleText regularWeight profileItems">{user.speaks}</p>
        </div>
      </div>
    ) : null;

    return (
      <div className="fullProfileDetailsComponent">
        {relationComponent ||
        speaksComponent ||
        childrenComponent ||
        bloodTypeComponent ||
        drugsComponent ||
        marijuanaComponent ||
        fertilizationComponent ||
        smokeComponent ||
        drinksComponent ||
        dietComponent ||
        bodyTypeComponent ? (
          <div className="fullProfileDetailsContainer">
            <FormHeader text="Details" />
            <div className="profileInfoList">
              {relationComponent}
              {speaksComponent}
              {childrenComponent}
              {bloodTypeComponent}
              {drugsComponent}
              {marijuanaComponent}
              {fertilizationComponent}
              {smokeComponent}
              {drinksComponent}
              {dietComponent}
              {bodyTypeComponent}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  renderMainComponent = (user) => {
    let convoStarters = [];
    if (user.convoStarters) {
      for (let index = 0; index < user.convoStarters.length; index++) {
        const starter = user.convoStarters[index];
        convoStarters.push(
          <div className="fullProfileConversationStarter">
            <div className="convoStarterQuestion">
              <p className="questionText">{starter.question}</p>
            </div>
            <div className="convoStarterAnswer">
              <p className="answerText">{starter.answer || ""}</p>
            </div>
          </div>
        );
      }
    }

    let biologyInfoComponent = (
      <div className="basicInfoComponent">
        <FormHeader text="Biology" />
        <div className="profileInfoList">
          <div className="profileInfoRow">
            <div className="profileItemContainer">
              <div className="profileItemIconContainer">
                <img
                  alt="gender"
                  className="itemIcon"
                  src={
                    user.gender
                      ? user.gender.toUpperCase() === "FEMALE" ||
                        user.gender.toUpperCase() === "TRANS-FEMALE"
                        ? genderFemaleImg
                        : genderMaleImg
                      : ""
                  }
                />
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.gender || ""}
                </p>
              </div>
            </div>
            <div className="profileItemContainer">
              <div className="profileItemIconContainer">
                <img
                  alt="orientation"
                  className="itemIcon"
                  src={orientationImg}
                />
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.orientation || ""}
                </p>
              </div>
            </div>
          </div>
          <div className="profileInfoRow">
            <div className="profileItemContainer">
              <div className="profileItemIconContainer">
                <img alt="height" className="itemIcon" src={heightImg || ""} />
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.height || ""} {user.heightIsMetric ? "cm" : "in"}
                </p>
              </div>
            </div>
            <div className="profileItemContainer">
              <div className="profileItemIconContainer">
                <img alt="eyecolor" className="itemIcon" src={eyecolorImg} />
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.eyeColor || ""}
                </p>
              </div>
            </div>
          </div>
          <div className="profileInfoRow">
            <div className="profileItemContainer">
              <div className="profileItemIconContainer">
                <img alt="hair" className="itemIcon" src={hairImg || ""} />
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.hairColor}
                </p>
              </div>
            </div>
            <div className="profileItemContainer">
              <div className="profileItemIconContainer">
                <img alt="race" className="itemIcon" src={raceImg || ""} />
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.race}
                </p>
              </div>
            </div>
          </div>
          <div className="profileInfoRow">
            <div className="profileItemContainer">
              <div className="profileItemIconContainer">
                <img
                  alt="ethnicity"
                  className="itemIcon"
                  src={ethnicityImg || ""}
                />
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.ethnicity}
                </p>
              </div>
            </div>
            <div className="profileItemContainer">
              <div className="profileItemIconContainer">
                <img
                  alt="astrology"
                  className="itemIcon"
                  src={astrology || ""}
                />
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.astralSign || ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    let basicInfoComponent = (
      <div className="basicInfoComponent">
        <FormHeader text="Basic" />
        <div className="profileInfoList">
          <div className="profileInfoRow">
            <div className="profileItemContainer">
              <div className="profileItemTitleContainer">
                <p className="greyText profileItemTitle">SCHOOL</p>
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.school || ""}
                </p>
              </div>
            </div>
            <div className="profileItemContainer">
              <div className="profileItemTitleContainer">
                <p className="greyText profileItemTitle">EDUCATION</p>
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.education || ""}
                </p>
              </div>
            </div>
          </div>
          <div className="profileInfoRow">
            <div className="profileItemContainer">
              <div className="profileItemTitleContainer">
                <p className="greyText profileItemTitle">ROLE</p>
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.occupationTitle || ""}
                </p>
              </div>
            </div>
            <div className="profileItemContainer">
              <div className="profileItemTitleContainer">
                <p className="greyText profileItemTitle">WORKPLACE</p>
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.occupationWorkplace || ""}
                </p>
              </div>
            </div>
          </div>
          <div className="profileInfoRow">
            <div className="profileItemContainer">
              <div className="profileItemTitleContainer">
                <p className="greyText profileItemTitle">INCOME</p>
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.income || ""}
                </p>
              </div>
            </div>
            <div className="profileItemContainer">
              <div className="profileItemTitleContainer">
                <p className="greyText profileItemTitle">RELIGION</p>
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.religion || ""}
                </p>
              </div>
            </div>
          </div>
          <div className="profileInfoRow">
            <div className="profileItemContainer">
              <div className="profileItemTitleContainer">
                <p className="greyText profileItemTitle">POLITICS</p>
              </div>
              <div className="profileItemContainer">
                <p className="purpleText regularWeight profileItems">
                  {user.politics || ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    let profileValuesComponent =
      user.coparentRanks && user.coparentRanks.length > 0 ? (
        <div className="profileValuesComponent">
          <FormHeader text="Coparent Ranks" />
          {user.coparentRanks.map((rank, index) => {
            return (
              <div className="profileValuesItem" key={index + rank}>
                <div className="rankOrderContainer">
                  <p className="rankText">{index + 1}</p>
                </div>
                <div className="rankValueContainer">
                  <p className="rankValue">{rank}</p>
                </div>
              </div>
            );
          })}
        </div>
      ) : null;

    let spotifyComponent = this.renderSpotifyComponent(user) || null;

    let additionInformationComponent =
      this.renderAdditionDetailsComponent(user);
    return (
      <Mutation mutation={mutations.blockUser}>
        {(blockUser, { loading, error, data }) => {
          if (error) {
            console.log(error);
          }
          if (data) {
            let response = decode(data.blockUser);
            // console.log(response);
            if (response.action === "success") {
              window.location.reload();
            }
          }

          // console.log(user);

          return (
            <div
              style={
                this.props.customContainerStyle
                  ? this.props.customContainerStyle
                  : {}
              }
              className="fullProfileModalCurtain"
            >
              <div className="fullProfileContentContainer">
                <div className="fullProfileColumn">
                  <div className="fullProfileCardContainer">
                    <div className="fullProfileImageSlideshow">
                      {user.images &&
                        user.images.map((e, id) => (
                          <Avatar
                            key={id + e.path}
                            style={{ width: "100%", height: "auto" }}
                            size={"100%"}
                            draggable="false"
                            alt={"photo"}
                            className={clsx(
                              "fullProfileSlideshowImage",
                              id !== this.state.slideIndex &&
                                "slideImageInactive"
                            )}
                            src={AWS_URL + e.path}
                            name={user.firstName ? user.firstName : "Unknown"}
                            textMarginRatio={0.15}
                            textSizeRatio={3}
                            unstyled={true}
                            color={"#f07f46"}
                            id={"avatar-profile"}
                          />
                        ))}
                      <div className="slideDots">
                        {user.images &&
                          user.images.slice(0, 6).map((e, index) => {
                            return (
                              <div
                                key={index + e.path}
                                className={
                                  "slideDot" +
                                  (index === this.state.slideIndex
                                    ? " activeDot"
                                    : "")
                                }
                                onClick={(e) => {
                                  this.pickImage(index);
                                  e.stopPropagation();
                                }}
                              />
                            );
                          })}
                      </div>
                    </div>
                    <div className="fullProfileInfoCard">
                      {this.state.showDotMenu && (
                        <div className="browseCardPopUpMenuContainer">
                          <DotMenu
                            options={["Block user", "Exit Full Profile"]}
                            functions={[
                              () => {
                                blockUser({ variables: { userId: user.id } });
                              },
                              () => {
                                this.props.exitFullProfile();
                              },
                            ]}
                          />
                        </div>
                      )}
                      <div className="profileInfoContainer">
                        <div className="profileInfoNameContainer">
                          <p className="purpleText regularWeight profileInfoName">
                            {user.firstName}
                          </p>
                        </div>
                        <div className="lookingForContainer">
                          <div className="lookingForTitleContainer">
                            <p className="greyText lookingForTitle">
                              LOOKING FOR
                            </p>
                          </div>
                          <div className="lookingForItemContainer">
                            <p className="purpleText regularWeight lookingForItems">
                              {user.lookingFor
                                ? user.lookingFor.join(", ")
                                : []}
                            </p>
                          </div>
                        </div>
                        <div className="ageContainer">
                          <div className="ageTitleContainer">
                            <p className="greyText ageTitle">AGE</p>
                          </div>
                          <div className="ageNumberContainer">
                            <p className="purpleText regularWeight ageNumber">
                              {user.age || ""}
                            </p>
                          </div>
                        </div>
                        <div className="ageContainer">
                          <div className="ageTitleContainer">
                            <p className="greyText ageTitle">COUNTRY</p>
                          </div>
                          <div className="ageNumberContainer">
                            <p className="purpleText regularWeight ageNumber">
                              {user.country || ""}
                            </p>
                          </div>
                        </div>
                        {user.city ? (
                          <div className="lookingForContainer">
                            <div className="lookingForTitleContainer">
                              <p className="greyText lookingForTitle">CITY</p>
                            </div>
                            <div className="lookingForItemContainer">
                              <p className="purpleText regularWeight lookingForItems">
                                {user.city || ""}
                              </p>
                            </div>
                          </div>
                        ) : null}
                        {this.state.disableCardActions ? null : (
                          <div className="cardActions">
                            <div
                              className="dislikeButtonContainer"
                              onClick={() => {
                                this.props.dislikeUser(false, user);
                              }}
                            >
                              <img
                                alt="dislike"
                                className="dislikeButtonImage"
                                src={dislikeButton}
                              />
                            </div>
                            <div
                              className="likeButtonContainer"
                              onClick={() => {
                                this.props.likeUser(true, user);
                              }}
                            >
                              <img
                                alt="like"
                                className="likeButtonImage"
                                src={likeButton}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {convoStarters && convoStarters.length >= 0
                    ? convoStarters[0]
                    : null}
                  <div className="fullProfileInfoCard fd-row">
                    {basicInfoComponent}
                    {biologyInfoComponent}
                  </div>
                  {convoStarters && convoStarters.length >= 1
                    ? convoStarters[1]
                    : null}
                  {user.coparentRanks.length > 0 ? (
                    <div className="fullProfileRanksCard">
                      {profileValuesComponent}
                    </div>
                  ) : null}
                  {convoStarters && convoStarters.length >= 2
                    ? convoStarters[2]
                    : null}
                  {additionInformationComponent}
                  {spotifyComponent}
                </div>

                <div
                  onClick={this.props.exitFullProfile}
                  className="exitButtonContainer"
                >
                  <img
                    alt="close"
                    className="exitButtonImage"
                    src={closeWhite}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </Mutation>
    );
  };

  render = () => {
    return (
      <Query
        query={queries.getFullProfile}
        variables={{ id: this.props.user.id }}
      >
        {({ loading, data, error }) => {
          if (error) {
            console.log(error);
          }
          if (data) {
            let keys = Object.keys(data);
            if (keys.length > 0) {
              // console.log(data);
              let response = decode(data.getFullProfile);
              let user = response.data;

              return this.renderMainComponent(user);
            }
          }
          if (loading) {
            return <LoadingComponent />;
          }
          return null;
        }}
      </Query>
    );
  };
}
