import React, { useState } from "react";

//form
import FormHeader from "../../../form/ModamilyAppFormHeader";
import ButtonGroup from "../../../form/ModamilyAppButtonGroup";

// cosntants
import Options from "../../../../constants/formOptions";
import { capitalize } from "lodash";
import ModamilyAppRadioButton from "../../../form/ModamilyAppRadioButton";
import ModamilyAppTextInput from "../../../form/ModamilyAppTextInput";

const EducationLevel = ({
  selectedEducationLevel,
  schoolName,
  handleUpdate,
}) => {
  const [selected, setSelected] = useState(selectedEducationLevel);
  const [school, setSchool] = useState(schoolName);

  const checkOptions = (option) => {
    setSelected(option);
    handleUpdate({
      educationLevel: option,
      school,
    });
  };

  let hasEducationLevel = false;
  // if !== prefer to say: hasEducationLevel = true;
  const opts = Options.educationLevels.map((e) =>
    e === "no preference" ? "prefer not to say" : e
  );

  return (
    <div className="searchPrefForm">
      <div className="searchPrefLookingForFormContainer">
        <FormHeader text="What’s your highest education level?" />
        {opts.map((o, idx) => {
          return (
            <>
              <ModamilyAppRadioButton
                text={capitalize(o)}
                key={idx}
                active={selected === o}
                onClick={(isActive) => {
                  checkOptions(o, idx, isActive);
                }}
              />
            </>
          );
        })}
        {selected !== "prefer not to say" && (
          <ModamilyAppTextInput
            onContentChange={setSchool}
            value={school}
            label="School"
          />
        )}
      </div>
    </div>
  );
};

export default EducationLevel;
