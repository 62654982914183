import React from "react";

import NavBar from "../components/common/ModamilyAppNavBar";
import FormHeader from "../components/form/ModamilyAppFormHeader";
import PageContainer from "../components/common/ModamilyPageContainer";

import decode from "jwt-decode";
import { withApollo } from "react-apollo";
import Mutations from "../apollo/mutations/mutations";
import ModamilyAppButton from "../components/common/ModamilyAppButton";

const ModamilyAppDeactivatedAccount = ({ client }) => {
  const clearAndReload = () => {
    localStorage.clear();
    window.location.reload();
  };

  const enableAccount = async () => {
    try {
      const response = await client.mutate({
        mutation: Mutations.reactivateAccount,
      });
      const { data } = decode(response.data.enableUser);

      if (data.status === "ok" && localStorage.getItem("user")) {
        const user = JSON.parse(localStorage.getItem("user"));
        user.userActive = true;
        localStorage.setItem("user", JSON.stringify(user));
      } else {
        localStorage.clear();
      }
      window.location.reload();
    } catch (error) {
      clearAndReload();
    }
  };

  return (
    <div className="aboutUsPage">
      <NavBar type="onboarding" />
      <PageContainer>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormHeader text="Your Account has been deactivated." />
          <br />
          <p>
            You have deactivated your account, would you like to reactivate it?
          </p>
          <br />
          <div style={{ width: "200px", paddingTop: "1rem" }}>
            <ModamilyAppButton
              style="red"
              text="Enable Account"
              onClick={enableAccount}
            />
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default withApollo(ModamilyAppDeactivatedAccount);
